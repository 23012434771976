import { Text, View, Linking, Image, TouchableOpacity } from 'react-native'
import { useLocalisationContext } from '../../Providers/LocalisationProvider'

const PdfAttachment = ({ media, label }) => {
    const lang = useLocalisationContext()

    const text = label ? label : `${lang.t('click_here_to_download_file') } "${media.file_name}"`
    return (
        <TouchableOpacity style={{
            padding: 24,
            flexDirection: 'row'
        }} onPress={() => {
            Linking.openURL(media.original_url)
        }}>
            <View style={{
                alignItems: 'center',
            }}>
                <Image source={require('../../assets/images/file-pdf.png')} style={{ width: 48, height: 48 }} />
                <Text style={{
                    marginTop: 20,
                    maxWidth: 200,
                    textAlign: 'center'
                }}>{text}</Text>
            </View>
            <Image source={require('../../assets/images/download.png')} style={{ width: 48, height: 48, marginLeft: 16, alignSelf: 'center' }} />
        </TouchableOpacity>
    )
}

export default PdfAttachment