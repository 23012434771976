import { Image, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { ThemeColours } from '../Styles/Theme'


const ReviewCard = ({authorAvatarUrl, authorName, reviewText, rating}) => {
  return (
    <View style={styles.row}>
        <View style={styles.colLeft}>
            <View style={styles.authorAvatarContainer}>
                {authorAvatarUrl ? <Image source={{uri: authorAvatarUrl}} style={styles.authorAvatar} /> : null }
            </View>
        </View>
        <View style={styles.col}>
            <Text numberOfLines={1} style={styles.authorName}>{authorName}</Text>
            <View style={styles.rowRating}>
            {rating >= 1 ?
            <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
            : 
            <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
            }
            {rating >= 2 ?
            <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
            :
            <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
            }
            {rating >= 3 ?
            <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
            :
            <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
            }
            {rating >= 4 ?
            <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
            :
            <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
            }
            {rating >= 5 ?
            <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
            :
            <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
            }
            </View>
            {reviewText ? <Text style={styles.reviewText}>{reviewText}</Text> : null }
        </View>
    </View>
  )
}

export default ReviewCard

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    col: {
        flex: 1,
    },
    colLeft: {
        flex: 0,
        paddingRight: 10
    },
    authorAvatar: {
        width: 40,
        height: 40,
        borderRadius: 3,
    },
    authorAvatarContainer: {
        width: 40,
        height: 40,
        borderRadius: 3,
        backgroundColor: ThemeColours.blueGrey
    },
    authorName: {
        fontSize: 18,
        fontFamily: 'ITCAvantGardeProMd',
        marginBottom: 5,
        color: ThemeColours.midnightBlue
    },
    rowRating: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    reviewText: {
        fontSize: 18,
        color: ThemeColours.blueGrey
    },
    starIcon: {
        marginRight: 5,
        width: 15,
        height: 15,
    }
})