import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgStarEmpty = (props) => (
    <Svg width={35} height={35} xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
        d="M17.498 29.803 8.36 34.808c-.477.294-1.127.246-1.625-.116-.49-.362-.736-.991-.696-1.607l1.797-10.632L.471 14.92a1.666 1.666 0 0 1-.391-1.675 1.601 1.601 0 0 1 1.279-1.108l10.158-1.552 4.548-9.66A1.594 1.594 0 0 1 17.498 0c.61 0 1.166.359 1.432.924l4.548 9.66 10.164 1.552c.597.089 1.088.52 1.28 1.108a1.68 1.68 0 0 1-.391 1.675l-7.373 7.534 1.744 10.632c.1.616-.146 1.245-.643 1.607-.491.362-1.2.41-1.678.116l-9.083-5.005zm-5.026-18.74a1.065 1.065 0 0 1-.796.608L1.514 13.216a.526.526 0 0 0-.427.37.56.56 0 0 0 .131.56l7.368 7.466c.246.315.358.67.299 1.02L7.148 33.27c-.04.205.046.41.152.533.225.123.438.137.617-.02L17 28.839c.312-.17.69-.17 1.001 0l9.077 4.944a.431.431 0 0 0 .564.02.55.55 0 0 0 .212-.533l-1.737-10.64c-.12-.349.053-.704.298-1.019l7.367-7.466a.58.58 0 0 0 .132-.56.537.537 0 0 0-.43-.37L23.326 11.67a1.074 1.074 0 0 1-.803-.608l-4.548-9.66c-.086-.19-.332-.37-.477-.37-.2 0-.385.18-.478.37l-4.548 9.66z"
        fill="#99A7BC"
        />
    </Svg>
)

const SvgStarFull = (props) => (
    <Svg width={35} height={35} xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
        d="m23.667 10.276 9.524 1.449c.788.116 1.451.69 1.703 1.476a2.245 2.245 0 0 1-.524 2.242l-6.905 6.986 1.63 10.02c.132.82-.192 1.654-.855 2.14a2.07 2.07 0 0 1-2.233.156l-8.51-4.682-8.503 4.682a2.081 2.081 0 0 1-2.24-.157 2.208 2.208 0 0 1-.849-2.14l1.63-10.02L.63 15.444a2.246 2.246 0 0 1-.523-2.242c.25-.786.91-1.36 1.705-1.476l9.516-1.45 4.268-9.044A2.108 2.108 0 0 1 17.497 0a2.11 2.11 0 0 1 1.909 1.23l4.261 9.046z"
        fill="#FBB03B"
        />
    </Svg>
)


export {
    SvgStarEmpty,
    SvgStarFull
}
