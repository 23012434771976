import React, { useLayoutEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, Text, View, ScrollView, ActivityIndicator, TextInput, TouchableOpacity, KeyboardAvoidingView, ImageBackground, Image, Pressable, Alert, TouchableWithoutFeedback, Keyboard, StatusBar } from 'react-native'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import axios from 'axios'
import { LOGIN_URL } from '../config'
import { saveToken, getUser, useAuthContext } from '../auth'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import dismissKeyboard from "../utils/dismissKeyboard";
import resolveDeviceName from "../utils/resolveDeviceName";


const LoginScreen = () => {
    
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setLoading] = useState(false)
    const navigation = useNavigation()
    const refPassword = useRef()
    const { setAuthenticated } = useAuthContext()
    const lang = useLocalisationContext()
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/
    const [errors, setErrors] = useState({})


    const doLogin = async () => {

        if (canSend() === false) {
            return;
        }
        setLoading(true);
        const formData = {
            email,
            password,
            device_name: resolveDeviceName()
        };

        try {
            const loginResponse = await axios.post(LOGIN_URL, formData);
            const token = loginResponse.data.token;
            const tokenResponse = await saveToken(token);

            const authUser = await getUser();
            setAuthenticated(authUser);
            
            setLoading(false);
            const routes = navigation.getState()?.routes;
            const prevRoute = routes[routes.length - 2];

            if(authUser.is_merchant){
                navigation.navigate('HomeTabs', { screen: 'Requests' })
            } else {
                navigation.replace(prevRoute.name, prevRoute.params)
            }
        } catch(error) {
            let validation = {};
            if (error?.response?.data) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    validation[key] = error.response.data.errors[key];
                });
                setErrors(validation);
            }
            //console.log(error);
            setLoading(false);
            //Alert.alert(error);
        }
    }

    const canSend = () => {
        let validation = {}
        if (!email || reg.test(email) === false) {
            validation.email = lang.t('plese_enter_your_email');
        }
        if (!password) {
            validation.password = lang.t('password_enter_your_password');
        }
        setErrors(validation);
        
        return Object.keys(validation).length === 0;
    }


    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        });
    }, [])

    if (isLoading) {
        return (
            <View style={styles.container}>
                <ActivityIndicator size="large" />
            </View>
        );
    }

    return (
    <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === "ios" ? "padding" : "height"}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <TouchableWithoutFeedback style={{flex: 1}} onPress={dismissKeyboard}>
            <ScrollView>
                <Image 
                style={styles.logo}
                source={require('../assets/images/materialNetworkLogoCenteredDark-2x.png')}/>
                <Text style={[Theme.h1, {marginBottom: 21, fontFamily: 'ITCAvantGardeProBold'}]}>{lang.t('login')}</Text>
                <View style={{marginBottom: 11}}>
                    <Text style={[Theme.inputLabelText, styles.label]}>{lang.t('email')}</Text>
                    <TextInput 
                    style={styles.input}
                    value={email}
                    onChangeText={(email) => setEmail(email)}
                    placeholder={lang.t('enter_your_email')}
                    keyboardType='email-address'
                    spellCheck={false}
                    autoCapitalize='none'
                    autoCorrect={false}
                    returnKeyType='next'
                    onSubmitEditing={() => {refPassword.current.focus()}}
                    />
                    {errors.email && 
                    <Text style={styles.formValidationLabel}>{errors.email}</Text>
                    }
                </View>
                <View>
                    <Text style={[Theme.inputLabelText, styles.label]}>{lang.t('password')}</Text>
                    <TextInput
                        style={styles.input}
                        value={password}
                        onChangeText={(password) => setPassword(password)}
                        placeholder={lang.t('enter_your_password')}
                        returnKeyType='done'
                        secureTextEntry={true}
                        textContentType='password'
                        ref={refPassword}
                        />
                    {errors.password && 
                        <Text style={styles.formValidationLabel}>{errors.password}</Text>
                    }
                    <Pressable 
                        onPress={() => navigation.navigate('ForgottenPasswordScreen')}
                        style={{flexDirection: 'row', justifyContent: 'flex-end', paddingHorizontal: 10}}>
                        <Text style={{color: '#667b9a'}}>{lang.t('ive_forgot_my_password')}</Text>
                    </Pressable>
                </View>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity
                        style={styles.button}
                        onPress={() => doLogin()}
                        >
                        <Text style={styles.buttonText}>{lang.t('log_in')}</Text>
                    </TouchableOpacity>
                </View>
                {
                    Platform.OS !== 'web' ? <View style={{flex: 1, width: '100%', flexDirection: 'row'}}>
                        <Text style={{marginRight: 5}}>{lang.t('dont_have_an_account')}</Text>
                        <Pressable
                            onPress={() => navigation.navigate('RegisterScreen')}>
                            <Text style={styles.secondaryActionText}>{lang.t('signup')}</Text>
                        </Pressable>
                    </View> : null
                }
                <View style={{height: 100}}></View>
            </ScrollView>
        </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    )
}

export default LoginScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        paddingLeft: 15,
        paddingRight: 15,
    },
    bgImage: {
        height: 239,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: 127,
        height: 85,
        alignSelf: 'center',
        marginTop: 77,
        marginBottom: 15,
    },
    content: {
        padding: 15,
        flex: 1,
        // justifyContent: "space-around"
    },
    label: {
        paddingLeft: 10,
        paddingBottom: 5,
    },
    input: {
        paddingHorizontal: 10,
        fontSize: 18,
        height: 50,
        width: 300,
        backgroundColor: '#ffffff',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#002357',
        borderRadius: 3
    },
    formValidationLabel: {
        marginTop: 4,
        marginLeft: 12,
        fontSize: 12,
        color: ThemeColours.denialRed,
    },
    buttonContainer: {
        marginTop: 45,
        marginBottom: 33,
    },
    button: {
        backgroundColor: 'rgb(251,176,59)',
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        borderRadius: 3,
        padding: 13,
    },
    buttonText: {
        color: '#002357',
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
    },
    buttonClose: {
        height: 40,
        width: 40,
        position: 'absolute',
        right: 15,
        top: 15,
    },
    secondaryActionText: {
        color: '#667b9a',
    }
})