import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BackendService from '../Services/BackendService'

export const fetchMessages = createAsyncThunk('messages/fetchMessages', async (formData) => {
    try {
        const {conversation_id} = formData
        const response = await BackendService.getMessages(conversation_id)
        return response.data.reverse()
    } catch(error) {
        console.log(error)
        return []
    }
})

const initialState = {
    messages: [],
    conversation_id: -1,
    status: 'idle',
    error: null,
}

export const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessage: (state, action) => {
            // Ensure data is an object, not string of json.
            let data = {}
            let message = {}
            if (typeof action.payload == 'string') {
              data = JSON.parse(action.payload)
              message = data.message
            } else {
              data = action.payload
              message = data.message
            }

            // Ensure message is part of conversation before adding.
            if (data.message?.conversation_id === state.conversation_id) {
              state.messages = [data.message, ...state.messages]
            }
        },
        setMessages: (state, action) => {
            state.messages = action.payload
        },
        setConversationId: (state, action) => {
            state.conversation_id = action.payload
        },
        resetMessagesStatus: (state, action) => {
          state.status = initialState.status
        },
        setMessageStatusLoading: (state, action) => {
            state.status = 'loading'
        }
    },
    extraReducers(builder) {
        builder
          .addCase(fetchMessages.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchMessages.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.messages = action.payload
          })
          .addCase(fetchMessages.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
      }
})

// Action creators are generated for each case reducer function
export const { addMessage, setMessages, resetMessagesStatus, setMessageStatusLoading, setConversationId } = messagesSlice.actions

export const selectMessages = (state) => state.messages.messages

export const selectMessageById = (state, messageId) =>
  state.messages.messages.find((message) => message.id === messageId)

export default messagesSlice.reducer