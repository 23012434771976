import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import userSlice from './userSlice';
import requestsSlice from './requestsSlice';
import resultsSlice from './resultsSlice';
import locationSlice from './locationSlice';
import categoriesSlice from './categoriesSlice';
import messagesSlice from './messagesSlice';
import notificationsSlice from './notificationsSlice';


const rootReducer = combineReducers({
    // user: userSlice,
    requests: requestsSlice,
    results: resultsSlice,
    location: locationSlice,
    categories: categoriesSlice,
    messages: messagesSlice,
    notifications: notificationsSlice
});

export const store = configureStore({
  reducer: rootReducer,
});
