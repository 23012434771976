import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { View, Text, ScrollView, StyleSheet, SafeAreaView, Image, Pressable, TouchableOpacity, FlatList } from 'react-native'
import CategoryCard from '../Components/CategoryCard'
import FeaturedMerchantCard from '../Components/FeaturedMerchantCard'
import {Theme, ThemeColours} from '../Styles/Theme'
import { useNavigation, useRoute } from '@react-navigation/native'
import BackendService from '../Services/BackendService'
import { selectAddressString, selectLocation } from '../Store/locationSlice'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { fetchCategories, selectParentCategories } from '../Store/categoriesSlice'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { Platform } from 'expo-modules-core'


const HomeScreen = () => {

    const [text, setText] = React.useState("")
    const [merchants, setMerchants] = React.useState([])
    const [merchantsCount, setMerchantsCount] = React.useState(0)
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const placeholder = "Search for materials you need"
    const address = useSelector(selectAddressString)
    const location = useSelector(selectLocation)
    const categoriesStatus = useSelector(state => state.categories.status)
    const categories = useSelector(selectParentCategories, shallowEqual)
    const lang = useLocalisationContext()

    const {params: {
        searchString
    }} = useRoute();

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        });
        setText(searchString);

    }, [searchString])

    useEffect(() => {
        const {lat, lng} = location;
        const params = {lat, lng};
        
        //Note: we may need to combine api requests for home screen into one, to improve performance
        BackendService.getMerchants(params).then((response) => {
            setMerchants(response.data);
            setMerchantsCount(response.data.length)
        }).catch(console.error);

        if (categoriesStatus === 'idle') {
            dispatch(fetchCategories())
        }

    }, [categoriesStatus, dispatch])
    //console.log(merchants);

    return (
        <SafeAreaView style={styles.container}>
            <FocusAwareStatusBar barStyle="dark-content" />
            <ScrollView>
                <View style={styles.content}>
                    <View style={{marginTop: 25, marginBottom: 20, padingLeft: 10, paddingRight: 10}}>
                        <TouchableOpacity 
                        style={{flexDirection: 'row'}}
                        onPress={() => {
                            navigation.navigate('LocationEditScreen')
                        }}
                        >
                            <Text style={styles.addressText} numberOfLines={1}>{address}</Text>
                            <Image 
                            source={require('../assets/images/dropdownArrowSolidIcon-2x.png')} 
                            style={{width: 20, height: 20, marginTop: 5}}/>
                        </TouchableOpacity>
                    </View>
                    <Pressable 
                        onPressIn={() => {
                            navigation.navigate('MaterialsSearchScreen', {searchString})
                        }} 
                        style={{backgroundColor: '#ffffff', height: 50, borderRadius: 3, flexDirection: 'row'}}
                        >
                        <View style={{padding: 15}}>
                            <Image source={require('../assets/images/searchIcon-2x.png')} style={{width: 20, height: 20}}/>
                        </View>
                        {text.length == 0 ?
                            <Text style={styles.inputPlaceholder}>{placeholder}</Text>
                            :
                            <Text style={styles.input}>{text}</Text>
                        }
                    </Pressable>
                </View>
                <View style={{marginBottom: 25}}>
                    <ScrollView 
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        paddingTop={15}
                        paddingHorizontal={15}
                        >
                            {categories.map((cat) => (
                                <CategoryCard imgUrl={{uri: cat.thumb}} key={cat.id} title={cat.name}></CategoryCard>
                            ))}
                    </ScrollView>
                </View>
                <View style={{backgroundColor: '#ffffff', paddingTop: 17, paddingBottom: 15}}>
                    <Text style={styles.topRatedHeading}>{lang.t('top_rated_nearby')}</Text>
                    {/* <ScrollView 
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        paddingTop={15}
                        paddingHorizontal={15}
                        >
                            <FeaturedMerchantCard 
                                imgUrl={require('../assets/images/featuredMerchantImagePlaceholder.png')}
                                logoUrl={require('../assets/images/merchantLogoPlaceholder.png')}
                                name="H&S Buiders Merchants"
                                distance="0.25mi"
                                rating={5}
                                reviewCount={5}
                                ></FeaturedMerchantCard>
                        </ScrollView> */}
                    <Text style={{paddingHorizontal: 15, fontSize: 18, color: ThemeColours.blueGrey}}>{merchantsCount} open merchants</Text>
                </View>
                <FlatList
                    data={merchants}
                    numColumns={Platform.OS === 'web' ? 2 : 0}
                    contentContainerStyle={styles.listContainer}
                    horizontal={false}
                    renderItem={({item}) => {

                    const merchant = item;
                    return <View style={Platform.select({
                        android: {},
                        ios: {},
                        web: {margin: '1%', width: '48%'}
                    })}>
                        <FeaturedMerchantCard 
                            key={merchant.id}
                            id={merchant.id}
                            imgUrl={{uri: merchant.cover_photo_url}}
                            logoUrl={{uri: merchant.logo_url}}
                            name={merchant.name}
                            distance={merchant.distance}
                            has_parking={merchant.has_parking}
                            has_toilets={merchant.has_toilets}
                            has_refreshments={merchant.has_refreshments}
                            rating={merchant.rating}
                            reviewCount={merchant.review_count}
                            ></FeaturedMerchantCard>
                        </View>
                }}>
                </FlatList>
            </ScrollView>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    content: {
        padding: 15,
    },
    input: {
        lineHeight: 50,
        fontSize: 18,
    },
    inputPlaceholder: {
        lineHeight: 50,
        color: '#99A7BC',
        fontSize: 18,
    },
    addressText: {
        ...Theme.bodyText,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
        marginRight: 5,
        flexGrow: 1,
    },
    listContainer: {
        flex: 1,
        backgroundColor: ThemeColours.white,
        paddingTop: 15,
        paddingHorizontal: 10
    },
    topRatedHeading: {
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
        paddingHorizontal: 15
    },
});

export default HomeScreen