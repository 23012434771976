import { StyleSheet, Text, View, Image } from 'react-native'
import React from 'react'

const LocationDistance = ({ distance }) => {
  return (
    <View stlye={styles.distanceContainer}>
        <View style={styles.row}>
            <Image source={require('../assets/images/locationIcon-2x.png')} style={styles.locationMarker}/>
            <Text style={styles.distanceText}>{ Number(distance).toFixed(2) }mi</Text>
        </View>
    </View>
  )
}

export default LocationDistance

const styles = StyleSheet.create({
    distanceContainer: {
        flex: 1,
        //flexDirection: 'row',
        //marginBottom: 10
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    locationMarker: {
        width: 10,
        height: 14,
        marginRight: 5,
    },
    distanceText: {}
})