import { Keyboard, Pressable, SafeAreaView, StyleSheet, View } from 'react-native'
import { useLayoutEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import CloseButton from '../Components/CloseButton'
import { ThemeColours } from '../Styles/Theme'
import { createStackNavigator } from '@react-navigation/stack'
import ForgottenPasswordCard from '../Components/ForgottenPasswordCard'
import RecoverAccountCard from '../Components/RecoverAccountCard'
import ResetPasswordCard from '../Components/ResetPasswordCard'
import dismissKeyboard from '../utils/dismissKeyboard'


const Stack = createStackNavigator();


const ForgottenPasswordScreen = () => {

    const navigation = useNavigation()
    const lang = useLocalisationContext()

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    return (
    <SafeAreaView style={styles.container}>
        <Pressable onPress={dismissKeyboard} style={{flex: 1}}>
            <View style={styles.header}>
                <CloseButton onPress={() => navigation.goBack()} />
            </View>

            <Stack.Navigator>
                <Stack.Screen 
                    name="ForgottenPassword" 
                    component={ForgottenPasswordCard} 
                    options={{headerShown: false}} 
                />
                <Stack.Screen 
                    name="RecoverAccount" 
                    component={RecoverAccountCard} 
                    options={{headerShown: false}} 
                />
                <Stack.Screen 
                    name="ResetPassword" 
                    component={ResetPasswordCard} 
                    options={{headerShown: false}} 
                />
            </Stack.Navigator>

            <View style={{height: 90}}></View>
        </Pressable>
    </SafeAreaView>
    )
}

export default ForgottenPasswordScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 15,
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        flexDirection: 'row',
        width: '100%',
        height: 40,
        justifyContent: 'flex-end',
        marginBottom: 34,
    }
})