import { Text, TouchableOpacity } from 'react-native'
import { lang } from '../../Providers/LocalisationProvider'

const PreviewAttachment = ({ file, onPress }) => {
    return (
        <TouchableOpacity onPress={onPress} style={{ width: '100%' }}>
            <Text style={{
                paddingVertical: 12,
                textAlign: 'center',
            }}>{lang.t('attached_file')} {file.name} {lang.t('tap_to_cancel')}</Text>
        </TouchableOpacity>
    )
}

export default PreviewAttachment