import * as React from 'react';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

const LocalisationContext = React.createContext();

const i18n = new I18n({
    en: {
        welcome: 'Hello',
        top_rated_nearby: 'Top-rated nearby',
        refer_and_earn: 'Refer & Earn',
        notifications: 'Notifications',
        subscription: 'Subscription',
        password: 'Password',
        terms_and_conditions: 'Terms and Conditions',
        privacy_policy: 'Privacy Policy',
        eula: 'EULA',
        cookies_policy: 'Cookies Policy',
        about: 'About',
        version: 'Version',
        platform: 'Platform',
        build_number: 'Build Number',
        application_id: 'Application ID',
        api_version: 'API Version',
        sign_out: 'Sign out',
        sign_in: 'Sign in',
        signup: 'Signup',
        log_in: 'Log in',
        cancel: 'Cancel',
        loading: 'loading',
        create_my_account: 'Create my account',
        by_clicking_button_you_agree_to: 'By clicking "Create my account", you agree to our ',
        already_have_an_account: 'Already have an account?',
        create_your_account: 'Create your account',
        your_name: 'Your name',
        your_friends_name: 'Your Friends Name',
        first_name: 'First Name',
        please_enter_your_friends_name: 'Please enter your friends name',
        enter_your_name: 'Enter your name',
        delete_account: 'Delete account',
        login: 'Login',
        email: 'Email',
        enter_your_email: 'Enter your email',
        enter_your_password: 'Enter your password',
        ive_forgot_my_password: 'I\'ve forgotten my password',
        dont_have_an_account: 'Don\'t have an account?',
        plese_enter_your_name: 'Plese enter your name.',
        plese_enter_your_email: 'Plese enter your email.',
        please_enter_a_valid_email: 'Please enter a valid email',
        password_enter_your_password: 'Password enter your password.',
        password_must_contain_a_number: 'Password must contain at least one number (0-9).',
        password_must_be_at_least_8_characters: 'Password must be at least 8 characters.',
        passwords_must_match: 'Passwords must match',
        get_searching_for_materials_you_need: 'Get searching for materials you need',
        enter_your_location_to_start: 'Enter your location to start',
        enter_your_location: 'Enter your location',
        use_my_current_location: 'Use my current location',
        search_by_postcode: 'Search by postcode',
        material_search: 'Material Search',
        search_for_the_materials_you_need: 'Search for the materials you need.',
        enter_material: 'Enter material',
        log_in_to_see_full_details: 'Log in to see full details',
        onsite_facilities: 'Onsite Facilities',
        onsite_parking: 'Onsite Parking',
        toilets: 'Toilets',
        refreshments: 'Food',
        verified_merchant: 'Verified Merchant',
        closed: 'Closed',
        close: 'Close',
        weekday_short_1: 'Mon',
        weekday_short_2: 'Tue',
        weekday_short_3: 'Wed',
        weekday_short_4: 'Thu',
        weekday_short_5: 'Fri',
        weekday_short_6: 'Sat',
        weekday_short_7: 'Sun',
        get_directions: 'Get Directions',
        leave_a_review: 'Leave a Review',
        where_you_can_find_us: 'Where you can find us',
        opening_times: 'Opening Times',
        reviews: 'reviews',
        profile: 'Profile',
        log_in_to_see_your_profile: 'Log in to see your profile',
        make_changes_and_update_your_image: 'Make changes and update your image…',
        view_profile: 'View Profile',
        request_messages: 'Request Messages',
        log_in_to_see_your_requests: 'Log in to see your requests',
        deals_on_materials_right_at_your_fingertips: 'Deals on materials right at your fingertips…',
        sorry_something_went_wrong: 'Sorry, Something went wrong',
        we_were_unable_to_load_your_requests: 'We were unable to load your requests',
        no_messages: 'No Messages',
        any_requests_you_make_for_materials_will_be_stored_here: 'Any requests you make for materials will be stored here.',
        merchant_any_requests_you_make_for_materials_will_be_stored_here: 'Any requests you receive for materials will be stored here.',
        start_a_request: 'Start a request',
        no_results: 'No Results',
        we_were_unable_to_load_search_results: 'We were unable to load search results',
        loading_results_for: 'Loading results for',
        no_results_for: 'No results for',
        here_are_the_closest_merchants: 'Here are the closest merchants that supply your product.',
        select_all: 'Select All',
        send_to_selected: 'Send to Selected',
        showing_x_results_for: 'Showing {{resultsCount}} results for',
        material_request: 'Material Request',
        log_in_to_create_a_request: 'Login to make a request',
        log_in_to_make_your_requests: 'Login to make your requests',
        sending_request: 'Sending Request...',
        template_messages: 'Template messages',
        tap_to_use: 'Tap to use',
        write_your_message_here: 'Write your message here…',
        no_specials_offers: `Hum... {{merchantName}} doesn't have any special offers right now, but don't worry we'll notify you when they do!`,
        add_photo: 'Add photo',
        add_document: 'Add document',
        attached_file: 'Attached File:',
        tap_to_cancel: '(tap to cancel)',
        send_request: 'Send Request',
        click_here_to_download_file: 'Click here to Download File',
        download: 'Download',
        your_request_has_been_sent: 'Your Request Has Been Sent',
        we_will_notify_you_when_merchants_respond: 'We will notify you when merchants respond',
        tell_merchants_what_you_need: 'Tell merchants what you need...',
        error: 'Error',
        reviews: 'Reviews',
        loading_reviews: 'Loading reviews',
        no_reviews: 'No Reviews',
        sorry_we_could_not_load_reviews: 'Sorry, we could not load reviews',
        leave_review: 'Leave Review',
        saving_review: 'Saving review',
        leaving_a_review_for: 'You\'re leaving a review for',
        how_many_stars_would_your_rate_them: 'How many stars would you rate them?',
        descibe_your_experience: 'Descibe your experience (optional)',
        write_review: 'Write review',
        save_your_review: 'Save Your Review',
        please_leave_a_star_rating: 'Please leave a star rating',
        generic_error_message: 'Sorry, something went wrong. Please try again later.',
        forgot_password: 'Forgot Password',
        enter_your_email_and_we_will_send_you_a_reminder: 'Enter your registered email address and we will send you a reminder',
        email_address: 'Email Address',
        your_email_address: 'Your Email Address',
        their_email_address: 'Their Email Address',
        send_reminder: 'Send reminder',
        please_enter_your_validation_code: 'Please enter your validation code',
        recover_account: 'Recover Account',
        enter_the_security_code_we_sent_to_email: 'Enter the security code we sent to {{email}}',
        verify: 'Verify',
        success: 'Success',
        your_password_has_been_reset: 'Your password has been reset.',
        reset_password: 'Reset Password',
        new_password: 'New password',
        enter_a_new_password_at_least_8_characters_long: 'Enter a new password that\'s at least 8 characters long',
        re_type_password: 'Re-type password',
        set_password: 'Set password',
        verify_account: 'Verify Account',
        why_verify_your_profile: 'Why verify your profile?',
        verify_profile_reason: 'Verified profiles have a quicker response rate and seen as more trusting. It takes two minutes.',
        your_account_has_been_verified: 'Your account has been verified',
        enter_the_code_we_sent_to: 'Enter the code we sent to',
        we_will_send_your_verification_code_to: 'We will send your verification code to the following email address:',
        get_verification_code: 'Get Verification Code',
        refer_us_to_a_friend: 'Refer us to a friend',
        and_get_x_when_they_sign_up: 'and get £10 when they subscribe to an anual plan',
        invite_friend: 'Invite Friend',
        refer_friend_disclaimer: 'Please only invite friends that you know would want to use our services. By pressing "Invite Friend", you agree to our',
        your_referral_has_been_sent: 'Your referral has been sent',
        merchant_profile: 'Merchant Profile',
        edit: 'edit',
        edit_profile: 'Edit Profile',
        save: 'Save',
        discard: 'Discard',
        save_changes: 'Save changes?',
        save_changes_btn: 'Save changes',
        do_you_want_to_save_your_changes: 'Do you want to save your changes first?',
        business_name: 'Business Name',
        please_enter_a_business_name: 'Please enter a business name',
        please_enter_your_company_phone_number: 'Please enter your company phone number',
        please_enter_specials_photo_title: 'Please enter title of Specials photo',
        change_background_image: 'Change background image',
        change_profile_image: 'Change profile image',
        add_special: 'Add Special',
        add_specials_document: 'Add Special Document',
        add_specials_photo: 'Add Special Photo',
        uploaded_file: 'Uploaded File:',
        upload_specials_file: 'Upload Specials:',
        upload_specials_image: 'Upload Image',
        upload_specials_pdf: 'Upload PDF',
        remove_specials_file: 'Remove Specials File',
        merchant_name: 'Merchant Name',
        company_phone_number: 'Company Phone Number',
        phone: 'Phone',
        company_email_address: 'Company Email Address',
        product_categories: 'Product Categories',
        have_toilets_that_the_public_can_use: 'Have toilets that the public can use',
        have_food_and_refreshments_on_site: 'Have food and refreshments on site',
        free_parking_to_customers: 'Free parking to customers',
        merchant_location: 'Merchant Location',
        address_line_1: 'Address Line 1',
        town_city: 'Town/City',
        region_county: 'Region/County',
        postcode: 'Postcode',
        specials_photo_heading: 'Upload Specials',
        no_specials_photo_label: 'No Specials photo available',
        specials_photo_title: 'Specials Label',
        current_notifications: 'Current Notifications',
        notify_me_when_i_get_a_response: 'Notify me when I get a response',
        send_me_offers_and_promotional_emails: 'Send me offers & promotional emails',
        tell_us_whats_on_your_mind: "Tell us what's on your mind",
        its_too_expensive: "It's too expensive",
        not_enough_merchants_near_me: "Not enough merchants near me",
        i_find_it_too_hard_to_use: "I find it too hard to use",
        it_takes_too_long_to_get_a_response: "It takes too long to get a response",
        other: "Other",
        why_you_have_decided_to_delete_your_account: 'Before you go, we would be extremely grateful if you would let us know why you have decided to delete your account?',
        please_explain: 'Please explain',
        continue: 'Continue',
        we_are_sad_to_see_you_go: "It's a shame you don't want to stay with us but we understand and the door will be always open.",
        please_note: 'Please note',
        deleting_your_account_is_permanent_and_cannot_be_undone: 'Deleting your account is permanent and cannot be undone.',
        type_delete_in_the_space_below: 'Type "DELETE" in the space below',
        type_out_phrase: 'Type out phrase',
        make_sure_you_type_it_exactly: 'Make sure you type it exactly',
        delete_my_account: 'Delete my account',
        account_deleted: 'Account Deleted',
        we_re_sorry_to_see_you_leave: "We're sorry to see you leave! We hope to see you back here in the future. Until then. Stay safe and well.",
        thank_you: 'Thank you',
        its_a_shame_you_want_to_delete_your_account: "It's a shame you want to delete your account, but we understand and the door will always be open.",
        merchant_account_deletion_instructions: "To delete your account, please send your request to {{email}} and one of our team will assist you.",
        restore_purchases: 'Restore Purchases',
        subscription: 'Subscription',
        paywall_headline: 'Find building materials you need, that are local and available today.',
        paywall_freetrial: 'Start with a 3 month FREE trial',
        feature_benefit_1: 'Save time and money',
        feature_benefit_2: 'Message merchants direct',
        feature_benefit_3: 'Notifications, so you never miss a deal',
        feature_benefit_4: 'No middlemen, get the best deals direct',
        ios_paywall_legal_text: 'After free 3 month trial ends, payment will be taken automatically. If you choose to upgrade or downgrade before your free trial ends, you will not be charged until the trial period is over. Subscriptions will be charged to your iTunes account on confirmation. Subscriptions will automatically renew unless canceled within 24-hours before the end of the current period. You can cancel anytime with your iTunes account settings. Any unused portion of a free trial will be forfeited if you purchase a subscription. For more information, see our',
        android_paywall_legal_text: 'After free 3 month trial ends, payment will be taken automatically. If you choose to upgrade or downgrade before your free trial ends, you will not be charged until the trial period is over. Subscriptions will be charged to your Google Play account on confirmation. Subscriptions will automatically renew unless canceled within 24-hours before the end of the current period. You can cancel anytime with your Google Play account settings. Any unused portion of a free trial will be forfeited if you purchase a subscription. For more information, see our',
        upgrade_now: 'Upgrade now',
        popular: 'Popular',
        saver_flex: 'Saver flex',
        saver_plus: 'Saver plus',
        best_value: 'Best value',
        billed_monthly: 'Billed monthly',
        billing_term_year: 'Billed once as {{priceString}} / year',
        billing_term_six_months: 'Billed once as {{priceString}} / 6 months',
        billing_term_three_months: 'Billed once as {{priceString}} / 3 months',
        month_abbr_unit: 'm',
        your_trade: 'Your Trade',
        copied_to_clipboard: 'Copied to clipboard',
        notifications_title: 'Notifications',
        no_notifications: 'No Notifications',
        notifications_will_be_stored_here: 'Any notifications for Special Offers will be stored here.',
        seen: 'Seen'
    },
    ja: { welcome: 'こんにちは' },
});

const LocalisationProvider = ({ children }) => {
    const [lang, setLang] = React.useState(null);

    React.useEffect(() => {
        // Set the locale once.
        i18n.locale = Localization.locale;
        // const { locale } = Localization.getLocalizationAsync()
        // lang.locale =  locale
        // When a value is missing from a language it'll fallback to another language with the key present.
        i18n.enableFallback = true;

        setLang(i18n);
    }, []);

    return (
        <LocalisationContext.Provider value={lang}>{children}</LocalisationContext.Provider>
    );
};

const useLocalisationContext = () => {
    const user = React.useContext(LocalisationContext);
    if (user === undefined) {
        throw new Error("useLocalisationContext can only be used inside LocalisationProvider");
    }
    return user;
};

export {
    i18n as lang,
    LocalisationContext,
    LocalisationProvider,
    useLocalisationContext
};