import { Keyboard, Pressable, SafeAreaView, StyleSheet, Text, View } from 'react-native'
import React, { useLayoutEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { ThemeColours } from '../Styles/Theme'
import CloseButton from '../Components/CloseButton'
import GetEmailVerificationCodeCard from '../Components/GetEmailVerificationCodeCard'
import VerifyEmailCard from '../Components/VerifyEmailCard'
import { useNavigation } from '@react-navigation/native'
import dismissKeyboard from "../utils/dismissKeyboard"


const Stack = createStackNavigator();


const AccountEmailVerifyScreen = () => {

    const navigation = useNavigation()

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    return (
        <SafeAreaView style={styles.container}>
            <Pressable onPress={dismissKeyboard} style={{flex: 1}}>
                <View style={styles.header}>
                    <CloseButton onPress={() => navigation.goBack()} />
                </View>

                <Stack.Navigator>
                    <Stack.Screen 
                        name="GetEmailVerificationCode" 
                        component={GetEmailVerificationCodeCard}
                        options={{headerShown: false}} 
                    />
                    <Stack.Screen 
                        name="VerifyEmail" 
                        component={VerifyEmailCard} 
                        options={{headerShown: false}} 
                    />
                </Stack.Navigator>

                <View style={{height: 90}}></View>
            </Pressable>
        </SafeAreaView>
    )
}

export default AccountEmailVerifyScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 15,
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        flexDirection: 'row',
        width: '100%',
        height: 40,
        justifyContent: 'flex-end',
        marginBottom: 34,
    }
})