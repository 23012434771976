import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const UnreadCount = ({ count }) => {
    if (!count || count === 0) {
        return null;
    }
    return (
        <View style={styles.container}>
            <Text style={styles.badge}>{count > 99 ? '99+' : count}</Text>
        </View>
    )
}

export default UnreadCount
const bgColor = 'red';
const size = 22;
const styles = StyleSheet.create({
    container: {
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: bgColor,
        borderRadius: size / 2,
        borderWidth: 1,
        height: size,
        width: size,
        borderColor: bgColor,
        marginTop: 6
    },
    badge: {
        textAlign: 'center',
        fontSize: 12,
        color: '#fff',
    },
})