import React from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import { lang } from '../Providers/LocalisationProvider'

const SeenMessage = () => {
    return (<View style={styles.container}>
        <Text style={styles.text}>{lang.t('seen')}</Text>
        <Image source={require('../assets/images/seen.png')} style={styles.icon} />
    </View>
    )
}

export default SeenMessage

const styles = StyleSheet.create({
    container: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: -6,
        marginBottom: 8,
        marginRight: 12,
        flexDirection: 'row'
    },
    text: {
        fontSize: 12
    },
    icon: {
        width: 17,
        height: 20,
        marginLeft: 6
    }
})