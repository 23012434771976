import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'
import React from 'react'
import { ThemeColours } from '../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import RelativeLocalDateTime from './RelativeLocalDateTime'
import UnreadCount from './UnreadCount'


const ConversationItem = ({id, unreadCount, logoUrl, imgUrl, name, dateTime, lastMessage, merchantId, addressString, merchantLat, merchantLng}) => {
    const navigation = useNavigation();
    return (
    <TouchableOpacity style={styles.container} activeOpacity={0.5}
        onPress={() => navigation.navigate('ConversationScreen', {id, logoUrl, imgUrl, name, merchantId, addressString, merchantLat, merchantLng})}>
        <View style={styles.content}>
            <View style={styles.row}>
                <Image source={logoUrl} style={styles.avatar} />
                <View style={styles.col}>
                    <View style={styles.header}>
                        <Text style={styles.merchantName} numberOfLines={1} >{name}</Text>
                        <View>
                            <RelativeLocalDateTime dateTime={dateTime} />
                            <UnreadCount count={unreadCount} />
                        </View>
                    </View>
                    <Text style={styles.message} numberOfLines={1}>{lastMessage}</Text>
                </View>
            </View>
        </View>
    </TouchableOpacity>
    )
}

export default ConversationItem

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 15,
    },
    content: {
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
    },
    col: {
        flex: 1,
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    avatar: {
        width: 40,
        height: 40,
        marginRight: 10,
    },
    header: {
        flex: 1,
        height: 20,
        flexDirection: 'row',
        marginBottom: 5,
    },
    merchantName: {
        flex: 1,
        flexGrow: 1,
        fontSize: 16,
        marginRight: 10,
        color: ThemeColours.midnightBlue,
    },
    message: {
        //width: '100%',
        fontSize: 14,
        color: ThemeColours.blueGrey,
        paddingRight: 80
    },
})