import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { ThemeColours } from '../Styles/Theme'

const Header = ({title}) => {
    
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()

    return (
        <View style={[styles.header, {height: (70 + insets.top), paddingTop: insets.top}]}>
            <View>
                <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton} accessibilityLabel="Go back to previous screen">
                    <Image source={require('../assets/images/chevronLeftIcon-2x.png')} style={styles.icon} />
                </TouchableOpacity>
            </View>
            <View style={{flex: 1}}>
                <Text style={styles.headerTitle} numberOfLines={1}>{title}</Text>
            </View>
            <View style={{width: 44}}></View>
        </View>
    )
}

export default Header

const styles = StyleSheet.create({
    header: {
        height: 70,
        flexDirection: 'row',
        backgroundColor: ThemeColours.white,
        paddingRight: 20,
        alignItems: 'center',
        borderBottomColor: ThemeColours.steelBlueShadow,
        borderBottomWidth: 1,
    },
    headerTitle: {
        textAlign: 'center',
        lineHeight: 70,
        fontSize: 18,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    backButton: {
        width: 44,
        paddingLeft: 20,
        paddingTop: 12,
        paddingBottom: 12
    },
    icon: {
        width:9,
        height: 16,
    }
})