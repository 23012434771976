import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BackendService from '../Services/BackendService'

export const fetchRequests = createAsyncThunk('requests/fetchRequests', async () => {
  try {
    const response = await BackendService.getRequests()
    return response.data
  } catch (error) {
    console.log(error)
    return []
  }
})

const initialState = {
  requests: [],
  status: 'idle',
  error: null,
}

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    addRequest: (state, action) => {
      state.requests.push(action.payload)
    },
    setRequests: (state, action) => {
      state.requests = action.payload
    },
    resetRequestStatus: (state, action) => {
      state.status = initialState.status
    },
    addMessageToConversations: (state, action) => {
      // const {message, conversation} = action.payload

      // Ensure data is object, not string of json.
      let data = {}
      let message = {}
      if (typeof action.payload == 'string') {
        data = JSON.parse(action.payload)
        message = data.message
      } else {
        data = action.payload
        message = data.message
      }

      // Find and update the conversation that the message belongs to.
      const index = state.requests.findIndex((convo) => convo.id == message?.conversation_id)
      if (index !== -1) {
        const conversationCopy = state.requests[index]
        conversationCopy.last_message = [message]
        conversationCopy.unread_count = ((conversationCopy.unread_count || 0) + 1) ;

        // Move the conversation to the top of the list
        const requestsCopy = state.requests
        requestsCopy.splice(index, 1)
        requestsCopy.unshift(conversationCopy)
        state.requests = requestsCopy
      }
      else {
        // When the conversation is not found, create a new object.
        const newConversation = data.conversation
        newConversation.last_message = []
        newConversation.last_message.push(message)
        newConversation.unread_count = 1;

        // Add it to the top of the list
        const requestsCopy = state.requests
        requestsCopy.unshift(newConversation)
        state.requests = requestsCopy
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRequests.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchRequests.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        //state.requests = state.requests.concat(action.payload)
        state.requests = action.payload
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

// Action creators are generated for each case reducer function
export const { addRequest, setRequests, resetRequestStatus, addMessageToConversations } = requestsSlice.actions

export const selectRequests = (state) => state.requests.requests

export const selectRequestById = (state, postId) =>
  state.requests.requests.find((request) => request.id === postId)

export const countUnreadConversations = (state) => {

  if (state.requests.requests.length === 0) return null

  const count = state.requests.requests.reduce((unreadCount, conversation) => {
    if (conversation.unread_count) {
      return unreadCount + conversation.unread_count
    }
    return unreadCount;
  }, 0)
  return count === 0 ? null : count
}

export default requestsSlice.reducer