import { StyleSheet, Text, View, Image, Dimensions, TouchableWithoutFeedback, Modal } from 'react-native'
import React, { useState } from 'react'
import { ThemeColours } from '../Styles/Theme'
import ImageViewer from 'react-native-image-zoom-viewer';
import PdfAttachment from './Conversation/PdfAttachment';
import { useLocalisationContext } from '../Providers/LocalisationProvider';

const MerchantSpecialsFile = ({ merchant }) => {
    const lang = useLocalisationContext()
    const [specialsImageDetails, setSpecialsImageDetails] = useState([])
    const [scaledSpecialPhoto, setScaledSpecialPhoto] = useState({})

    function setSpecialsImageStyle(uri) {
        Image.getSize(uri, (width, height) => {
            const screenWidth = Dimensions.get('screen').width;
            const imageAspectRatio = width / height;
            setScaledSpecialPhoto({ width: '100%', height: screenWidth / imageAspectRatio });
        });
    }

    const lastMedia = merchant.specials_photo;
    if (!lastMedia || !merchant.specials_photo_title) {
        return null;
    }
    if (lastMedia['collection_name'] === 'specials_photo_pdf') {
        const label = `${lang.t('download')} ${merchant.specials_photo_title}`;

        return <View style={{ alignItems: 'center' }}>
            <Text style={styles.subHeading}>{merchant.specials_photo_title}</Text>
            <PdfAttachment media={lastMedia} label={label} />
        </View>
    } else if (lastMedia['collection_name'] === 'specials_photo') {
        setSpecialsImageStyle(lastMedia.original_url)
        return <>

            <Text style={styles.subHeading}>{merchant.specials_photo_title}</Text>
            <TouchableWithoutFeedback onPress={() => {
                {
                    setSpecialsImageDetails([{
                        url: lastMedia.original_url
                    }]);
                }
            }}>
                <Image source={{
                    uri: lastMedia.original_url
                }} style={{ ...scaledSpecialPhoto }}
                    resizeMode='contain' />
            </TouchableWithoutFeedback>
            <Modal visible={specialsImageDetails.length > 0} transparent={true}>
                <ImageViewer
                    enableSwipeDown
                    imageUrls={specialsImageDetails}
                    onCancel={() => setSpecialsImageDetails([])}
                />
            </Modal></>
    }
}

export default MerchantSpecialsFile
const styles = StyleSheet.create({
    subHeading: {
        fontSize: 16,
        lineHeight: 28,
        marginBottom: 16,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProMd',
    },
})