import React from 'react'
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'
import { Theme } from '../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import { Platform } from 'expo-modules-core'


const CategoryCard = ({ imgUrl, title }) => {
    const navigation = useNavigation();

    return (
        <TouchableOpacity
            onPress={() => {
                navigation.navigate('MaterialSearchResultsScreen', { searchString: title });
            }}
            style={styles.card}>
            <Image
                source={imgUrl}
                style={styles.image}
            />
            <View style={styles.footer}>
                <Text style={[styles.title, Theme.textMidnightBlue]}>{title}</Text>
            </View>
        </TouchableOpacity>
    )
}

export default CategoryCard
const image = Platform.OS === 'web' ? {
    width: 150,
    height: 140,
} : {
    width: 150,
    height: 65,
    flex: 1,
}
const styles = StyleSheet.create({
    card: {
        width: 150,
        marginRight: 10,
    },
    image,
    footer: {
        backgroundColor: '#ffffff',
        padding: 10,
        flex: 1,
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
    }
})