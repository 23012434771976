import { StyleSheet, Modal, Text, View, FlatList, Image, SafeAreaView, KeyboardAvoidingView, TouchableOpacity, TextInput, TouchableWithoutFeedback, Keyboard, Dimensions, Animated, ActivityIndicator } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useAuthContext } from '../auth'
import { Platform } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Theme, ThemeColours } from '../Styles/Theme'
import BackendService from '../Services/BackendService'
import * as ImagePicker from 'expo-image-picker'
import * as DocumentPicker from 'expo-document-picker';
import { lang } from '../Providers/LocalisationProvider'
import { addMessage, fetchMessages, setMessageStatusLoading, selectMessages, setConversationId } from '../Store/messagesSlice'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { addMessageToConversations } from '../Store/requestsSlice'
import ConversationHeader from '../Components/ConversationHeader'
import ImageViewer from 'react-native-image-zoom-viewer';
import SeenMessage from '../Components/SeenMessage'
import dismissKeyboard from "../utils/dismissKeyboard"
import PdfAttachment from '../Components/Conversation/PdfAttachment';
import PreviewAttachment from '../Components/Conversation/PreviewAttachment';

const ConversationScreen = () => {

    const navigation = useNavigation()
    const dispatch = useDispatch()
    const { user } = useAuthContext()
    const isLoggedIn = !!user?.id //convert to boolean
    const [message, setMessage] = useState()
    const messages = useSelector(selectMessages)
    const messagesStatus = useSelector(state => state.messages.status)
    const errorMessage = useSelector(state => state.messages.error)
    const [photo, setPhoto] = useState()
    const [showCopyToClipboardText, setShowCopyToClipboardText] = useState(false);
    const messageListRef = useRef()
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const { params: {
        id,
        logoUrl,
        imgUrl,
        name,
        merchantId,
        addressString,
        merchantLat,
        merchantLon,
    } } = useRoute();
    const [currentConvoId, setCurrentConvoId] = useState(-1)
    const [showImageDetails, setShowImageDetails] = useState([]);
    const [webUploadedPhoto, setWebUploadedPhoto] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isMenuRendered, setIsMenuRendered] = useState(false);
    const menuAnimation = useRef(new Animated.Value(0)).current;

    const showMenu = () => {
        setIsMenuRendered(true);
        Animated.timing(menuAnimation, {
            toValue: 1,
            duration: 300,
            useNativeDriver: true,
        }).start();
    };

    const hideMenu = () => {
        Animated.timing(menuAnimation, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
        }).start(() => setIsMenuRendered(false));
    };
    useEffect(() => {
        if (isMenuVisible) {
            showMenu();
        } else {
            hideMenu();
        }
    }, [isMenuVisible]);

    useEffect(() => {
        dispatch(setConversationId(id))

        if (currentConvoId === -1) {
            setCurrentConvoId(id)
        }

        // Fetch data for conversation id
        if (isLoggedIn && (currentConvoId !== id)) {
            setCurrentConvoId(id)
            dispatch(setMessageStatusLoading())
            dispatch(fetchMessages({ conversation_id: id }))
            BackendService.markMessagesAsRead(id);
        }

    }, [id, messagesStatus, isLoggedIn])

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    const sendMessage = async () => {
        let hasError = true;
        if (Platform.OS === 'web') {
            hasError = !message && !webUploadedPhoto;
        } else {
            hasError = !message && !photo;
        }
        if (hasError) return;

        const token = '' + user.id + (new Date().getTime())
        const now = new Date()
        const newMessage = {
            id: token,
            message: message ?? '',
            author_id: user.id,
            conversation_id: id,
            created_at: now.toISOString(),
            photo_url: photo?.uri,
            read_at: now.toISOString(),
        }
        if (Platform.OS === 'web') {
            if (webUploadedPhoto) {
                newMessage.media = [{
                    collection_name: webUploadedPhoto.type === 'application/pdf' ? 'pdf' : 'images',
                    file_name: webUploadedPhoto.name,
                    original_url: webUploadedPhoto.uri
                }]
            }

        } else {
            if (photo) {
                newMessage.media = [{
                    collection_name: photo.type === 'application/pdf' ? 'pdf' : 'images',
                    file_name: photo.name,
                    original_url: photo.uri
                }]
            }
        }
        dispatch(addMessage({ message: newMessage }))
        dispatch(addMessageToConversations({ message: newMessage }))

        const formData = new FormData()
        formData.append('token', token)
        formData.append('message', message ?? '')

        if (Platform.OS === 'web') {
            if (webUploadedPhoto) {
                formData.append('photo', webUploadedPhoto, webUploadedPhoto.name)
            }
        } else {
            if (photo) {
                formData.append('photo', photo)
            }
        }

        BackendService.saveMessage(id, formData).then((response) => {
            //update the id maybe, so long as the message contains the token.
            // console.log(response.data)
            if (Platform.OS === 'web') {
                dispatch(fetchMessages({ conversation_id: id }))
            }
        }).catch((error) => console.error(error))

        // Reset message text & photo
        setMessage('')
        setPhoto();
        setWebUploadedPhoto(null)
    }

    const choosePhotoAsync = async (setter, aspect) => {
        // Display the media libary to the user and wait for them to take a photo or to cancel
        if (Platform.OS !== 'web') {
            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All,
                // allowsEditing: true,
                quality: 0.2,
            });

            if (result.canceled) {
                //setter(undefined);
                return;
            }

            // ImagePicker saves the taken photo to disk and returns a local URI to it
            let localUri = result.assets[0].uri;
            let filename = localUri.split('/').pop();

            // Infer the type of the image
            let match = /\.(\w+)$/.exec(filename);
            let type = match ? `image/${match[1]}` : `image`;

            setIsMenuVisible(false);
            setter({
                uri: localUri,
                name: filename,
                type
            });
        } else {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = () => {
                setWebUploadedPhoto(input.files[0]);
            };
            input.click();
        }
    }

    const choosePdfAsync = async (setter, aspect) => {
        // Display the media libary to the user and wait for them to take a photo or to cancel
        if (Platform.OS !== 'web') {
            const result = await DocumentPicker.getDocumentAsync({
                type: ['application/pdf']
            });
            if (result.type !== 'success') {
                return;
            }
            setIsMenuVisible(false);
            setter({
                uri: result.uri,
                name: result.name,
                type: result.mimeType
            });
        } else {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'application/pdf');
            input.onchange = () => {
                setWebUploadedPhoto(input.files[0]);
            };
            input.click();
        }
    }

    const { width } = Dimensions.get('window')

    const renderMessage = ({ item }) => {
        const aspectRatio = item?.photo_meta?.aspect_ratio;
        const imageWidth = (width - 90); // scale width with aspectRatio
        const imageHeight = aspectRatio ? imageWidth * aspectRatio : (width - 40);

        return (
            <TouchableWithoutFeedback
                onPress={() => { }}
                key={item.id}
            >
                <View>
                    <View style={{
                        maxWidth: (width - 60), padding: 10, borderRadius: 6, marginBottom: 10,
                        marginLeft: (item.author_id == user.id ? 'auto' : 0),
                        backgroundColor: (item.author_id == user.id ? '#dbe7ff' : '#e8f0ff')
                    }}>
                        {
                            item && item.media && item.media.length > 0 && item.media[0].collection_name === 'images' && item?.photo_url &&
                            <TouchableWithoutFeedback onPress={() => {
                                {
                                    setShowImageDetails([{
                                        url: item.photo_url
                                    }]);
                                }
                            }}>
                                <Image source={{ uri: item.photo_url }}
                                    style={{
                                        width: imageWidth,
                                        height: imageHeight,
                                        resizeMode: 'contain',
                                        marginBottom: 10,
                                        ...(Platform.OS === 'web' ? {
                                            width: 400,
                                            height: 300
                                        } : {})
                                    }} />
                            </TouchableWithoutFeedback>
                        }
                        {
                            item && item.media && item.media.length > 0 && item.media[0].collection_name === 'pdf' ? <PdfAttachment media={item?.media[0]} /> : null
                        }
                        <Text style={{ fontSize: 15, color: ThemeColours.midnightBlue }} onLongPress={async () => {
                            await Clipboard.setStringAsync(item.message);
                            setShowCopyToClipboardText(true);
                            Animated.timing(fadeAnim, {
                                toValue: 1,
                                duration: 100,
                                useNativeDriver: true,
                            }).start();
                            setTimeout(() => {
                                Animated.timing(fadeAnim, {
                                    toValue: 0,
                                    duration: 1000,
                                    useNativeDriver: true,
                                }).start(({ finished }) => {
                                    if (finished) {
                                        setShowCopyToClipboardText(false);
                                    }
                                });
                            }, 1000)
                        }}>{item.message}</Text>
                    </View>
                    {item.last_seen_message ? <SeenMessage /> : null}
                </View>
            </TouchableWithoutFeedback>
        )
    }


    if (messagesStatus === 'loading') {
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: '#ffffff' }}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <ConversationHeader
                    merchantId={merchantId}
                    name={name}
                    logoUrl={logoUrl}
                    imgUrl={imgUrl}
                    addressString={addressString}
                    isMerchant={user.is_merchant}
                    merchantLat={merchantLat}
                    merchantLon={merchantLon}
                />
                <View style={styles.content}></View>
                <View style={{
                    flex: 1,
                    backgroundColor: ThemeColours.cloudWhite,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
                    <Text style={styles.h1}>{lang.t('loading')}</Text>
                </View>
                <View style={styles.footer}>

                    <TouchableOpacity
                        onPress={() => choosePhotoAsync(setPhoto)}
                        style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }}>
                        <Image source={require('../assets/images/sendImageIcon-2x.png')} style={{ width: 20, height: 18 }} />
                    </TouchableOpacity>
                    <TextInput
                        style={styles.messageBox}
                        placeholder='Your message here'
                        multiline={true}
                        textAlignVertical='top'
                        value={message}
                        onChangeText={setMessage}
                        placeholderTextColor={ThemeColours.steelBlue}
                    />
                    <TouchableOpacity style={styles.button} onPress={sendMessage}>
                        <Image source={require('../assets/images/sendIcon-2x.png')} style={{ width: 20, height: 18 }} />
                    </TouchableOpacity>
                </View>
            </SafeAreaView>
        )
    }

    if (messagesStatus === 'error') {
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: '#ffffff' }}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <ConversationHeader
                    merchantId={merchantId}
                    name={name}
                    logoUrl={logoUrl}
                    imgUrl={imgUrl}
                    addressString={addressString}
                    isMerchant={user.is_merchant}
                    merchantLat={merchantLat}
                    merchantLon={merchantLon}
                />
                <View style={[styles.content, { justifyContent: 'center', alignItems: 'center' }]}>
                    <Text style={styles.h1}>{lang.t('sorry_something_went_wrong')}</Text>
                    <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('we_were_unable_to_load_your_requests')}</Text>
                    <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{errorMessage}</Text>
                </View>
                <View style={styles.footer}></View>
            </SafeAreaView>
        )
    }

    if (messagesStatus === 'succeeded') {
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: '#ffffff' }}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : "height"}>
                    <TouchableWithoutFeedback style={{ flex: 1 }} onPress={dismissKeyboard}>
                        <View style={{ flex: 1 }}>
                            <ConversationHeader
                                merchantId={merchantId}
                                name={name}
                                logoUrl={logoUrl}
                                imgUrl={imgUrl}
                                addressString={addressString}
                                isMerchant={user.is_merchant}
                                merchantLat={merchantLat}
                                merchantLon={merchantLon}
                            />
                            <View style={styles.content}>
                                <FlatList
                                    onScroll={() => {
                                        dismissKeyboard();
                                    }}
                                    data={messages}
                                    renderItem={renderMessage}
                                    keyExtractor={item => item.id}
                                    inverted={1}
                                    contentContainerStyle={{
                                        paddingTop: 15,
                                        paddingBottom: 20,
                                        paddingHorizontal: 10,
                                    }}
                                    ref={messageListRef}
                                />
                            </View>
                            {showCopyToClipboardText ? <Animated.Text style={{ ...styles.copyToClipboardText, opacity: fadeAnim, }}>{lang.t('copied_to_clipboard')}</Animated.Text> : null}
                            <View style={styles.footer}>
                                <View style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    alignItems: 'center'
                                }}>
                                    {isMenuRendered && (
                                        <Animated.View style={[floatingMenu.menu, { opacity: menuAnimation }]}>
                                            <TouchableOpacity onPress={() => choosePdfAsync(setPhoto)} style={floatingMenu.menuItem}>
                                                <Text>{lang.t('add_document')}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => choosePhotoAsync(setPhoto)} style={floatingMenu.menuItem}>
                                                <Text>{lang.t('add_photo')}</Text>
                                            </TouchableOpacity>
                                        </Animated.View>
                                    )}
                                    {
                                        Platform.OS !== 'web' ?
                                            <TouchableOpacity
                                                onPress={() => setIsMenuVisible(!isMenuVisible)}
                                                style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                                <Image source={ isMenuVisible ? require('../assets/images/closeIcon-2-dark.png') : require('../assets/images/sendImageIcon-2x.png')} style={{ width: 20, height: 18 }} />
                                            </TouchableOpacity>
                                            : <>
                                                <TouchableOpacity
                                                    onPress={() => choosePdfAsync(setPhoto)}
                                                    style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                                    <Image source={require('../assets/images/attachmentIcon-steel-blue2x.png')} style={{ width: 17, height: 20 }} />
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    onPress={() => choosePhotoAsync(setPhoto)}
                                                    style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                                    <Image source={require('../assets/images/sendImageIcon-2x.png')} style={{ width: 20, height: 18 }} />
                                                </TouchableOpacity>
                                            </>
                                    }
                                    <TextInput
                                        style={styles.messageBox}
                                        placeholder='Your message here'
                                        multiline={true}
                                        textAlignVertical='top'
                                        value={message}
                                        onChangeText={setMessage}
                                        placeholderTextColor={ThemeColours.steelBlue}
                                    />
                                    <TouchableOpacity style={styles.button} onPress={sendMessage}>
                                        <Image source={require('../assets/images/sendIcon-2x.png')} style={{ width: 20, height: 18 }} />
                                    </TouchableOpacity>
                                </View>
                                {
                                    Platform.OS !== 'web' ?
                                        (photo ?
                                            <PreviewAttachment file={photo} onPress={() => {
                                                setPhoto()
                                            }} /> : null)
                                        : (
                                            webUploadedPhoto ?
                                                <PreviewAttachment file={webUploadedPhoto} onPress={() => {
                                                    setWebUploadedPhoto()
                                                }} /> : null
                                        )
                                }
                            </View>
                        </View>
                    </TouchableWithoutFeedback>

                    <Modal visible={showImageDetails.length > 0} transparent={true}>
                        <ImageViewer
                            imageUrls={showImageDetails}
                            onCancel={() => setShowImageDetails([])}
                            enableSwipeDown
                        />
                    </Modal>
                </KeyboardAvoidingView>
            </SafeAreaView>
        )
    }
}
export default ConversationScreen

const floatingMenu = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 20,
        height: 18,
    },
    menu: {
        position: 'absolute',
        borderRadius: 5,
        bottom: 70,
        //   padding: 10,
        elevation: 5,  // for Android
        shadowColor: 'black',  // for iOS
        shadowOpacity: 0.3,    // for iOS
        shadowOffset: { width: 0, height: 2 },  // for iOS
    },
    menuItem: {
        padding: 10,
        backgroundColor: '#fff',
        borderRadius: 30,
        marginTop: 16
    },
});
const styles = StyleSheet.create({
    header: {
        height: 70,
        flexDirection: 'row',
        backgroundColor: ThemeColours.white,
        paddingRight: 20,
        alignItems: 'center',
        borderBottomColor: ThemeColours.steelBlueShadow,
        borderBottomWidth: 1,
    },
    logo: {
        width: 40,
        height: 40,
        marginRight: 10,
        borderRadius: 2,
    },
    merchantName: {
        // flex: 1,
        // flexGrow: 1,
        fontSize: 16,
        marginRight: 10,
        color: ThemeColours.midnightBlue,
    },
    profileLink: {
        fontSize: 12,
        color: ThemeColours.blueGrey,
        textDecorationLine: 'underline',
    },
    content: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    footer: {
        minHeight: 80,
        maxHeight: 140,
        backgroundColor: ThemeColours.white,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: 'center',
        borderTopColor: ThemeColours.steelBlueShadow,
        borderTopWidth: 1,
    },
    messageBox: {
        flex: 1,
        marginLeft: 10,
        marginRight: 10,
        paddingLeft: 15,
        paddingRight: 15,
        lineHeight: 21,
        minHeight: 50,
        backgroundColor: ThemeColours.white,
        shadowColor: 'rgba(139, 165, 219, 0.3)',
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowRadius: 15,
        shadowOpacity: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.midnightBlue,
        borderRadius: 3
    },
    button: {
        ...Theme.button,
        width: 40,
        height: 40,
    },
    h1: {
        ...Theme.h1,
        marginBottom: 14,
        color: ThemeColours.midnightBlue
    },
    copyToClipboardText: {
        backgroundColor: '#fff',
        marginTop: 6,
        marginBottom: 6,
        textAlign: 'center',
    }
})