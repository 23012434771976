import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import NotificationsService from '../Services/NotificationsService'

export const fetchNotifications = createAsyncThunk('notifications/fetchNotifications', async () => {
  try {
    const response = await NotificationsService.getNotifications()
    return response.data
  } catch (error) {
    console.log(error)
    return []
  }
})
export const markNotificationAsRead = createAsyncThunk('notifications/markNotificationAsRead', async (id) => {
  try {
    const response = await NotificationsService.markNotificationAsRead(id)
    return response.data
  } catch (error) {
    console.log(error)
    return []
  }
})

const initialState = {
  notifications: [],
  status: 'idle',
  error: null,
}
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      let data = {}
      if (typeof action.payload == 'string') {
        data = JSON.parse(action.payload)
      } else {
        data = action.payload
      }
      const now = new Date();
      state.notifications.unshift({
        id: now.getTime(),
        created_at: now.toString(),
        notifiable_id: null,
        notifiable_type: 'App\\Models\\User',
        read_at: null,
        type: 'App\\Notifications\\NewSpecialOfferCreated',
        updated_at: now.toString(),
        data
      });
    },
    resetNotifications: (state) => {
      state.notifications = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.notifications = action.payload
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(markNotificationAsRead.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.notifications = [...state.notifications].map((notification) => {
          if (notification.id === action.payload.id) {
            return {
              ...action.payload
            }
          }
          return {
            ...notification
          }
        })
      })
      .addCase(markNotificationAsRead.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})
export const { addNotification, resetNotifications } = notificationsSlice.actions
export const selectNotifications = (state) => {
  return state.notifications.notifications
}
export const countUnreadNotifications = (state) => {
  const { notifications } = state.notifications;
  if (notifications.length === 0) return null

  const count = notifications.filter((item) => !item.read_at).length

  return count === 0 ? null : count
}

export default notificationsSlice.reducer
