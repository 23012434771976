import { StyleSheet, Text, View, Image } from 'react-native'

const FiveStarRating = ({rating, reviewCount}) => {

    if (reviewCount < 3) {
        return (
        <View style={styles.container}>
            <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
            <Text>New</Text>
        </View>
        )
    }

  return (
    <View style={styles.container}>
        {rating >= 1 ?
        <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
        : 
        <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
        }
        {rating >= 2 ?
        <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
        :
        <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
        }
        {rating >= 3 ?
        <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
        :
        <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
        }
        {rating >= 4 ?
        <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
        :
        <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
        }
        {rating >= 5 ?
        <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={styles.starIcon} />
        :
        <Image source={require('../assets/images/starIconOutline-2x.png')} style={styles.starIcon} />
        }
        <Text>{ reviewCount }</Text>
    </View>
  )
}

export default FiveStarRating

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        //flexWrap: "wrap",
        width: 140,
        justifyContent: 'flex-end',
    },
    starIcon: {
        marginRight: 5,
        width: 15,
        height: 15,
    }
})