import { ActivityIndicator, Alert, SafeAreaView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { useState } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import BackendService from '../Services/BackendService'
import { ThemeColours, Theme } from '../Styles/Theme'
import { getUser, saveToken, useAuthContext } from '../auth'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import resolveDeviceName from "../utils/resolveDeviceName";


const RecoverAccountCard = () => {

    const [isLoading, setLoading] = useState(false)
    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const { setAuthenticated } = useAuthContext()
    const [verificationCode, setVerificationCode] = useState('')
    const [errors, setErrors] = useState({})

    // Load email variable from route param.
    const {params: {
        email
    }} = useRoute();

    const canSend = () => {
        let validation = {}
        if ( !verificationCode || verificationCode.length < 4) {
            validation.code = lang.t('please_enter_your_validation_code')
        }
        
        setErrors(validation)
        
        return Object.keys(validation).length === 0
    }

    const recoverAccount = async () => {
        
        if (canSend() === false) {
            return
        }
    
        setLoading(true)

        try {
            const response = await BackendService.recoverAccount({
                email,
                code: verificationCode,
                device_name: resolveDeviceName()
            })
            const token = response.data.token
            const tokenResponse = await saveToken(token)

            const authUser = await getUser()
            setAuthenticated(authUser)

            setLoading(false)
            navigation.navigate('ResetPassword')

        } catch (error) {
            let validation = {}
            if (error?.response?.data) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    validation[key] = error.response.data.errors[key]
                })
                setErrors(validation)
            } else {
                console.error(error.message)
                Alert.alert(lang.t('generic_error_message'))
            }

            setLoading(false)
        }
    }

    if (isLoading) {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
            </View>
        )
    }

    return (
        <SafeAreaView style={styles.cardContainer}>
            <View style={styles.formContainer}>
                <Text style={styles.heading}>{lang.t('recover_account')}</Text>
                <Text style={styles.bodyCopy}>{lang.t('enter_the_security_code_we_sent_to_email', {email: email})}</Text>
                <View style={{flexDirection: 'row'}}>
                    <TextInput 
                    onChangeText={setVerificationCode}
                    returnKeyType="done"
                    autoComplete='off'
                    autoCorrect={false}
                    spellCheck={false}
                    style={[styles.formControl, {}]}
                    />
                </View>
                {errors?.code ? <Text style={styles.formValidationLabel}>{errors.code}</Text> : null }
            </View>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={recoverAccount} style={styles.button}>
                    <Text style={styles.buttonText}>{lang.t('verify')}</Text>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    )
}

export default RecoverAccountCard

const styles = StyleSheet.create({
    cardContainer: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        flexDirection: 'row',
        width: '100%',
        height: 40,
        justifyContent: 'flex-end',
        marginBottom: 34,
    },
    formContainer: {
        flex: 1,
        padding: 15,
    },
    formControl: {
        ...Theme.formControl,
    },
    formControlLabel: {
        ...Theme.formLabel,
        marginLeft: 10,
        marginBottom: 4,
    },
    formValidationLabel: {
        marginTop: 11,
        marginLeft: 12,
        fontSize: 12,
        color: ThemeColours.denialRed,
    },
    heading: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        marginBottom: 12,
    },
    bodyCopy: {
        fontSize: 16,
        color: ThemeColours.blueGrey,
        marginBottom: 34,
        maxWidth: 315,
    },
    buttonContainer: {
        padding: 15,
    },
    button: {
        ...Theme.button,
        shadowColor: 'transparent',
    },
    buttonText: {
        ...Theme.buttonText,
    },
})