import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const RelativeLocalDateTime = ({dateTime, color}) => {
  
    const dt = new Date(dateTime)
    const now = new Date()
    const milliseconds = Math.abs(dt.getTime() - now.getTime())
    const diffDays = (milliseconds / (1000 * 60 * 60 * 24));
    const relativeTime = (diffDays < 1) ? dt.toLocaleTimeString() : dt.toLocaleDateString();

    return (
        <View>
            <Text style={{
                ...styles.timeStamp,
                color: color ? color : '#ccd3dd'
            }}>{relativeTime}</Text>
        </View>
    )
}

export default RelativeLocalDateTime

const styles = StyleSheet.create({
    timeStamp: {
        fontSize: 12,
        letterSpacing: 0,
    },
})