import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { StyleSheet, Text, View, Image, Pressable } from 'react-native'
import { Theme, ThemeColours } from '../Styles/Theme'
import LocationDistance from './LocationDistance'
import Checkbox from 'expo-checkbox'
import { toggleResult, isSelected } from '../Store/resultsSlice';
import { useDispatch, useSelector } from 'react-redux'
import FiveStarRating from './FiveStarRating'


const SearchResult = ({ id, imgUrl, logoUrl, name, distance, has_parking, has_toilets, has_refreshments, rating, reviewCount }) => {

    const navigation = useNavigation()
    const isChecked = useSelector((state) => isSelected(state, id));
    const dispatch = useDispatch();


    return (
        <View style={styles.card}>
            <Image source={imgUrl} style={styles.featuredImage} />

            <View style={styles.checkboxContainer}>
                <Checkbox
                style={styles.checkbox}
                value={isChecked}
                onValueChange={(value) => {
                    dispatch(toggleResult(id))
                }}
                color={isChecked ? ThemeColours.midnightBlue : undefined}
                />
                <Pressable onPress={() => dispatch(toggleResult(id))}>
                    <Text style={styles.checkboxLabel}>Select Merchant</Text>
                </Pressable>
            </View>

            <Pressable style={styles.info} 
                onPress={() => navigation.navigate('MerchantScreen', {
                    id, imgUrl, logoUrl, name, distance, rating, reviewCount
                })}
                >
                <Image source={logoUrl} style={styles.logo} />
                <Text style={styles.merchantName}>{ name }</Text>
                <LocationDistance distance={distance} />
                <View style={[styles.row, {width: '100%', marginTop: 10}]}>
                    <View stlye={[styles.col]}>
                        <View style={[styles.row, {width: 140}]}>
                            {has_refreshments &&
                            <Image source={require('../assets/images/utilitiesFoodIcon-2x.png')} style={{marginRight: 12, width: 12, height: 15}} />
                            }
                            {has_parking &&
                            <Image source={require('../assets/images/utilitiesParkingIcon-2x.png')} style={{marginRight: 12, width: 15, height: 15}} />
                            }
                            {has_toilets &&
                            <Image source={require('../assets/images/utilitiesToiletIcon-2x.png')} style={{marginRight: 12, width: 12, height: 15}} />
                            }
                        </View>
                    </View>
                    <View style={[styles.col, {height: 30}]}>
                        <View style={{flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <FiveStarRating rating={rating} reviewCount={reviewCount} />
                        </View>
                    </View>
                </View>
            </Pressable>
        </View>
    )
}

export default SearchResult

const styles = StyleSheet.create({
    card: {
        borderRadius: 3,
        marginBottom: 17,
    },
    featuredImage: {
        height: 146,
        width: '100%',
        backgroundColor: '#d6e0f5',
    },
    col: {
        flex: 1,
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    info: {
        flex: 1,
        padding: 15,
        marginTop: -60,
        flexDirection: "row",
        flexWrap: "wrap",
        backgroundColor: ThemeColours.white,
        borderRadius: 3,
        marginLeft: 10,
        marginRight: 10,
        shadowColor: ThemeColours.steelBlue,
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    logo: {
        marginTop: -40,
        height: 52,
        width: 52,
        backgroundColor: '#2d2e89',
        shadowColor: ThemeColours.steelBlue,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowRadius: 8,
        shadowOpacity: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: ThemeColours.white,
        borderRadius: 3
    },
    merchantName: {
        ...Theme.h2, 
        color: ThemeColours.midnightBlue,
        marginBottom: 5,
        lineHeight: 30,
        width: '100%',
        fontFamily: 'ITCAvantGardeProMd',
    },
    checkboxContainer: {
        flexDirection: 'row',
        position: 'absolute',
        left: 15,
        top: 15,
    },
    checkbox: {
        marginRight: 10,
        backgroundColor: ThemeColours.white,
        borderWidth: 1,
        borderColor: '#c5d4f4',
        borderRadius: 5,
    },
    checkboxLabel: {
        color: ThemeColours.white,
        fontSize: 14,
        lineHeight: 24,
        fontFamily: 'ITCAvantGardeProMd',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: {width: -1, height: 1},
        textShadowRadius: 5,
    }
})