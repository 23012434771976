import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { Theme, ThemeColours } from '../../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useLocalisationContext } from '../../Providers/LocalisationProvider'

const ConfirmDeletionCard = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const lang = useLocalisationContext()

    return (
    <View style={[styles.container, {paddingBottom: insets.bottom}]}>
        <View style={{height: 100}}></View>
        <View style={{flex: 1}}>
            <Text style={styles.headingDanger}>{lang.t('account_deleted')}</Text>
            <Text style={styles.text}>{lang.t('we_re_sorry_to_see_you_leave')}</Text>
        </View>
        <View style={{paddingBottom: 30}}>
            <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Home')}>
                <Text style={styles.buttonText}>{lang.t('thank_you')}</Text>
            </TouchableOpacity>
        </View>
    </View>
    )
}

export default ConfirmDeletionCard

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 20,
    },
    headingDanger: {
        fontSize: 23,
        color: ThemeColours.denialRed,
        marginBottom: 30,
        fontFamily: 'ITCAvantGardeProBold',
    },
    text: {
      fontSize: 14,
      color: ThemeColours.blueGrey,
      marginBottom: 40,
    },
    button: {
        ...Theme.button,
    },
    buttonText: {
        ...Theme.buttonText,
    },
})