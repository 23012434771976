import { StyleSheet, Text, View, ScrollView, Keyboard, SafeAreaView, TextInput, Image, Pressable, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useNavigation, useRoute } from '@react-navigation/native'
import CloseButton from '../Components/CloseButton'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import {  useSelector, useDispatch } from 'react-redux'
import { fetchCategories, selectCategoryNames } from '../Store/categoriesSlice'
import dismissKeyboard from '../utils/dismissKeyboard'


const MaterialsSearchScreen = () => {
  
    const [hints, setHints] = useState([])
    const [text, setText] = useState('')
    const searchBox = useRef()
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const lang = useLocalisationContext()
    const categoriesStatus = useSelector(state => state.categories.status)
    const categories = useSelector(selectCategoryNames)
    
    const getHints = () => {
        if (text.trim().length < 1) {
            return [];
        }
        const normalised = text.toLowerCase();
        const filtered = categories.filter(hint => hint.indexOf(normalised) !== -1);

        return filtered.sort((a, b) => a.indexOf(normalised) - b.indexOf(normalised));
    }

    const {params: {
        searchString
    }} = useRoute();

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        });
        setText(searchString);

    }, [searchString])
  
    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(fetchCategories())
        }
        // Each time `text` updates we reload search hints
        setHints(getHints());

    }, [text, categoriesStatus, dispatch, categories])
    
    
    return (
    <SafeAreaView style={styles.container}>
        <TouchableWithoutFeedback style={{flex: 1}} onPress={dismissKeyboard}>
            <View style={styles.content}>
                <View style={{position: 'relative'}}>
                    <Text style={styles.h1}>{lang.t('material_search')}</Text>
                    <Text style={styles.copy}>{lang.t('search_for_the_materials_you_need')}</Text>
                    <CloseButton style={{right: 5, top: 5}} onPress={() => navigation.goBack()}></CloseButton>
                </View>
                <View style={styles.searchContainer}>
                    <Pressable onPress={() => {searchBox.current.focus()}} style={{padding: 15}}>
                        <Image source={require('../assets/images/searchIcon-2x.png')} style={{width: 20, height: 20}}/>
                    </Pressable>
                    <TextInput 
                        style={styles.input}
                        value={text}
                        ref={searchBox}
                        onChangeText={setText}
                        placeholder={lang.t('enter_material')}
                        placeholderTextColor={ThemeColours.steelBlue}
                        placeholderStyle={{fontSize: 18}}
                    />
                </View>
                <ScrollView style={{padding: 15}}>
                    {hints.map((value, index) => (
                        <TouchableOpacity 
                            key={index} 
                            onPress={() => {
                                setText(value);
                                navigation.navigate('MaterialSearchResultsScreen', {searchString: value});
                            }}
                            >
                            <Text style={styles.hintText}>{value}</Text>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
            </View>
        </TouchableWithoutFeedback>
    </SafeAreaView>
  )
}

export default MaterialsSearchScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    content: {
        padding: 15,
    },
    h1: {
        ...Theme.h1,
        color: ThemeColours.midnightBlue,
        marginBottom: 5,
    },
    input: {
        height: 50,
        marginLeft: 10,
        marginRight: 7,
        borderWidth: 1,
        padding: 10,
        flex: 1,
        borderColor: 'transparent',
        fontWeight: 'bold',
    },
    searchContainer: {
        backgroundColor: ThemeColours.white,
        height: 50,
        borderRadius: 3,
        flexDirection: 'row',
        shadowColor: ThemeColours.steelBlueShadow,
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
    },
    hintText: {
        lineHeight: 35,
        marginBottom: 5,
        fontSize: 18,
        letterSpacing: 0,
        color: ThemeColours.midnightBlue,
        fontWeight: 'bold',
    },
    copy: {
        color: ThemeColours.blueGrey,
        lineHeight: 28,
        marginBottom: 9,
    }
});