import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BackendService from '../Services/BackendService'

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
    try {
      const response = await BackendService.getCategories()
      return response.data
    } catch (error) {
      console.error(error)
      return []
    }
})

const initialState = {
    categories: [],
    status: 'idle',
    error: null,
}

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload
        } 
    },
    extraReducers(builder) {
        builder
          .addCase(fetchCategories.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchCategories.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Set fetched categories
            state.categories = action.payload
          })
          .addCase(fetchCategories.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
      }
})

// Action creators are generated for each case reducer function
export const { setCategories } = categoriesSlice.actions

export const selectCategories = (state) => state.categories.categories
export const selectParentCategories = (state) => 
  state.categories.categories.filter((cat) => Number(cat.parent) === 0)
export const selectCategoryNames = (state) => 
  state.categories.categories.map((cat) => cat.name.toLowerCase())

export const selectCategoriesById = (state, Id) =>
  state.categories.categories.find((cat) => cat.id === Id)

export default categoriesSlice.reducer