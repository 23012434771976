import { Modal, ScrollView, StyleSheet, Text, View, SafeAreaView, ActivityIndicator } from 'react-native'
import { useEffect, useState } from 'react'
import { ThemeColours } from '../Styles/Theme'
import CloseButton from './CloseButton'
import ReviewCard from './ReviewCard'
import BackendService from '../Services/BackendService'
import { lang } from '../Providers/LocalisationProvider'


const ReviewsModal = ({visible, onClose, merchantId}) => {

    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [reviews, setReviews] = useState([])
    

    useEffect(() => {
        if ( !visible) return

        setIsLoading(true)
        BackendService.getReviews(merchantId).then((response) => {
            setReviews(response.data)
            setIsLoading(false)
        }).catch((error) => {
            setHasError(true)
            setIsLoading(false)
        })

    }, [visible, merchantId])
    
    if (hasError) {
        return (
            <Modal visible={visible} onClose={onClose}>
                <SafeAreaView style={styles.container}>
                    <View style={styles.modalHeader}>
                        <Text style={styles.headerText}>{lang.t('reviews')}</Text>
                        <CloseButton onPress={onClose}></CloseButton>
                    </View>
                    <View style={{flex: 1, padding: 15}}>
                        <Text style={styles.headerText}>{lang.t('error')}</Text>
                        <Text style={styles.subHeading}>{lang.t('sorry_we_could_not_load_reviews')}</Text>
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    if (isLoading) {
        return (
            <Modal visible={visible} onClose={onClose}>
                <SafeAreaView style={styles.container}>
                    <View style={styles.modalHeader}>
                        <Text style={styles.headerText}>{lang.t('reviews')}</Text>
                        <CloseButton onPress={onClose}></CloseButton>
                    </View>
                    <View style={{flex: 1, padding: 15, justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={[styles.headerText, {marginBottom: 30}]}>{lang.t('loading_reviews')}</Text>
                        <ActivityIndicator size="large" color={ThemeColours.sunshineGold} />
                    </View>
                </SafeAreaView>
            </Modal>
        )
    }

    return (
        <Modal visible={visible} onClose={onClose}>
            <SafeAreaView style={styles.container}>
                <View style={styles.modalHeader}>
                    <Text style={styles.headerText}>{lang.t('reviews')}</Text>
                    <CloseButton onPress={onClose}></CloseButton>
                </View>
                {reviews ?
                <ScrollView style={{padding: 15}}>
                    {reviews.map((item) => (
                        <ReviewCard 
                        key={item.id}
                        authorAvatarUrl={item.author_avatar_url} 
                        authorName={item?.author?.name}
                        reviewText={item.review} 
                        rating={item.rating} />
                    ))}
                </ScrollView>
                :
                <Text style={{padding: 15}}>{lang.t('no_reviews')}</Text>
                }
            </SafeAreaView>
        </Modal>
    )
}

export default ReviewsModal

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    modalHeader: {
        height: 73,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        fontSize: 18,
        lineHeight: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    subHeading: {
        fontSize: 18,
        lineHeight: 30,
        color: ThemeColours.steelBlue
    }
})