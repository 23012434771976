import { StyleSheet, View } from 'react-native'
import FocusAwareStatusBar from '../../Components/FocusAwareStatusBar'
import { WebView } from 'react-native-webview'
import { BASE_URL } from '../../config'
import Header from '../../Components/Header'
import { useLayoutEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useLocalisationContext } from '../../Providers/LocalisationProvider'


const PrivacyPolicyScreen = () => {
    
    const source = `${BASE_URL}/legal/privacy-policy`
    const navigation = useNavigation()
    const lang = useLocalisationContext()

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    return (
        <View style={styles.container}>
            <FocusAwareStatusBar barStyle="dark-content" />
            <Header title={lang.t('privacy_policy')} />
            <WebView 
            style={styles.container}
            source={{ uri: source }}
            />
        </View>
    )
}

export default PrivacyPolicyScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})