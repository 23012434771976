import axios from 'axios';
import { getToken } from '../auth';
import { BASE_URL } from '../config';
import { Platform } from 'expo-modules-core';


class NotificationsService {

    baseUrl = BASE_URL;
    cdnUrl = '';

    constructor() {
        if (NotificationsService._instance) {
            return NotificationsService._instance
        }

        NotificationsService._instance = this;
    }

    async getNotifications() {
        const token = await getToken();
        try {
            const response = await axios.get(this.baseUrl + '/notifications', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    async markNotificationAsRead(id) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/notifications/${id}/mark-as-read`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
}

export default new NotificationsService();
