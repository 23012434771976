import { StyleSheet, View } from 'react-native'
import React from 'react'
import { ThemeColours } from '../Styles/Theme'

const ScrollIndicator = ({data, currentPosition}) => {
  return (
    <View style={styles.scrollIndicator}>
        {data.map((item, index) => (
            <View 
            key={index}
            style={{
                ...styles.scrollDot,
                backgroundColor: (currentPosition === index ? ThemeColours.midnightBlue : undefined)
            }}
            ></View>
        ))}
    </View>
  )
}

export default ScrollIndicator

const styles = StyleSheet.create({
    scrollIndicator: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    scrollDot: {
        height: 5,
        width: 5,
        marginRight: 5,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: ThemeColours.steelBlue,
    },
})