import { ActivityIndicator, Alert, StyleSheet, Switch, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useAuthContext } from '../auth'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { Theme, ThemeColours } from '../Styles/Theme'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import BackendService from '../Services/BackendService'
import Header from '../Components/Header'


const NotificationPreferencesScreen = () => {

    const navigation = useNavigation()
    const { user, setAuthenticated } = useAuthContext()
    const lang = useLocalisationContext()
    const [isLoading, setLoading] = useState(false)
    const [sendResponses, setSendResponses] = useState(0)
    const [sendPromotions, setSendPromotions] = useState(0)
    
    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    useEffect(() => {
        setSendPromotions(user.send_promotions)
        setSendResponses(user.send_responses)
    }, [user])

    const savePreferences = async () => {
        
        setLoading(true)

        try {
            const user_id = Number(user.id)
            const response = await BackendService.updateNotificationPreferences(user_id, {
                send_promotions: sendPromotions ? 1 : 0,
                send_responses: sendResponses ? 1 : 0,
            })
            
            // Update local copy of the user.
            const userCopy = user
            userCopy.send_promotions = sendPromotions
            userCopy.send_responses = sendResponses
            setAuthenticated(userCopy)
            
            setLoading(false)
            navigation.goBack()

        } catch (error) {
            console.error(error)
            Alert.alert(lang.t('generic_error_message'))
        }
        setLoading(false)
    }

    if (isLoading) {
        return (
            <View style={{flex:1, alignItems: 'center', justifyContent: 'center'}}>
                <ActivityIndicator size="large" color={ThemeColours.sunshineGold} />
            </View>
        );
    }

    return (
        <View style={{flex: 1}}>
            <FocusAwareStatusBar barStyle="dark-content" />
            <Header title={lang.t('notifications')} />

            <View style={styles.container}>
                <Text style={styles.smallHeading}>{lang.t('notifications')}{lang.t('current_notifications')}</Text>
                
                <View style={{marginTop: 10}}>
                    <View style={{flexDirection: 'row', minHeight: 34, marginBottom: 30}}>
                        <Text style={styles.facalityLabel}>{lang.t('notify_me_when_i_get_a_response')}</Text>
                        <Switch
                            trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                            thumbColor={sendResponses ? ThemeColours.white : ThemeColours.white}
                            ios_backgroundColor="#efeff0"
                            onValueChange={setSendResponses}
                            value={sendResponses}
                        />
                    </View>

                    <View style={{flexDirection: 'row', minHeight: 34, marginBottom: 30}}>
                        <Text style={styles.facalityLabel}>{lang.t('send_me_offers_and_promotional_emails')}</Text>
                        <Switch
                            trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                            thumbColor={sendPromotions ? ThemeColours.white : ThemeColours.white}
                            ios_backgroundColor="#efeff0"
                            onValueChange={setSendPromotions}
                            value={sendPromotions}
                        />
                    </View>
                </View>

                <View style={{flexDirection: 'row', marginTop: 40}}>
                    <TouchableOpacity style={styles.button} onPress={savePreferences}>
                        <Text style={styles.buttonText}>{lang.t('save')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

export default NotificationPreferencesScreen

const styles = StyleSheet.create({
    smallHeading: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 68,
        marginBottom: 40,
        color: ThemeColours.midnightBlue,
    },
    container: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    button: {
        ...Theme.button,
        maxWidth: 335,
        width: '100%',
    },
    buttonText: {
        ...Theme.buttonText,
    },
    facalityLabel: {
        flex: 1,
        width: '100%',
        fontSize: 16,
        color: ThemeColours.midnightBlue,
    },
})