import { Pusher } from '@pusher/pusher-websocket-react-native';
import PusherWeb from 'pusher-js';
import { PUSHER_API_KEY, PUSHER_CLUSTER } from '../config'
import { createContext, useState, useEffect } from 'react'
import { useAuthContext, getToken } from '../auth'
import BackendService from '../Services/BackendService'
import { addMessage } from '../Store/messagesSlice'
import { useDispatch } from 'react-redux'
import { addMessageToConversations } from '../Store/requestsSlice'
import { addNotification } from '../Store/notificationsSlice'
import { Platform } from 'expo-modules-core'

const MessageContext = createContext()

const MessageProvider = ({ children }) => {
    const { user } = useAuthContext()
    const [userId, setUserId] = useState()
    const dispatch = useDispatch()
    let pusher;

    useEffect(() => {
        let connectionState;
        const init = async () => {
            if (Platform.OS === 'web') {
                const token = await getToken();
                pusher = new PusherWeb(PUSHER_API_KEY, {
                    cluster: PUSHER_CLUSTER,
                    channelAuthorization: {
                        endpoint: 'https://staging.getmaterialnetwork.com/api/broadcasting/auth',
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Accept': 'application/json',
                        }
                    }
                });
            } else {
                pusher = Pusher.getInstance()
            }
        }

        const connect = async () => {

            const successStates = ["CONNECTED", "CONNECTING"]
            connectionState = Platform.OS === 'web' ? (PusherWeb.connection?.state || '').toUpperCase() : pusher.connectionState;
            // Only continue if the userId has changed
            if (userId === user?.id) return

            try {
                //TODO: check if we're alreayd initalise.
                if (Platform.OS === 'web' && connectionState === 'DISCONNECTED') {
                    await pusher.init({
                        apiKey: PUSHER_API_KEY,
                        cluster: PUSHER_CLUSTER,
                        onAuthorizer,
                        onConnectionStateChange,
                        onError,
                        onEvent,
                    });
                }

                // If we are subscribed to a channle but the user id has changed, unsubscribe.
                if (successStates.includes(connectionState)) {
                    await pusher.unsubscribe({ channelName: `private-user.${userId}` })
                }

                // Update the user id to current user.
                const newUserId = user?.id;
                setUserId(newUserId)

                if (newUserId) {
                    // If not already connected or connecting, connect to the Pusher network
                    if (false == successStates.includes(pusher.connectionState)) {
                        await pusher.connect()
                    }
                    // Subscribe to the channel for the new userId
                    const channelName = `private-user.${newUserId}`;

                    if (Platform.OS === 'web') {
                        const channel = pusher.subscribe(channelName);
                        channel.bind('new-message', (data) => {
                            onEvent({
                                eventName: 'new-message',
                                data
                            })
                        })
                        channel.bind("pusher:subscription_error", (error) => {
                            onError(error.error, error.type, error)
                        });
                    } else {
                        pusher.subscribe({ channelName })
                    }

                } else {
                    // Disconnect if we don't have a value for userId
                    if (Platform.OS === 'web') {
                        pusher.disconnect();
                    } else {
                        pusher.disconnect().catch((error) => console.error(error))
                    }

                }

            } catch (error) {
                console.error(error)
            }
        }
        init().then(() => {
            connect()
        });

        return async () => {
            if (connectionState === 'CONNECTED') {
                // await pusher.disconnect().catch((error) => console.error(error))
            }
        }

    }, [user])

    const onAuthorizer = async (channelName, socketId) => {
        try {
            const response = await BackendService.authenticateChannel({
                socket_id: socketId,
                channel_name: channelName,
            })

            const { data } = response

            return {
                auth: data?.auth
            }

        } catch (error) {
            console.log({ channelName, socketId, error })
            return {
                auth: 'An error occured'
            }
        }
    }

    const onConnectionStateChange = (currentState, previousState) => {
        console.log(`onConnectionStateChange. previousState=${previousState} newState=${currentState}`)
    }

    const onError = (message, code, error) => {
        console.log(`onError: ${message} code: ${code} exception: ${error}`)
    }

    const onEvent = (event) => {
        console.debug(`onEvent: ${event}`)

        // We save data to Redux when receive data from streams.
        // This class should only act as a router, by calling a
        // method in a slice which will do something with the data.
        if (event.eventName === 'new-message') {
            try {
                dispatch(addMessageToConversations(event.data))
                dispatch(addMessage(event.data))
            } catch (error) {
                console.error(error)
            }
        } else if (event.eventName.indexOf('BroadcastNotificationCreated') > -1) {
            try {
                dispatch(addNotification(event.data))
            } catch (error) {
                console.error(error)
            }

        }
    }

    return (
        <MessageContext.Provider value="">{children}</MessageContext.Provider>
    );
};

export {
    MessageContext,
    MessageProvider,
}