import { StyleSheet, Text, View, Modal, SafeAreaView, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, Alert } from 'react-native'
import { useState } from 'react'
import CloseButton from './CloseButton'
import { Theme, ThemeColours } from '../Styles/Theme'
import BackendService from '../Services/BackendService'
import { SvgStarEmpty, SvgStarFull } from './StarSVG'
import { useLocalisationContext } from '../Providers/LocalisationProvider'


const CreateReviewModal = ({visible, onClose, merchantId, merchantName}) => {

    const lang = useLocalisationContext()
    const ratings = [1, 2, 3, 4, 5]
    const [rating, setRating] = useState(undefined)
    const [review, setReview] = useState('')
    const [isSending, setIsSending] = useState(false)

    const saveReview = () => {
        if (!rating) {
            Alert.alert(lang.t('please_leave_a_star_rating'))
            return
        }

        setIsSending(true)
        const formData = new FormData()
        formData.append('rating', rating)
        if (review) {
            formData.append('review', review)
        }

        BackendService.createReview(merchantId, formData).then((result) => {
            setIsSending(false)
            setReview('')
            setRating(undefined)
            onClose()
        }).catch((error) => {
            console.error(error)
            setIsSending(false)
            onClose()
        })
    }

    if (isSending) {
        return (
        <Modal visible={visible} onClose={onClose}>
            <SafeAreaView style={styles.container}>
                <View style={styles.modalHeader}>
                    <Text style={styles.headerText}>{lang.t('leave_review')}</Text>
                    <CloseButton onPress={onClose}></CloseButton>
                </View>
                <View style={styles.content}>
                    <Text style={[styles.headerText, {marginBottom: 30}]}>{lang.t('saving_review')}</Text>
                    <ActivityIndicator size="large" color={ThemeColours.sunshineGold} />
                </View>
            </SafeAreaView>
        </Modal>
        )
    }

    return (
    <Modal visible={visible} onClose={onClose}>
        <SafeAreaView style={styles.container}>
            <View style={styles.modalHeader}>
                <Text style={styles.headerText}>{lang.t('leave_review')}</Text>
                <CloseButton onPress={onClose}></CloseButton>
            </View>
            <ScrollView style={styles.formContent}>
                
                <Text style={styles.subHeading}>{lang.t('leaving_a_review_for')}</Text>
                <Text style={styles.merchantName}>{merchantName}</Text>
                <Text style={styles.lableStars}>{lang.t('how_many_stars_would_your_rate_them')}</Text>

                <View style={{flexDirection: 'row', marginBottom: 26, justifyContent: 'center'}}>
                    {ratings.map((item) => (
                        <TouchableOpacity key={item} onPress={() => setRating(item)}>
                            {rating >= item ? <SvgStarFull style={styles.starIcon} /> : <SvgStarEmpty style={styles.starIcon} /> }
                        </TouchableOpacity>
                    ))}
                </View>
                <View>
                    <Text style={styles.inputLabel}>{lang.t('descibe_your_experience')}</Text>
                    <TextInput 
                    style={styles.inputReview}
                    placeholder={lang.t('write_review')}
                    multiline={true}
                    value={review}
                    onChangeText={setReview}
                    placeholderTextColor={ThemeColours.steelBlue}
                    />
                </View>
            </ScrollView>

            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={saveReview} style={styles.button}>
                    <Text style={styles.buttonText}>{lang.t('save_your_review')}</Text>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    </Modal>
    )
}

export default CreateReviewModal

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    modalHeader: {
        height: 73,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        fontSize: 18,
        lineHeight: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    subHeading: {
        fontSize: 18,
        lineHeight: 30,
        marginBottom: 5,
        color: ThemeColours.steelBlue,
        textAlign: 'center',
    },
    merchantName: {
        ...Theme.h1,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
        textAlign: 'center',
        marginBottom: 20,
        lineHeight: 28,
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    formContent: {
        flex: 1,
        padding: 15,
    },
    starIcon: {
        width: 35,
        height: 35,
        marginHorizontal: 10,
    },
    lableStars: {
        fontSize: 15,
        textAlign: 'center',
        marginBottom: 10,
        lineHeight: 20,
        color: ThemeColours.steelBlue
    },
    inputLabel: {
        fontSize: 12,
        color: ThemeColours.midnightBlue,
        marginBottom: 4,
        marginLeft: 10,
    },
    inputReview: {
        height: 180,
        width: '100%',
        fontSize: 18,
        lineHeight: 30,
        backgroundColor: ThemeColours.white,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.midnightBlue,
        borderRadius: 3,
        padding: 10,
        marginBottom: 15,
    },
    buttonText: {
        fontSize: 18,
        letterSpacing: 0,
        textAlign: 'center',
        color: 'rgb(0,35,87)',
    },
    buttonContainer: {
        padding: 15,
        backgroundColor: ThemeColours.white,
    },
    button: {
        backgroundColor: 'rgb(251,176,59)',
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        borderRadius: 3,
        padding: 13,
    },
})