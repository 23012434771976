import { StyleSheet, View } from 'react-native'
import React, { useLayoutEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import FeedbackCard from '../Components/AccountDeletion/FeedbackCard'
import TypeDeleteCard from '../Components/AccountDeletion/TypeDeleteCard'
import ConfirmDeletionCard from '../Components/AccountDeletion/ConfirmDeletionCard'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { useNavigation } from '@react-navigation/native'
import Header from '../Components/Header'
import { useLocalisationContext } from '../Providers/LocalisationProvider'


const Stack = createStackNavigator()

const DeleteAccountScreen = () => {

    const navigation = useNavigation()
    const lang = useLocalisationContext()

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    return (
    <View style={{flex: 1}}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <Header title={lang.t('delete_account')} />
        <Stack.Navigator>
            <Stack.Screen 
                name="AccountDeletionFeedback" 
                component={FeedbackCard} 
                options={{headerShown: false}} 
            />
            <Stack.Screen 
                name="AccountDeletionTypeDelete" 
                component={TypeDeleteCard} 
                options={{headerShown: false}} 
            />
            <Stack.Screen 
                name="AccountDeletionConfirm" 
                component={ConfirmDeletionCard} 
                options={{headerShown: false}} 
            />
        </Stack.Navigator>
    </View>
    )
}

export default DeleteAccountScreen

const styles = StyleSheet.create({})