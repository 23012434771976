import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { ThemeColours } from '../Styles/Theme'

const RadioButtonOption = ({
    optionId,
    optionText,
    onPress,
    selected
}) => {
    return (
        <TouchableOpacity key={optionId} onPress={onPress} style={styles.option}>
            <View style={styles.radioButton} borderColor={selected ? ThemeColours.midnightBlue : ThemeColours.steelBlue}>
                {selected ? <View style={styles.radioCenter}></View> : null }
            </View>
            <Text style={[styles.optionLabel, {color: selected ? ThemeColours.midnightBlue : ThemeColours.steelBlue}]}>{optionText}</Text>
        </TouchableOpacity>
    )
}

export default RadioButtonOption

const styles = StyleSheet.create({
    option: {
        flexDirection: 'row',
        marginBottom: 15
    },
    radioButton: {
        height: 30,
        width: 30,
        borderRadius: 15,
        paddingHorizontal: 4,
        paddingVertical: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.steelBlue,
    },
    radioCenter: {
        height: 20,
        width: 20,
        borderRadius: 10,
        backgroundColor: ThemeColours.sunshineGold,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.sunshineGold,
    },
    optionLabel: {
        fontSize: 18,
        color: ThemeColours.steelBlue,
        lineHeight: 30,
        marginLeft: 10,
    },
})