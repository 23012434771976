import { ActivityIndicator, Alert, SafeAreaView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import BackendService from '../Services/BackendService'
import { ThemeColours, Theme } from '../Styles/Theme'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import FocusAwareStatusBar from './FocusAwareStatusBar'


const ForgottenPasswordCard = () => {

    const [isLoading, setLoading] = useState(false)
    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/


    const canSend = () => {
        let validation = {}
        if (!email || reg.test(email) === false) {
            validation.email = lang.t('plese_enter_your_email')
        }
        setErrors(validation)
        
        return Object.keys(validation).length === 0
    }


    const sendReminder = async () => {
        
        if (canSend() === false) {
            return
        }
    
        setLoading(true)
    
        BackendService.sendPasswordReminder({
            email
        }).then(() => {
          
            setLoading(false)
            navigation.navigate('RecoverAccount', {email})
    
        }).catch((error) => {
            console.error(error.message)
            Alert.alert(lang.t('generic_error_message'))
            setLoading(false)
        })
    }

    if (isLoading) {
        return (
            <View style={styles.spinnerContainer}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
            </View>
        )
    }

    return (
        <SafeAreaView style={styles.cardContainer}>
            <FocusAwareStatusBar barStyle="dark-content" />
            <View style={styles.formContainer}>
                <Text style={styles.heading}>{lang.t('forgot_password')}</Text>
                <Text style={styles.bodyCopy}>{lang.t('enter_your_email_and_we_will_send_you_a_reminder')}</Text>
                <View style={styles.formGroup}>
                <Text style={styles.formControlLabel}>{lang.t('email_address')}</Text>
                <TextInput 
                    onChangeText={setEmail}
                    value={email}
                    placeholder={lang.t('your_email_address')}
                    keyboardType={'email-address'}
                    style={styles.formControl}
                />
                {errors.email ? <Text style={styles.formValidationLabel}>{errors.email}</Text> : null }
                </View>
            </View>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={sendReminder} style={styles.button}>
                    <Text style={styles.buttonText}>{lang.t('send_reminder')}</Text>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    )
}

export default ForgottenPasswordCard

const styles = StyleSheet.create({
    cardContainer: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        flexDirection: 'row',
        width: '100%',
        height: 40,
        justifyContent: 'flex-end',
        marginBottom: 34,
    },
    formContainer: {
        flex: 1,
        padding: 15,
    },
    formControl: {
        ...Theme.formControl,
    },
    formControlLabel: {
        ...Theme.formLabel,
        marginLeft: 10,
        marginBottom: 4,
    },
    formValidationLabel: {
        marginTop: 4,
        marginLeft: 12,
        fontSize: 12,
        color: ThemeColours.denialRed,
    },
    heading: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        marginBottom: 12,
    },
    bodyCopy: {
        fontSize: 16,
        color: ThemeColours.blueGrey,
        marginBottom: 34,
        maxWidth: 315,
    },
    buttonContainer: {
        padding: 15,
    },
    button: {
        ...Theme.button,
        shadowColor: 'transparent',
    },
    buttonText: {
        ...Theme.buttonText,
    },
})