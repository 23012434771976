import { StyleSheet, Text, View, TouchableOpacity, TextInput, Alert, ActivityIndicator } from 'react-native'
import React, { useState } from 'react'
import { Theme, ThemeColours } from '../../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useAuthContext, saveToken } from '../../auth'
import { useLocalisationContext } from '../../Providers/LocalisationProvider'
import BackendService from '../../Services/BackendService'


const TypeDeleteCard = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const lang = useLocalisationContext()
    const { user, setAuthenticated } = useAuthContext()
    const [phrase, setPhrase] = useState()
    const [errors, setErrors] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)


    const deleteUserAccount = async () => {
        if (!phrase || phrase.toLowerCase() !== 'delete') {
            setErrors({phrase: lang.t('make_sure_you_type_it_exactly')})
            return;
        }

        setIsLoading(true)
        try {
            await BackendService.deleteUserAccount(user.id)
            saveToken('')
            setAuthenticated(undefined)

            navigation.navigate('AccountDeletionConfirm')
        } catch (error) {
            console.error(error)
            Alert.alert(lang.t('generic_error_message'))
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return (
          <View style={styles.spinnerContainer}>
              <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
          </View>
        )
    }

    return (
    <View style={[styles.container, {paddingBottom: insets.top}]}>
        <View style={{flex: 1}}>
            <Text style={styles.text}>{lang.t('we_are_sad_to_see_you_go')}</Text>

            <Text style={[styles.text, {marginBottom: 32}]}>
              <Text style={styles.textBold}>{lang.t('please_note')}: </Text>
              {lang.t('deleting_your_account_is_permanent_and_cannot_be_undone')}</Text>

            <View>
                <Text style={styles.inputLabel}>{lang.t('type_delete_in_the_space_below')}</Text>
                <TextInput 
                style={styles.textInput} 
                value={phrase} 
                onChangeText={(value) => {
                    setPhrase(value)
                    setCanSubmit((value.length > 1))
                }} 
                placeholder={lang.t('type_out_phrase')}
                placeholderTextColor={ThemeColours.steelBlue}
                />
                { (errors && errors.phrase) ? <Text style={styles.errorLabel}>{lang.t('make_sure_you_type_it_exactly')}</Text> : null }
            </View>
        </View>
        <View style={{paddingBottom: 30}}>
            <TouchableOpacity style={[styles.button, {opacity: canSubmit ? 1 : 0.3 }]} onPress={deleteUserAccount} disabled={!canSubmit}>
                <Text style={styles.buttonText}>{lang.t('delete_my_account')}</Text>
            </TouchableOpacity>
        </View>
    </View>
    )
}

export default TypeDeleteCard

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 20,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: ThemeColours.cloudWhite,
    },
    text: {
        fontSize: 14,
        color: ThemeColours.blueGrey,
        marginBottom: 20,
    },
    textBold: {
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
    },
    inputLabel: {
        color: ThemeColours.midnightBlue,
        fontSize: 14,
        marginBottom: 4,
        marginLeft: 10,
    },
    textInput: {
        minHeight: 50,
        paddingLeft: 10,
        fontSize: 18,
        backgroundColor: ThemeColours.white,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.midnightBlue,
        borderRadius: 3,
        color: ThemeColours.steelBlue,
    },
    errorLabel: {
        color: ThemeColours.denialRed,
        fontSize: 14,
        marginTop: 4,
        marginLeft: 10,
    },
    button: {
        ...Theme.button,
    },
    buttonText: {
        ...Theme.buttonText,
    },
})