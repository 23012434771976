import { StyleSheet, Text, View, SafeAreaView, TouchableOpacity } from 'react-native'
import { Image } from 'react-native'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ThemeColours } from '../Styles/Theme'

import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { useNavigation, useRoute } from '@react-navigation/native'
import BackendService from '../Services/BackendService'
import * as Application from 'expo-application'
import MerchantSpecialsFile from '../Components/MerchantSpecialsFile'

const MerchantSpecialsOfferScreen = () => {
    const { params: {
        merchantId
    } } = useRoute()
    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const [merchant, setMerchant] = useState(null)

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    useEffect(() => {
        const loadMerchant = async () => {
            const { data } = await BackendService.getMerchant(merchantId);
            setMerchant(data);
        }
        loadMerchant().catch(error => console.error(error));
    }, [merchantId])

    const viewProfile = () => {
        const imgUrl = { uri: merchant.cover_photo_url };
        const logoUrl = { uri: merchant.logo_url };
        navigation.navigate('MerchantScreen', {
            id: merchantId,
            name: merchant.name,
            logoUrl,
            imgUrl
        })
    }

    const openNavigation = () => {
        showLocation({
            latitude: merchantLat,
            longitude: merchantLng,
            title: addressString, // optional
            googleForceLatLon: false, // optionally force GoogleMaps to use the latlon for the query instead of the title
            alwaysIncludeGoogle: true, // optional, true will always add Google Maps to iOS and open in Safari, even if app is not installed (default: false)
            appsWhiteList: ['apple-maps', 'google-maps', 'waze'],
            naverCallerName: Application.applicationId, // to link into Naver Map You should provide your appname which is the bundle ID in iOS and applicationId in android.
            directionsMode: 'car',
        })
    }
    return (
        <SafeAreaView style={styles.container}>
            {merchant ?
                <View style={styles.header}>
                    <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
                        <Image source={require('../assets/images/chevronLeftIcon-2x.png')} style={{ width: 9, height: 16 }} />
                    </TouchableOpacity>
                    <Image source={merchant.logoUrl} style={styles.logo} />
                    <View style={{ flex: 1 }}>
                        <Text style={styles.merchantName} numberOfLines={1}>{merchant.name}</Text>
                        <TouchableOpacity onPress={viewProfile}>
                            <Text style={styles.profileLink}>{lang.t('view_profile')}</Text>
                        </TouchableOpacity>

                    </View>
                    <TouchableOpacity onPress={openNavigation}>
                        <Image source={require('../assets/images/viewOnMapIcon-2x.png')} style={{ width: 22, height: 20 }} />
                    </TouchableOpacity>
                </View> : null}
            {merchant ?
                <MerchantSpecialsFile merchant={merchant} /> : null
            }
            {
                merchant && !merchant.specials_photo_title ? <View style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Text style={{
                        width: '70%',
                        fontSize: 18,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: ThemeColours.midnightBlue
                    }}>{lang.t('no_specials_offers', { merchantName: merchant.name })}</Text>
                </View> : null
            }
        </SafeAreaView>
    )
}

export default MerchantSpecialsOfferScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    subHeading: {
        fontSize: 24,
        lineHeight: 28,
        marginVertical: 16,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProMd',
        textAlign: 'center'
    },
    header: {
        height: 70,
        flexDirection: 'row',
        backgroundColor: ThemeColours.white,
        paddingRight: 20,
        alignItems: 'center',
        borderBottomColor: ThemeColours.steelBlueShadow,
        borderBottomWidth: 1,
    },
    backButton: {
        paddingLeft: 20,
        paddingRight: 26,
        paddingTop: 12,
        paddingBottom: 12
    },
    logo: {
        width: 40,
        height: 40,
        marginRight: 10,
        borderRadius: 2,
    },
    merchantName: {
        fontSize: 16,
        marginRight: 10,
        color: ThemeColours.midnightBlue,
    },
    profileLink: {
        fontSize: 12,
        color: ThemeColours.blueGrey,
        textDecorationLine: 'underline',
    },
})