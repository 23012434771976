import { ActivityIndicator, Alert, Keyboard, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import React, { useState } from 'react'
import { Theme, ThemeColours } from '../../Styles/Theme'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import RadioButtonOption from '../RadioButtonOption'
import { useNavigation } from '@react-navigation/native'
import BackendService from '../../Services/BackendService'
import { useLocalisationContext } from '../../Providers/LocalisationProvider'
import { useAuthContext } from '../../auth'
import dismissKeyboard from '../../utils/dismissKeyboard'

const FeedbackCard = () => {

    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const { user } = useAuthContext()
    const lang = useLocalisationContext()
    const [option, setOption] = useState()
    const [optionDetails, setOptionDetails] = useState()
    const [canSubmit, setCanSubmit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const options = [
        {id: 1, text: lang.t('its_too_expensive')},
        {id: 2, text: lang.t('not_enough_merchants_near_me')},
        {id: 3, text: lang.t('i_find_it_too_hard_to_use')},
        {id: 4, text: lang.t('it_takes_too_long_to_get_a_response')},
        {id: 5, text: lang.t('other')},
    ]


    const submitFeedback = async () => {
        setIsLoading(true)
        try {
            await BackendService.createFeedback({
                reason: option,
                other_reason: optionDetails
            })
            setIsLoading(false)
            navigation.navigate('AccountDeletionTypeDelete')
        } catch (error) {
            console.error(error)
            setIsLoading(false)
            Alert.alert(lang.t('generic_error_message'))
        }
    }


    if (isLoading) {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
            </View>
        )
    }

    if (user?.is_merchant) {
        return (
            <View style={{flex: 1, paddingHorizontal: 20, paddingTop: (insets.bottom + 20)}}>
                <Text style={styles.text}>{lang.t('its_a_shame_you_want_to_delete_your_account')} </Text>
                <Text style={styles.text}>{lang.t('merchant_account_deletion_instructions', {email: 'support@getmaterialnetwork.com'})}</Text>
            </View>
        )
    }

    return (
    <TouchableWithoutFeedback style={{flex: 1}} onPress={() => dismissKeyboard()}>
        <ScrollView style={[styles.container, {paddingBottom: insets.bottom}]} contentContainerStyle={{flex: 1}}>
            <Text style={styles.text}>{lang.t('why_you_have_decided_to_delete_your_account')}</Text>
            <View style={styles.optionsContainer}>
                {options.map((item) => (
                    <RadioButtonOption 
                    key={item.id} 
                    optionId={item.id} 
                    onPress={() => {
                      setOption(item.id)
                      setCanSubmit(true)
                    }} 
                    optionText={item.text} 
                    selected={option == item.id} 
                    />
                ))}
                {(option == 5) ? 
                <>
                <Text style={styles.inputLabel}>{lang.t('please_explain')}</Text>
                <TextInput 
                value={optionDetails} 
                onTextInput={setOptionDetails} 
                style={styles.textArea}
                placeholder={lang.t('tell_us_whats_on_your_mind')}
                multiline={true}
                />
                </>
                : null}
            </View>
            <View style={{paddingBottom: 30}}>
                <TouchableOpacity style={[styles.button, {opacity: canSubmit ? 1 : 0.3 }]} onPress={() => submitFeedback()} disabled={!canSubmit}>
                    <Text style={styles.buttonText}>{lang.t('continue')}</Text>
                </TouchableOpacity>
            </View>
        </ScrollView>
    </TouchableWithoutFeedback>
    )
}

export default FeedbackCard

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 20,
        paddingTop: 20,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 14,
        color: ThemeColours.blueGrey,
        marginBottom: 40,
    },
    optionsContainer: {
        flex: 1,
    },
    inputLabel: {
        color: ThemeColours.midnightBlue,
        fontSize: 14,
        marginBottom: 4,
        marginLeft: 10,
    },
    textArea: {
        minHeight: 135,
        paddingTop: 10,
        paddingHorizontal: 10,
        fontSize: 18,
        backgroundColor: ThemeColours.white,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.midnightBlue,
        borderRadius: 3
    },
    button: {
        ...Theme.button,
    },
    buttonText: {
        ...Theme.buttonText,
    },
})