import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { ThemeColours } from '../Styles/Theme'

const CategoryTag = ({name, id, isSelected, onPress}) => {
    
    return (
        <TouchableOpacity
            key={id}
            onPress={onPress}
            style={[styles.category, isSelected ? styles.categorySelected : styles.categoryDefault]}
            borderColor={isSelected ? ThemeColours.midnightBlue : '#ccd3dd'}
        >
            <View style={{flexDirection: 'row'}}>
            <Text style={[styles.categoryText, isSelected ? styles.categoryTextSelected : styles.categoryTextDefault]} numberOfLines={1}>{name}</Text>
            {isSelected ? 
            <Image style={styles.icon} source={require('../assets/images/Categories-Selected-Icon-2x.png')} />
            :
            <Image style={styles.icon} source={require('../assets/images/Categories-Unselected-Icon-2x.png')} />
            }
            </View>
        </TouchableOpacity>
    )
}

export default CategoryTag

const styles = StyleSheet.create({
    category: {
        paddingHorizontal: 14,
        marginBottom: 10,
        marginRight: 10,
        height: 44,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 22,
    },
    categoryDefault: {
        borderColor : '#ccd3dd',
    },
    categorySelected: {
        borderColor: ThemeColours.midnightBlue,
    },
    categoryText: {
        lineHeight: 44,
        fontSize: 14,
    },
    categoryTextDefault: {
        color : '#ccd3dd',
    },
    categoryTextSelected: {
        color: ThemeColours.midnightBlue,
    },
    icon: {
        width: 18,
        height: 19,
        marginTop: 12,
        marginLeft: 6,
    }
})