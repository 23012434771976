import { ActivityIndicator, Alert, Keyboard, KeyboardAvoidingView, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import BackendService from '../Services/BackendService'
import CloseButton from '../Components/CloseButton'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import dismissKeyboard from '../utils/dismissKeyboard'

const ReferAndEarnScreen = () => {

    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const insets = useSafeAreaInsets()
    const [isLoading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})
    const firstNameInput = useRef();
    const emailInput = useRef();
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/

    useLayoutEffect(() => {
      navigation.setOptions({
          headerShown: false,
      }) 
    }, [])

  
    const canSend = () => {
        let validation = {}
        if (!email || reg.test(email) === false) {
            validation.email = lang.t('please_enter_a_valid_email')
        }
        if (!firstName) {
            validation.first_name = lang.t('please_enter_your_friends_name')
        }
        setErrors(validation)
        
        return Object.keys(validation).length === 0
    }


    const sendReferal = async () => {
        
        if (canSend() === false) {
            return
        }

        setLoading(true)

        BackendService.sendReferral({
            first_name: firstName,
            email
        }).then((response) => {
          
            // Rest the form.
            setEmail('')
            setFirstName('')

            // Turn off loading indicator.
            setLoading(false)
            
            // Show success message.
            Alert.alert(lang.t('success'), lang.t('your_referral_has_been_sent'));

        }).catch((error) => {
            console.error(error.message)
            Alert.alert(lang.t('generic_error_message'))
            setLoading(false)
        })
    }
  
    if (isLoading) {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
            </View>
        )
    }

    return (
    <KeyboardAvoidingView style={{flex: 1}} behavior={Platform.OS === "ios" ? "padding" : "height"}>
        <TouchableWithoutFeedback style={{flex: 1}} onPress={dismissKeyboard}>
            <ScrollView style={styles.container} contentContainerStyle={{flex:1}}>
                <View style={[styles.header, {paddingTop: insets.top}]}>
                    {/* Background image */}
                    <View style={{flex: 1, height: (70)}}>
                        <Text style={styles.headerText} numberOfLines={1}>{lang.t('refer_and_earn')}</Text>
                        <CloseButton onPress={() => navigation.goBack()}></CloseButton>
                    </View>
                    <Text style={styles.heading}>{lang.t('refer_us_to_a_friend')}</Text>
                    <Text style={[styles.subHeading, {maxWidth: 280}]}>{lang.t('and_get_x_when_they_sign_up')}</Text>
                </View>
                <View style={styles.content}>
                    <View style={styles.form}>

                        <View style={styles.formGroup}>
                            <Text style={styles.formControlLabel}>{lang.t('your_friends_name')}</Text>
                            <TextInput 
                                onChangeText={setFirstName}
                                value={firstName}
                                placeholder={lang.t('first_name')}
                                ref={firstNameInput}
                                style={styles.formControl}
                                onSubmitEditing={() => {emailInput.current.focus()}}
                            />
                            {errors.first_name ? <Text style={styles.formValidationLabel}>{errors.first_name}</Text> : null }
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.formControlLabel}>{lang.t('their_email_address')}</Text>
                            <TextInput 
                                onChangeText={setEmail}
                                value={email}
                                placeholder={lang.t('email_address')}
                                keyboardType={'email-address'}
                                ref={emailInput}
                                style={styles.formControl}
                                returnKeyType={'done'}
                                onEndEditing={() => {dismissKeyboard()}}
                            />
                            {errors.email ? <Text style={styles.formValidationLabel}>{errors.email}</Text> : null }
                        </View>

                        <View style={{paddingVertical: 15, flexDirection: 'row', flexWrap: 'wrap'}}>
                            <Text style={styles.disclaimer}>{lang.t('refer_friend_disclaimer')}</Text>
                            <Text style={styles.disclaimerAction} onPress={() => navigation.navigate('TermsAndConditionsScreen')}>{lang.t('terms_and_conditions')}</Text>
                        </View>

                        <View style={styles.buttonContainer}>
                            <TouchableOpacity onPress={sendReferal} style={styles.button}>
                                <Text style={styles.buttonText}>{lang.t('invite_friend')}</Text>
                            </TouchableOpacity>
                        </View>

                    </View>
                </View>
            </ScrollView>
        </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    )
}

export default ReferAndEarnScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        backgroundColor: ThemeColours.steelBlue,
        height: 274,
        paddingBottom: 15,
        paddingLeft: 15,
    },
    headerText: {
        textAlign: 'center',
        lineHeight: 70,
        fontSize: 18,
        color: ThemeColours.white,
        fontFamily: 'ITCAvantGardeProBold',
    },
    heading: {
      fontSize: 25,
      color: ThemeColours.white,
      marginBottom: 5,
      lineHeight: 36,
      fontFamily: 'ITCAvantGardeProBold',
    },
    subHeading: {
      fontSize: 18,
      color: ThemeColours.white,
      lineHeight: 22,
    },
    content: {
        flex: 1,
        paddingTop: 31,
    },
    form: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    formGroup: {
        marginBottom: 16,
    },
    formControl: {
        ...Theme.formControl,
    },
    formControlLabel: {
        ...Theme.formLabel,
        marginLeft: 10,
        marginBottom: 4,
    },
    formValidationLabel: {
        marginTop: 4,
        marginLeft: 12,
        fontSize: 12,
        color: ThemeColours.denialRed,
    },
    buttonContainer: {
        marginTop: 25,
        marginBottom: 31,
    },
    button: {
        ...Theme.button,
        shadowColor: 'transparent',
    },
    buttonText: {
        ...Theme.buttonText,
    },
    disclaimer: {
        fontSize: 14,
        color: ThemeColours.blueGrey,
    },
    disclaimerAction: {
        fontSize: 14,
        color: ThemeColours.blueGrey,
        textDecorationLine: 'underline',
    }
})