import { ActivityIndicator, Alert, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import { useAuthContext } from '../auth'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import BackendService from '../Services/BackendService'


const GetEmailVerificationCodeCard = () => {

    const { user } = useAuthContext()
    const [isLoading, setLoading] = useState(false)
    const navigation = useNavigation()
    const lang = useLocalisationContext()

    const requestCode = async () => {

        setLoading(true)

        try {
            const response = await BackendService.requestEmailVerificationCode(user.id)
            setLoading(false)
            navigation.navigate('VerifyEmail')
        } catch(error) {
            console.error(error.message)
            setLoading(false)
            Alert.alert('Error', lang.t('generic_error_message'))
        }
    }

    if (isLoading) {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
            </View>
        )
    }

    return (
    <View style={styles.container}>
        <View style={{flex: 1}}>
            <Text style={styles.h1}>{lang.t('verify_account')}</Text>
            <Text style={styles.copy}>{lang.t('we_will_send_your_verification_code_to')}</Text>
            <Text style={styles.email} numberOfLines={1}>{user.email}</Text>
        </View>
        <View>
            <TouchableOpacity onPress={requestCode} style={styles.button}>
                <Text style={styles.buttonText}>{lang.t('get_verification_code')}</Text>
            </TouchableOpacity>
        </View>
    </View>
    )
}

export default GetEmailVerificationCodeCard

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
        paddingLeft: 20,
        paddingRight: 20,
    },
    h1: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        marginBottom: 38,
    },
    copy: {
        fontSize: 18,
        color: ThemeColours.blueGrey,
        marginBottom: 26,
    },
    email: {
        fontWeight: 'bold',
        fontSize: 18,
        color: ThemeColours.midnightBlue,
    },
    button: {
        ...Theme.button,
        shadowColor: 'transparent',
    },
    buttonText: {
        ...Theme.buttonText,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ThemeColours.cloudWhite,
    }
})