import { StyleSheet } from 'react-native'

const colours = {
    steelBlue: '#99a7bc',
    midnightBlue: '#002357',
    cloudWhite: '#f8faff',
    steelBlueShadow: 'rgba(153, 167, 188, 0.3)',
    grantedGreen: '#73e213',
    veryLightBlue: '#dae5fc',
    sunshineGold: '#fbb03b',
    verifiedGreen: '#15e5b9',
    white: '#ffffff',
    blueGrey: '#667b9a',
    denialRed: '#e21513',
}

const Theme = StyleSheet.create({
    heroTitle: {
        fontSize: 30,
        lineHeight: 38,
        color: colours.white,
        fontWeight: 'bold'
    },
    h1: {
        fontSize: 23,
        fontWeight: 'bold'
    },
    h2: {
        fontSize: 23,
        fontWeight: 'bold'
    },
    bodyText: {
        fontWeight: '300',
        fontSize: 20,
        lineHeight: 30,
        //color: colours.steelBlue,
    },
    placeholderText: {
        fontSize: 18,
        color: colours.steelBlue,
        fontWeight: '400',
    },
    subheadingText: {
        fontSize: 18,
        fontWeight: '400',
        lineHeight: 30,
    },
    smallText: {
        fontSize: 15,
        fontWeight: '400',
    },
    inputLabelText: {
        fontSize: 12,
        fontWeight: '400',
        color: colours.midnightBlue,
    },
    textMidnightBlue: {
        color: colours.midnightBlue,
    },
    textSteelBlue: {
        color: colours.steelBlue,
    },
    textBlueGrey: {
        color: colours.blueGrey,
    },
    textWhite: {
        color: colours.white,
    },
    button: {
        backgroundColor: 'rgb(251,176,59)',
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        borderRadius: 3,
        padding: 13,
    },
    buttonText: {
        fontSize: 18,
        letterSpacing: 0,
        textAlign: 'center',
        color: 'rgb(0,35,87)'
    },
    bgLight: {
        backgroundColor: colours.white,
    },

    //Forms
    form: {
        maxWidth: 345,
        width: '100%',
        paddingHorizontal: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    formGroup: {
        marginBottom: 16,
        width: '100%',
    },
    formLabel: {
        marginBottom: 4,
        marginLeft: 12,
        fontSize: 12,
        color: colours.midnightBlue,
    },
    inputContainer: {
        width: '100%',
    },
    formControl: {
        width: '100%',
        paddingHorizontal: 10,
        height: 50,
        fontSize: 18,
        backgroundColor: colours.white,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: colours.midnightBlue,
        borderRadius: 3
    },
    formValidationLabel: {
        marginTop: 4,
        marginLeft: 12,
        fontSize: 12,
        color: colours.denialRed,
    },
});

export {
    Theme,
    colours as ThemeColours,
};