import { StyleSheet, Text, View, SafeAreaView, TouchableOpacity } from 'react-native'
import React, { useEffect, useLayoutEffect } from 'react'
import { ThemeColours } from '../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import { GOOGLE_MAPS_API_KEY } from '../config'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { setLocation, setAddressString, selectAddressString } from '../Store/locationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useRef } from 'react'

const LocationEditScreen = () => {

    const navigation = useNavigation()
    const dispatch = useDispatch()
    const lang = useLocalisationContext()
    const autoCompleteRef = useRef()
    const addressString = useSelector(selectAddressString)

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    useEffect(() => {
        autoCompleteRef.current.setAddressText(addressString ?? '')
    }, [])

    return (
    <SafeAreaView style={styles.container}>
        <View style={styles.header}>
            <Text style={styles.lable}>{lang.t('search_by_postcode')}</Text>
            <TouchableOpacity onPress={() => {navigation.goBack()}} style={{marginLeft: 'auto'}}>
                <Text style={styles.cancleText}>{lang.t('cancel')}</Text>
            </TouchableOpacity>
        </View>
        <View style={styles.searchContainer}>
        <GooglePlacesAutocomplete
            ref={autoCompleteRef}
            placeholder={lang.t('enter_your_location')}
            onPress={(data, details = null) => {
                const {geometry: {location }} = details
                const {formatted_address} = details
                dispatch(setLocation(location))
                dispatch(setAddressString(formatted_address))
                navigation.replace('HomeTabs')
            }}
            onFail={error => console.log(error)}
            nearbyPlacesAPI={'GoogleReverseGeocoding'}
            currentLocation={true}
            currentLocationLabel={lang.t('use_my_current_location')}
            enablePoweredByContainer={false}
            minLength={2}
            fetchDetails={true}
            debounce={400}
            styles={{
                container: {
                    flex: 0,
                },
                textInput: {
                    ...styles.input,
                }
            }}
            query={{
                key: GOOGLE_MAPS_API_KEY,
                language: lang?.locale || 'en',
                components: 'country:uk',
            }}
            />
        </View>
    </SafeAreaView>
    )
}

export default LocationEditScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        paddingTop: 25,
        paddingHorizontal: 15,
        paddingBottom: 13,
        flexDirection: 'row',
    },
    cancleText: {
        fontSize: 18,
        textAlign: 'right',
        color: ThemeColours.steelBlue,
    },
    lable: {
        fontSize: 18,
        color: ThemeColours.midnightBlue,
    },
    searchContainer: {
        paddingHorizontal: 15,
        flex: 1,
    },
    input: {
        height: 50,
        borderWidth: 1,
        padding: 10,
        flex: 1,
        fontSize: 18,
        borderColor: 'transparent',
        color: ThemeColours.midnightBlue,
    }
})