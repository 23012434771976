import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native'
import { Theme, ThemeColours } from '../Styles/Theme'
import FiveStarRating from './FiveStarRating'
import LocationDistance from './LocationDistance'

const FeaturedMerchantCard = ({ id, imgUrl, logoUrl, name, distance, rating, reviewCount, has_parking, has_toilets ,has_refreshments }) => {

    const navigation = useNavigation();

  return (
    <TouchableOpacity style={styles.card}
        onPress={() => navigation.navigate('MerchantScreen', {
            id,
            imgUrl,
            logoUrl,
            name,
            distance,
            rating,
            reviewCount,
            has_parking,
            has_toilets,
            has_refreshments
        })}
        >
        {imgUrl &&
        <Image source={imgUrl} style={styles.featuredImage} />
        }
        <View style={styles.info}>
            {logoUrl &&
            <Image source={logoUrl} style={styles.logo} />
            }
            <Text style={styles.merchantName}>{ name }</Text>
            <LocationDistance distance={distance} />
            <View style={[styles.row, {width: '100%', marginTop: 10}]}>
                <View stlye={styles.col}>
                    <View style={[styles.row, {width: 140}]}>
                        {has_parking &&
                        <Image source={require('../assets/images/utilitiesParkingIcon-2x.png')} style={{marginRight: 12, width: 15, height: 15}} />
                        }
                        {has_toilets &&
                        <Image source={require('../assets/images/utilitiesToiletIcon-2x.png')} style={{marginRight: 12, width: 12, height: 15}} />
                        }
                        {has_refreshments &&
                        <Image source={require('../assets/images/utilitiesFoodIcon-2x.png')} style={{marginRight: 12, width: 12, height: 15}} />
                        }
                    </View>
                </View>
                <View style={styles.col}>
                    <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <FiveStarRating rating={rating} reviewCount={reviewCount} />
                    </View>
                </View>
            </View>
        </View>
    </TouchableOpacity>
  )
}

export default FeaturedMerchantCard

const styles = StyleSheet.create({
    card: {
        borderRadius: 3,
        marginBottom: 17,
        flex: 1
    },
    featuredImage: {
        height: 146,
        width: '100%', // was 330
        borderRadius: 3,
        backgroundColor: '#d6e0f5',
    },
    col: {
        flex: 1,
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    merchantName: { 
        fontSize: 22,
        lineHeight: 27,
        color: ThemeColours.midnightBlue,
        marginBottom: 5,
        marginTop: 5,
        width: '100%',
        fontFamily: 'ITCAvantGardeProMd',
    },
    info: {
        flex: 1,
        padding: 15,
        marginTop: -60,
        width: '100%', // was 310
        flexDirection: "row",
        flexWrap: "wrap",
        backgroundColor: '#ffffff',
        borderRadius: 3,
        marginLeft: 10,
        marginRight: 10,
        shadowColor: '#99a7bc',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    logo: {
        marginTop: -40,
        height: 52,
        width: 52,
        backgroundColor: '#2d2e89',
        shadowColor: '#99a7bc',
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowRadius: 8,
        shadowOpacity: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#ffffff',
        borderRadius: 3
    },
    starIcon: {
        marginRight: 5,
        width: 15,
        height: 15,
    }
})