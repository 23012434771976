import { Text, StyleSheet, View, TouchableOpacity, ImageBackground, Image, ScrollView, TextInput, Alert, ActivityIndicator, KeyboardAvoidingView, Platform, TouchableWithoutFeedback, Keyboard, Modal, Pressable } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useAuthContext } from '../auth'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useState, useEffect, useLayoutEffect } from 'react'
import BackendService from '../Services/BackendService'
import * as ImagePicker from 'expo-image-picker'
//import { Camera } from 'expo-camera'
import * as MediaLibary from 'expo-media-library'
import Dropdown from '../Components/Dropdown'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { Picker } from '@react-native-picker/picker'
import dismissKeyboard from '../utils/dismissKeyboard'


const ProfileEditScreen = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const lang = useLocalisationContext()
    const { user, setAuthenticated } = useAuthContext()
    const [name, setName] = useState();
    const [businessName, setBusinessName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [selectedTrade, setSelectedTrade] = useState(undefined);
    const [tradesData, setTradesData] = useState([]);
    const [tradeModalVisible, setTradeModalVisible] = useState(false)
    const [isLoading, setLoading] = useState(false);
    //
    const [avatar, setAvatar] = useState();
    const [coverPhoto, setCoverPhoto] = useState();

    // Web
    const [webUploadedAvatar, setWebUploadedAvatar] = useState(null);
    const [webUploadedCoverPhoto, setWebUploadedCoverPhoto] = useState(null);

    useEffect(() => {
        setName(user.name);
        setBusinessName(user.company_name);
        setEmail(user.email);
        setPhone(user.phone);
        setSelectedTrade(user.trade?.id);

        const loadTrades = async () => {
            const response = await BackendService.getTrades().catch((error) => console.error(error));
            setTradesData(response.data);
        }
        loadTrades();
    }, [user])

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        });
    }, [navigation])

    const maybeGoBack = () => {
        let changed = false;
        // Check if any values changed
        if (user.name !== name) {
            changed = true;
        }
        if (user.email !== email) {
            changed = true;
        }
        if (user.company_name !== businessName) {
            changed = true;
        }
        if (user.phone !== phone) {
            changed = true;
        }
        if (user.trade_id != selectedTrade) {
            changed = true;
        }
        if (typeof avatar !== "undefined") {
            changed = true;
        }
        if (typeof coverPhoto !== "undefined") {
            changed = true;
        }

        if (changed) {
            Alert.alert(lang.t('save_changes'),
                lang.t('do_you_want_to_save_your_changes'),
                [
                    {
                        text: lang.t('save'),
                        onPress: () => saveChanges(),
                        style: "default",
                    },
                    {
                        text: lang.t('discard'),
                        onPress: () => navigation.goBack(),
                        style: "destructive",
                    },
                ]);
            return;
        }
        navigation.goBack();
    }

    const choosePhotoAsync = async (setter, aspect, photoType) => {
        if (Platform.OS !== 'web') {

            // Display the media libary to the user and wait for them to take a photo or to cancel
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All,
                allowsEditing: true,
                aspect: aspect ? aspect : [16, 9],
                quality: 0.2,
            });

            console.log(result);

            if (result.canceled) {
                setter(undefined);
                return;
            }

            // ImagePicker saves the taken photo to disk and returns a local URI to it
            let localUri = result.assets[0].uri
            let filename = localUri.split('/').pop();

            // Infer the type of the image
            let match = /\.(\w+)$/.exec(filename);
            let type = match ? `image/${match[1]}` : `image`;

            setter({
                uri: localUri,
                name: filename,
                type
            });
        } else {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = () => {
                if (photoType === 'avatar') {
                    setWebUploadedAvatar(input.files[0]);
                } else if (photoType === 'cover_photo') {
                    setWebUploadedCoverPhoto(input.files[0]);
                }
            };
            input.click();
        }
    }

    const saveChanges = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            if (businessName) {
                formData.append('company_name', businessName);
            }
            if (phone) {
                formData.append('phone', phone);
            }
            if (selectedTrade) {
                formData.append('trade_id', Number(selectedTrade));
            }
            //TODO: add code to handle images.


            if (Platform.OS === 'web') {
                if (webUploadedAvatar) {
                    formData.append('avatar', webUploadedAvatar, webUploadedAvatar.name)
                }
                if (webUploadedCoverPhoto) {
                    formData.append('cover_photo', webUploadedCoverPhoto, webUploadedCoverPhoto.name);
                }
            } else {
                if (avatar) {
                    formData.append('avatar', avatar);
                }
                if (coverPhoto) {
                    formData.append('cover_photo', coverPhoto);
                }
            }

            const response = await BackendService.saveProfile(formData);
            console.log(response.data);
            setAuthenticated(response.data);
            setLoading(false);
            navigation.goBack()
        } catch (error) {
            //Todo: handle errors.
            console.error(error);
            setLoading(false);
        }
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <ActivityIndicator size="large" color={ThemeColours.sunshineGold} />
            </View>
        );
    }

    return (
        <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : "height"}>
            <TouchableWithoutFeedback style={{ flex: 1 }} onPress={dismissKeyboard}>
                <ScrollView style={{ flex: 1, backgroundColor: ThemeColours.white, paddingBottom: insets.bottom }}>
                    <FocusAwareStatusBar barStyle="light-content" />
                    <ImageBackground
                        source={{ uri: (Platform.OS === 'web' && webUploadedCoverPhoto) ? URL.createObjectURL(webUploadedCoverPhoto) : coverPhoto?.uri ?? user.cover_photo }}
                        style={{ height: (148 + insets.top), width: '100%', paddingTop: insets.top }}
                    >
                        <View style={styles.header}>
                            <View style={styles.headerRow}>
                                <View style={{ position: 'absolute', left: 15 }}>
                                    <TouchableOpacity onPress={maybeGoBack} accessibilityLabel={'Go back to previous screen'}>
                                        <Image source={require('../assets/images/arrowLeftWhiteIcon-2x.png')} style={{ width: 17, height: 14 }} />
                                    </TouchableOpacity>
                                </View>
                                <TouchableOpacity onPress={() => choosePhotoAsync(setCoverPhoto, [16, 9], 'cover_photo')}>
                                    <Text style={styles.headerTextLight}>{lang.t('change_background_image')}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </ImageBackground>
                    <View style={{ backgroundColor: ThemeColours.white }}>
                        <View style={styles.profileContainer}>
                            <View style={styles.profileImageContainer}>
                                <Image
                                    source={{
                                        uri: (Platform.OS === 'web' && webUploadedAvatar) ? URL.createObjectURL(webUploadedAvatar) : avatar?.uri ?? user.avatar
                                    }}
                                    style={styles.profileImage}
                                />
                            </View>

                            <TouchableOpacity onPress={() => choosePhotoAsync(setAvatar, [1, 1], 'avatar')} style={{ marginBottom: 21 }}>
                                <Text>{lang.t('change_profile_image')}</Text>
                            </TouchableOpacity>

                            <View style={styles.form} behavior={Platform.OS === "ios" ? "padding" : "height"}>
                                <View style={styles.formGroup}>
                                    <Text style={styles.formLabel}>{lang.t('your_name')}</Text>
                                    <View style={styles.inputContainer}>
                                        <TextInput
                                            style={styles.formControl}
                                            onChangeText={setName}
                                            value={name}
                                            placeholder={lang.t('your_name')}
                                        />
                                    </View>
                                    {!name &&
                                        <Text style={styles.formValidationLabel}>{lang.t('plese_enter_your_name')}</Text>
                                    }
                                </View>
                                <View style={styles.formGroup}>
                                    <Text style={styles.formLabel}>{lang.t('your_trade')}</Text>
                                    <View style={styles.inputContainer}>
                                        <TouchableOpacity style={styles.formControl} onPress={() => setTradeModalVisible(true)}>
                                            <Text style={{ fontSize: 18, lineHeight: 48, color: ThemeColours.blueGrey }}>{tradesData.find((item) => item.id === selectedTrade) ? tradesData.find((item) => item.id === selectedTrade).name : 'Select Trade'}</Text>
                                        </TouchableOpacity>
                                        <Modal
                                            transparent={true}
                                            visible={tradeModalVisible}
                                            animation="slide"
                                            onRequestClose={() => {
                                                setTradeModalVisible(!tradeModalVisible)
                                            }}
                                            style={{ flex: 1 }}
                                        >
                                            <Pressable style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.2)' }} onPress={() => setTradeModalVisible(false)}></Pressable>
                                            <View style={{ flex: 1, backgroundColor: 'white', width: '100%' }}>
                                                <TouchableOpacity
                                                    onPress={() => setTradeModalVisible(false)}
                                                    style={{ height: 40, width: '100%', paddingHorizontal: 20, flexDirection: 'row', alignItems: 'flex-end' }}
                                                >
                                                    <Text style={{ color: ThemeColours.midnightBlue, lineHeight: 40, fontSize: 18, marginLeft: 'auto' }}>Done</Text>
                                                </TouchableOpacity>
                                                <Picker
                                                    selectedValue={selectedTrade}
                                                    onValueChange={(itemValue, itemIndex) => {
                                                        //console.log(itemValue)
                                                        setSelectedTrade(itemValue)
                                                    }}
                                                >
                                                    {tradesData.map((trade) => (
                                                        <Picker.Item label={trade.name} key={trade.id} value={trade.id} />
                                                    ))}
                                                </Picker>
                                            </View>
                                        </Modal>
                                    </View>
                                </View>
                                <View style={styles.formGroup}>
                                    <Text style={styles.formLabel}>{lang.t('business_name')}</Text>
                                    <View style={styles.inputContainer}>
                                        <TextInput
                                            style={styles.formControl}
                                            onChangeText={setBusinessName}
                                            value={businessName}
                                            placeholder={lang.t('business_name')}
                                        />
                                    </View>
                                </View>
                                <View style={styles.formGroup}>
                                    <Text style={styles.formLabel}>{lang.t('email')}</Text>
                                    <View style={styles.inputContainer}>
                                        <TextInput
                                            style={styles.formControl}
                                            onChangeText={setEmail}
                                            value={email}
                                            placeholder={lang.t('email')}
                                        />
                                    </View>
                                    {!email &&
                                        <Text style={styles.formValidationLabel}>{lang.t('plese_enter_your_email')}</Text>
                                    }
                                </View>
                                <View style={styles.formGroup}>
                                    <Text style={styles.formLabel}>{lang.t('phone')}</Text>
                                    <View style={styles.inputContainer}>
                                        <TextInput
                                            style={styles.formControl}
                                            onChangeText={setPhone}
                                            value={phone}
                                            placeholder={lang.t('phone')}
                                        />
                                    </View>
                                </View>

                                <View style={{ paddingVertical: 15, flexDirection: 'row', justifyContent: 'center', backgroundColor: ThemeColours.white }}>
                                    <TouchableOpacity style={styles.button} onPress={saveChanges}>
                                        <Text style={styles.buttonText}>{lang.t('save_changes_btn')}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
    )
}

export default ProfileEditScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        height: 73,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontSize: 18,
        lineHeight: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
    },
    headerTextLight: {
        fontSize: 14,
        lineHeight: 32,
        textAlign: 'center',
        color: ThemeColours.white,
    },
    buttonTextEdit: {
        fontSize: 18,
        lineHeight: 20,
        color: ThemeColours.white,
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    h1: {
        ...Theme.h1,
        color: ThemeColours.midnightBlue,
        marginBottom: 25,
        fontSize: 25,
        letterSpacing: 0,
        textAlign: 'center',
        lineHeight: 32,
        maxWidth: 275,
        fontWeight: 'bold',
    },
    subheading: {
        ...Theme.subheadingText,
        color: ThemeColours.blueGrey,
        marginBottom: 35,
        textAlign: 'center',
        width: '100%',
        marginHorizontal: 18,
    },
    button: {
        ...Theme.button,
        maxWidth: 335,
        width: '100%',
    },
    buttonText: {
        ...Theme.buttonText,
    },
    messageIcon: {
        width: 59,
        height: 44,
        marginBottom: 25,
    },
    loadingIcon: {
        marginBottom: 25,
    },
    profileContainer: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3
    },
    profileMeta: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.blueGrey,
        lineHeight: 20,
        marginBottom: 10,
    },
    profileTrade: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
        lineHeight: 20,
        paddingVertical: 5,
        paddingHorizontal: 15,
        marginBottom: 20,

        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccd3dd',
        borderRadius: 16
    },
    profileImageContainer: {
        position: 'relative',
        height: 100,
        width: 100,
        marginBottom: 25,
        marginTop: -46,
        borderRadius: 3,
    },
    profileImage: {
        height: 100,
        width: 100,
        borderRadius: 6,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: ThemeColours.white,
    },

    //Form
    form: {
        ...Theme.form,
    },
    formGroup: {
        ...Theme.formGroup,
    },
    formLabel: {
        ...Theme.formLabel,
    },
    inputContainer: {
        ...Theme.inputContainer,
    },
    formControl: {
        ...Theme.formControl,
    },
    formValidationLabel: {
        ...Theme.formValidationLabel,
    },
})