import { Text, StyleSheet, View, SafeAreaView, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native'
import { useLayoutEffect, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useAuthContext } from '../auth'
import { Theme, ThemeColours } from '../Styles/Theme'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { selectNotifications, fetchNotifications } from '../Store/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { lang } from '../Providers/LocalisationProvider'
import NotificationItem from '../Components/NotificationItem'

const NotificationsScreen = () => {

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const notifications = useSelector(selectNotifications)
  const notificationsStatus = useSelector(state => state.notifications.status)
  const errorMessage = useSelector(state => state.notifications.error)
  const { user } = useAuthContext()
  const isLoggedIn = !!user?.id //convert to boolean

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [])

  useEffect(() => {
    // TODO: listen for new messages.
    if (isLoggedIn && notificationsStatus === 'idle') {
      dispatch(fetchNotifications())
    }
  }, [notificationsStatus, isLoggedIn])

  const onRefresh = () => {
    dispatch(fetchNotifications())
  }

  if (!isLoggedIn) {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('notifications_title')}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.h1}>{lang.t('log_in_to_see_your_requests')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('deals_on_materials_right_at_your_fingertips')}</Text>
          <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')} style={[Theme.button, styles.button]}>
            <Text style={Theme.buttonText}>{lang.t('log_in')}</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    )
  }

  if (notificationsStatus === 'loading') {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('notifications_title')}</Text>
        </View>
        <View style={styles.content}>
          <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
          <Text style={styles.h1}>{lang.t('loading')}</Text>
        </View>
      </SafeAreaView>
    )
  }

  if (notificationsStatus === 'error') {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('notifications_title')}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.h1}>{lang.t('sorry_something_went_wrong')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('we_were_unable_to_load_your_requests')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{errorMessage}</Text>
        </View>
      </SafeAreaView>
    )
  }

  if (notificationsStatus === 'succeeded' && notifications.length === 0) {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('notifications_title')}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.h1}>{lang.t('no_notifications')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('notifications_will_be_stored_here')}</Text>
        </View>
      </SafeAreaView>
    )
  }

  if (notificationsStatus === 'succeeded') {
    const renderItem = ({ item }) => <NotificationItem notification={item} />;

    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('notifications_title')}</Text>
        </View>
        <FlatList
          style={{ backgroundColor: ThemeColours.white, paddingTop: 15 }}
          data={notifications}
          renderItem={renderItem}
          keyExtractor={item => item.id}
          onRefresh={onRefresh}
          refreshing={notificationsStatus === 'loading'}
        />
      </SafeAreaView>
    )
  }
}

export default NotificationsScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    height: 73,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    fontSize: 18,
    lineHeight: 32,
    textAlign: 'center',
    fontWeight: 'bold',
    color: ThemeColours.midnightBlue,
    fontFamily: 'ITCAvantGardeProBold',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  h1: {
    marginBottom: 25,
    fontSize: 25,
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: 32,
    maxWidth: 275,
    color: ThemeColours.midnightBlue,
    fontFamily: 'ITCAvantGardeProBold',
  },
  subheading: {
    marginBottom: 35,
    textAlign: 'center',
    maxWidth: 275,
  },
  button: {
    maxWidth: 335,
    width: '100%',
  },
  messageIcon: {
    width: 59,
    height: 44,
    marginBottom: 25,
  },
  loadingIcon: {
    marginBottom: 25,
  }
})
