import { StyleSheet, Text, ScrollView, View, Image, SafeAreaView, Pressable, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native';
import { Theme, ThemeColours } from '../Styles/Theme';
import Checkbox from 'expo-checkbox';
import SearchResult from '../Components/SearchResult';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResults, getResults, getSelected, toggleAllResults, isAllSelected, resetResultsStatus } from '../Store/resultsSlice';
import { selectLocation } from '../Store/locationSlice';
import { lang } from '../Providers/LocalisationProvider';
import Purchases from 'react-native-purchases';
import { ENTITLEMENT_ID } from '../config';
import { useAuthContext } from '../auth';
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar';
import CloseButton from '../Components/CloseButton';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'expo-modules-core'



const MaterialSearchResultsScreen = () => {
    
    const navigation = useNavigation()
    const dispatch = useDispatch();
    const results = useSelector(getResults);
    const resultsStatus = useSelector(state => state.results.status);
    const errorMessage = useSelector(state => state.results.error);
    const resultsCount = useSelector(state => state.results.count);
    const isChecked = useSelector(isAllSelected);
    const selected = useSelector(getSelected);
    const location = useSelector(selectLocation);
    const { user } = useAuthContext();
    const isLoggedIn = !!user?.id //convert to boolean
    const [mustLogin, setMustLogin] = useState(false)
    const insets = useSafeAreaInsets()
    // const selectedCount = 0;


    const {params: {
        searchString
    }} = useRoute();

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        });
    })

    useEffect(() => {
        const {lat, lng} = location;
        dispatch(fetchResults({q: searchString, 'lat': lat, 'lng': lng}))
    }, [searchString, location])


    const navigateToRequest = async () => {
        
        // Check if the user is logged in, before directing anywhere else.
        if (!isLoggedIn) {
            setMustLogin(true)
            return;
        }

        // Check if the current user is subscribed and if not, display the paywall.
        try {
            // access latest customerInfo
            navigation.navigate('CreateRequestScreen', {selected, searchString})
            // const customerInfo = await Purchases.getCustomerInfo()
            // if (typeof customerInfo.entitlements.active[ENTITLEMENT_ID] !== 'undefined') {
            //     navigation.navigate('CreateRequestScreen', {selected, searchString})
            // } else {
            //     navigation.replace('PaywallScreen')
            // }
        } catch (e) {
            Alert.alert('Error fetching customer info', e.message)
        }
    }


    if (errorMessage) {
        return (
            <SafeAreaView style={styles.container}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <View style={styles.header}>
                    <Text style={styles.h1}>{lang.t('showing_x_results_for', {resultsCount})}</Text>
                </View>
                <View style={styles.contentSpace}>
                    <Text style={styles.h1}>{lang.t('sorry_something_went_wrong')}</Text>
                    <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('we_were_unable_to_load_search_results')}</Text>
                    <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{errorMessage}</Text>
                </View>
            </SafeAreaView>
        )
    }

    if (resultsStatus === 'loading') {
        return (
            <SafeAreaView style={styles.container}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <View style={styles.header}>
                    <Text style={styles.h1}>{lang.t('loading_results_for')}</Text>
                    <TouchableOpacity 
                        onPress={() => navigation.goBack()}
                        style={styles.searchTermContainer}
                        >
                        <View style={{padding: 15}}>
                            <Image source={require('../assets/images/backArrowIcon-2x.png')} style={{width: 9, height: 17}}/>
                        </View>
                        <Text style={styles.searchTerm}>{searchString}</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.contentSpace}>
                    <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
                    <Text style={styles.h1}>{lang.t('loading')}</Text>
                </View>
            </SafeAreaView>
        )    
    }

    if (resultsStatus === 'succeeded' && resultsCount === 0) {
        return (
            <SafeAreaView style={styles.container}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <View style={styles.header}>
                    <Text style={styles.h1}>{lang.t('no_results_for')}</Text>
                    <TouchableOpacity 
                        onPress={() => navigation.goBack()}
                        style={styles.searchTermContainer}
                        >
                        <View style={{padding: 15}}>
                            <Image source={require('../assets/images/backArrowIcon-2x.png')} style={{width: 9, height: 17}}/>
                        </View>
                        <Text style={styles.searchTerm}>{searchString}</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.contentSpace}>
                    <Text style={styles.h1}>{lang.t('no_results')}</Text>
                </View>
            </SafeAreaView>
        )    
    }

    if (mustLogin) {
        return (
            <SafeAreaView style={styles.container}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <View style={styles.header}>
                    <Text style={styles.headerText} numberOfLines={1}>{lang.t('material_search')}</Text>
                    <CloseButton onPress={() => {setMustLogin(false)}}></CloseButton>
                </View>
                <View style={{flex: 1, paddingLeft: 15, paddingRight: 15, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={styles.h1}>{lang.t('log_in_to_make_your_requests')}</Text>
                    <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('deals_on_materials_right_at_your_fingertips')}</Text>
                    <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')} style={[Theme.button, styles.button]}>
                        <Text style={Theme.buttonText}>{lang.t('log_in')}</Text>
                    </TouchableOpacity>
                </View>
            </SafeAreaView>
        )
    }

    if (resultsStatus === 'succeeded') {
        return (
            <View style={styles.container}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <ScrollView>
                    <View style={[styles.header, {paddingTop: 30 + insets.top}]}>
                        <Text style={styles.h1}>{lang.t('showing_x_results_for', {resultsCount})}</Text>
                        <TouchableOpacity 
                            onPress={() => navigation.goBack()}
                            style={styles.searchTermContainer}
                            >
                            <View style={{padding: 15}}>
                                <Image source={require('../assets/images/backArrowIcon-2x.png')} style={{width: 9, height: 17}}/>
                            </View>
                            <Text style={styles.searchTerm}>{searchString}</Text>
                        </TouchableOpacity>
                    </View>

                <View>
                    <View style={styles.listTop}>
                        <Text style={styles.bodyCopy}>{lang.t('here_are_the_closest_merchants')}</Text>
                        <View style={styles.checkboxContainer}>
                            <Checkbox
                            style={styles.checkbox}
                            value={isChecked}
                            onValueChange={(value) => {
                                //setChecked(value)
                                dispatch(toggleAllResults())
                            }}
                            color={isChecked ? ThemeColours.midnightBlue : undefined}
                            />
                            <Pressable onPress={() => dispatch(toggleAllResults())}>
                                <Text style={styles.checkboxLabel}>{lang.t('select_all')}</Text>
                            </Pressable>
                        </View>
                    </View>
                    <FlatList
                        data={results}
                        numColumns={Platform.OS === 'web' ? 2 : 0}
                        contentContainerStyle={styles.listContainer}
                        horizontal={false}
                        renderItem={({item}) => {
                        const merchant = item;
                        return <View style={Platform.select({
                            android: {},
                            ios: {},
                            web: {margin: '1%', width: '48%'}
                        })}>
                            <SearchResult 
                            key={merchant.id}
                            id={merchant.id}
                            imgUrl={{uri: merchant.cover_photo_url}}
                            logoUrl={{uri: merchant.logo_url}}
                            name={merchant.name}
                            distance={merchant.distance}
                            has_parking={merchant.has_parking}
                            has_toilets={merchant.has_toilets}
                            has_refreshments={merchant.has_refreshments}
                            rating={merchant.rating}
                            reviewCount={merchant.review_count}
                            ></SearchResult>
                        </View>
                    }}>
                    </FlatList>
                </View>
                </ScrollView>
                {selected.length > 0 &&
                <View style={[styles.buttonContainer, {paddingBottom: 15 + insets.bottom}]}>
                    <TouchableOpacity 
                        style={styles.sendButton} 
                        onPress={navigateToRequest}
                        >
                        <Text style={styles.sendButtonText}>({selected.length}) {lang.t('send_to_selected')}</Text>
                    </TouchableOpacity>
                </View>
                }
            </View>
        )
    }
}

export default MaterialSearchResultsScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        padding: 15,
    },
    contentSpace: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
    },
    header: {
        paddingTop: 30,
        paddingBottom: 25,
        paddingHorizontal: 20,
    },
    listContainer: {
        flex: 1,
        backgroundColor: ThemeColours.white,
        paddingTop: 15,
        paddingHorizontal: 10
    },
    listTop: {
        paddingTop: 18,
        paddingHorizontal: 15,
        paddingBottom: 25,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: ThemeColours.white,
        shadowColor: ThemeColours.steelBlueShadow,
        shadowOffset: {
            width: 0,
            height: -10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
    },
    h1: {
        ...Theme.h1,
        color: ThemeColours.midnightBlue,
        marginBottom: 15,
        fontFamily: 'ITCAvantGardeProBold',
    },
    bodyCopy: {
        color: ThemeColours.midnightBlue,
        marginBottom: 19,
        fontSize: 18,
        lineHeight: 22,
        maxWidth: 305,
    },
    searchTermContainer: {
        height: 50,
        flexDirection: 'row',
        backgroundColor: ThemeColours.white,
        shadowColor: ThemeColours.steelBlueShadow,
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        borderRadius: 3,
    },
    searchTerm: {
        lineHeight: 50,
        fontSize: 18,
        color: ThemeColours.midnightBlue,
    },
    checkboxContainer: {
        flexDirection: 'row',
    },
    checkbox: {
        marginRight: 10,
        backgroundColor: ThemeColours.white,
        borderColor: ThemeColours.midnightBlue,
        borderWidth: 1,
        borderRadius: 5,
    },
    checkboxLabel: {
        color: ThemeColours.blueGrey,
        fontSize: 14,
    },
    buttonContainer: {
        padding: 15,
        backgroundColor: ThemeColours.white,
    },
    sendButton: {
        ...Theme.button,
        paddingVertical: 5,
    },
    sendButtonText: {
        ...Theme.buttonText,
        fontSize: 22,
        lineHeight: 40,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
    },
    headerText: {
        fontSize: 18,
        // lineHeight: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    subheading: {
        marginBottom: 35,
        textAlign: 'center',
        maxWidth: 275,
    },
    button: {
        maxWidth: 335,
        width: '100%',
    },
    loadingIcon: {
        marginBottom: 25,
    },
})