import Constants from 'expo-constants';
import { GOOGLE_MAPS_API_KEY, PUSHER_API_KEY, PUSHER_CLUSTER, REMOTE_DEBUG_URL, PURCHASES_API_KEY_IOS, PURCHASES_API_KEY_ANDROID } from '@env';


// const devBaseUrl = REMOTE_DEBUG_URL ? REMOTE_DEBUG_URL : 'http://material-network-backend.test';
// const devBaseUrl = REMOTE_DEBUG_URL ? REMOTE_DEBUG_URL : 'https://staging.getmaterialnetwork.com';
// const devBaseUrl = REMOTE_DEBUG_URL ? REMOTE_DEBUG_URL : 'https://api.materialnetwork.com';
const devBaseUrl = 'https://api.materialnetwork.com';

// __DEV__ is true when run locally, but false when published.
// const baseUrl = __DEV__ ? `${devBaseUrl}/api/v1` : Constants.expoConfig.extra.apiUrl;
// const loginUrl = __DEV__ ? `${devBaseUrl}/api/login` : Constants.expoConfig.extra.loginUrl;
// const registerUrl = __DEV__ ? `${devBaseUrl}/api/register` : Constants.expoConfig.extra.registerUrl;
// const logOutUrl = __DEV__ ? `${devBaseUrl}/api/v1/logout` : Constants.expoConfig.extra.logoutUrl;
// const broadcastingAuthUrl = __DEV__ ? `${devBaseUrl}/api/broadcasting/auth` : Constants.expoConfig.extra.broadcastingAuthUrl;

// const googleMapsApiKey = __DEV__ ? GOOGLE_MAPS_API_KEY : Constants.expoConfig.extra.googleMapsApiKey;
// const pusherApiKey = __DEV__ ? PUSHER_API_KEY : Constants.expoConfig.extra.pusherApiKey;
// const pusherCluster = __DEV__ ? PUSHER_CLUSTER : Constants.expoConfig.extra.pusherCluster;
// const projectId = Constants.expoConfig.extra.eas.projectId;

// const purchasesApiKeyIos = __DEV__ ? PURCHASES_API_KEY_IOS : Constants.expoConfig.extra.purchasesApiKeyIos;
// const purchasesApiKeyAndroid = __DEV__ ? PURCHASES_API_KEY_ANDROID : Constants.expoConfig.extra.purchasesApiKeyAndroid;

//Web:
const baseUrl = `${devBaseUrl}/api/v1`;
const loginUrl = `${devBaseUrl}/api/login`;
const registerUrl = `${devBaseUrl}/api/register`;
const logOutUrl = `${devBaseUrl}/api/v1/logout`;
const broadcastingAuthUrl = `${devBaseUrl}/api/broadcasting/auth`;
const googleMapsApiKey = 'AIzaSyC5TACGPa5gH4alRzbjhcd_9Bhsq56dLBI';
const pusherApiKey = '09932348b8dac46c79c5';
const pusherCluster = 'eu';
const projectId = "85b410d2-aa13-418f-ac50-e1f077bea0fe";

const purchasesApiKeyIos = "appl_BonuXtIedgOJeDQwtFRrTlWKRmX";
const purchasesApiKeyAndroid = "goog_JBQJYvNNYixbCGGKyFTVHPyTVnb";
// Web-end
// Entitlement is for subscriptions.
const ENTITLEMENT_ID = 'pro';

export {
    baseUrl as BASE_URL,
    loginUrl as LOGIN_URL,
    registerUrl as REGISTER_URL,
    logOutUrl as LOGOUT_URL,
    broadcastingAuthUrl as BROADCASTING_AUTH_URL,
    googleMapsApiKey as GOOGLE_MAPS_API_KEY,
    pusherApiKey as PUSHER_API_KEY,
    pusherCluster as PUSHER_CLUSTER,
    purchasesApiKeyIos as PURCHASES_API_KEY_IOS,
    purchasesApiKeyAndroid as PURCHASES_API_KEY_ANDROID,
    ENTITLEMENT_ID,
    projectId as PROJECT_ID,
};