import { ActivityIndicator, Alert, Dimensions, Image, ImageBackground, Keyboard, KeyboardAvoidingView, Platform, Pressable, ScrollView, StatusBar, StyleSheet, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import { useState, useRef } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useLayoutEffect } from 'react'
import { REGISTER_URL } from '../config'
import * as Device from 'expo-device'
import axios from 'axios'
import analytics from '@react-native-firebase/analytics'
import { saveToken, getUser, useAuthContext } from '../auth'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import dismissKeyboard from '../utils/dismissKeyboard'
import resolveDeviceName from "../utils/resolveDeviceName";


const RegisterScreen = () => {

    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const { setAuthenticated } = useAuthContext()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [isLoading, setLoading] = useState(false)
    const refEmail = useRef()
    const refPassword = useRef()
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const [errors, setErrors] = useState({});

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    const doSignup = async () => {

        if (canSend() === false) {
            return;
        }

        setLoading(true);
        const formData = {
            name,
            email,
            password,
            device_name: resolveDeviceName(),
        };

        try {
            const loginResponse = await axios.post(REGISTER_URL, formData);
            const token = loginResponse.data.token;
            const tokenResponse = await saveToken(token);

            const authUser = await getUser();
            setAuthenticated(authUser);

            if(Platform.OS === 'ios'){
                await analytics().logEvent('signup', {
                    id: authUser?.id,
                })
            }
            
            setLoading(false);
            navigation.replace('AccountSetupScreen');

        } catch(error) {
            let validation = {};
            if (error?.response?.data?.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    validation[key] = error.response.data.errors[key][0];
                });
                setErrors(validation);
            } else {
                console.error(error);
            }
            setLoading(false);
        }
    }

    const canSend = () => {
        let validation = {}
        if (!name) {
            validation.name = lang.t('plese_enter_your_name');
        }
        if (!email || reg.test(email) === false) {
            validation.email = lang.t('plese_enter_your_email');
        }
        if (!password || /\d/.test(password) === false) {
            validation.password = lang.t('password_must_contain_a_number');
        }
        if (!password || password.length < 8) {
            validation.password = lang.t('password_must_be_at_least_8_characters');
        }
        setErrors(validation);
        
        return Object.keys(validation).length === 0;
    }

    if (isLoading) {
        return (
            <View style={styles.container}>
                <ActivityIndicator size="large" />
            </View>
        )
    }

    return (
        <KeyboardAvoidingView 
        style={styles.container}
        behavior={Platform.OS === "ios" ? "padding" : "height"}>
            <FocusAwareStatusBar barStyle="light-content" />
            <TouchableWithoutFeedback style={{flex: 1}} onPress={dismissKeyboard}>
                <ScrollView>
                    <ImageBackground
                    behavior={'cover'}
                    style={styles.bgImage}
                    source={require('../assets/images/postcodeBgImage-2x.png')}
                    >
                      <Image 
                      style={styles.logo}
                      source={require('../assets/images/materialNetworkLogoCentered-2x.png')}/>
                    </ImageBackground>

                    <View style={{paddingLeft: 15,paddingRight: 15}}>
                      <Text style={styles.h1}>{lang.t('create_your_account')}</Text>
                      <View style={{marginBottom: 11}}>
                          <Text style={styles.label}>{lang.t('your_name')}</Text>
                          <TextInput 
                          style={styles.input}
                          value={name}
                          onChangeText={(name) => setName(name)}
                          placeholder={lang.t('enter_your_name')}
                          returnKeyType='next'
                          onSubmitEditing={() => {refEmail.current.focus()}}
                          />
                          {errors.name &&
                              <Text style={styles.formValidationLabel}>{errors.name}</Text>
                          }
                      </View>
                      <View style={{marginBottom: 11}}>
                          <Text style={styles.label}>{lang.t('email')}</Text>
                          <TextInput 
                          style={styles.input}
                          value={email}
                          onChangeText={(email) => setEmail(email)}
                          placeholder={lang.t('enter_your_email')}
                          keyboardType='email-address'
                          returnKeyType='next'
                          onSubmitEditing={() => {refPassword.current.focus()}}
                          ref={refEmail}
                          />
                          {errors.email &&
                              <Text style={styles.formValidationLabel}>{errors.email}</Text>
                          }
                      </View>
                      <View>
                          <Text style={styles.label}>{lang.t('password')}</Text>
                          <TextInput
                              style={styles.input}
                              value={password}
                              onChangeText={(password) => setPassword(password)}
                              placeholder={lang.t('enter_your_password')}
                              returnKeyType='done'
                              secureTextEntry={true}
                              textContentType='password'
                              ref={refPassword}
                          />
                          {errors.password &&
                              <Text style={styles.formValidationLabel}>{errors.password}</Text>
                          }
                      </View>
                      <View style={styles.termsContainer}>
                          <Text style={styles.termsText}>{lang.t('by_clicking_button_you_agree_to')}</Text>
                          <Text style={styles.linkText} onPress={() => navigation.navigate('TermsAndConditionsScreen')}>{lang.t('terms_and_conditions')}</Text>
                      </View>
                      <View style={styles.buttonContainer}>
                          <TouchableOpacity
                              style={styles.button}
                              onPress={() => doSignup()}
                              >
                              <Text style={styles.buttonText}>{lang.t('create_my_account')}</Text>
                          </TouchableOpacity>
                      </View>
                      <View style={{flex: 1, width: '100%', flexDirection: 'row'}}>
                          <Text style={{marginRight: 5}}>{lang.t('already_have_an_account')}</Text>
                          <Pressable
                              onPress={() => navigation.navigate('LoginScreen')}>
                              <Text style={styles.secondaryActionText}>{lang.t('login')}</Text>
                          </Pressable>
                      </View>
                    </View>
                    <View style={{height: 100}}></View>
                </ScrollView>
            </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
    )
}

export default RegisterScreen

const {width} = Dimensions.get('window');

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ThemeColours.white,
    },
    bgImage: {
        height: 239,
        width: width,
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: 127,
        height: 85,
        alignSelf: 'center',
    },
    h1: {
      ...Theme.h1,
      marginTop: 15,
      marginBottom: 13,
      fontSize: 25,
      color: ThemeColours.midnightBlue,
      fontFamily: 'ITCAvantGardeProBold',
    },
    label: {
        ...Theme.inputLabelText,
        paddingLeft: 10,
        paddingBottom: 5,
    },
    input: {
        paddingHorizontal: 10,
        fontSize: 18,
        height: 50,
        width: '100%',
        backgroundColor: ThemeColours.white,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#002357',
        borderRadius: 3
    },
    formValidationLabel: {
        marginTop: 4,
        marginLeft: 12,
        fontSize: 12,
        color: ThemeColours.denialRed,
    },
    buttonContainer: {
        marginTop: 35,
        marginBottom: 33,
    },
    button: {
        backgroundColor: 'rgb(251,176,59)',
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        borderRadius: 3,
        padding: 13,
        height: 50,
        width: '100%',
    },
    buttonText: {
        color: '#002357',
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
    },
    buttonClose: {
        height: 40,
        width: 40,
        position: 'absolute',
        right: 15,
        top: 15,
    },
    secondaryActionText: {
        color: '#667b9a',
    },
    termsContainer: {
        paddingTop: 25,
        paddingBottom: 15,
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    termsText: {
        // color: '#667b9a',
    },
    linkText: {
        color: '#667b9a',
    }
})