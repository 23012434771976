import { Text, StyleSheet, View, TouchableOpacity, SafeAreaView, Image, ActivityIndicator, FlatList } from 'react-native'
import React, { useLayoutEffect, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import {Theme, ThemeColours} from '../Styles/Theme'
import ConversationItem from '../Components/ConversationItem'
import { selectRequests, fetchRequests } from '../Store/requestsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthContext } from '../auth'
import { lang } from '../Providers/LocalisationProvider'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { useIsFocused } from '@react-navigation/native'


const RequestsScreen = () => {

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const requests = useSelector(selectRequests)
  const requestsStatus = useSelector(state => state.requests.status)
  const errorMessage = useSelector(state => state.requests.error)
  const { user } = useAuthContext()
  const isLoggedIn = !!user?.id //convert to boolean
  const isFocused = useIsFocused()

  useLayoutEffect(() => {
    navigation.setOptions({
        headerShown: false,
    });
  }, [])

  useEffect(() => {
    if(isFocused){
      dispatch(fetchRequests())
    }
  }, [isFocused])

  useEffect(() => {
    // TODO: listen for new messages.
    if (isLoggedIn && requestsStatus === 'idle') {
      dispatch(fetchRequests())
    }
  }, [requestsStatus, isLoggedIn])

  const onRefresh = () => {
    dispatch(fetchRequests())
  }

  if (!isLoggedIn) {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('request_messages')}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.h1}>{lang.t('log_in_to_see_your_requests')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('deals_on_materials_right_at_your_fingertips')}</Text>
          <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')} style={[Theme.button, styles.button]}>
            <Text style={Theme.buttonText}>{lang.t('log_in')}</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'loading') {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('request_messages')}</Text>
        </View>
        <View style={styles.content}>
          <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
          <Text style={styles.h1}>{lang.t('loading')}</Text>
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'error') {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('request_messages')}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.h1}>{lang.t('sorry_something_went_wrong')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('we_were_unable_to_load_your_requests')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{errorMessage}</Text>
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'succeeded' && requests.length === 0) {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <View style={styles.header}>
          <Text style={styles.headerText}>{lang.t('request_messages')}</Text>
        </View>
        <View style={styles.content}>
          <Image source={require('../assets/images/messagesIconLarge-2x.png')} 
            style={styles.messageIcon}/>
          <Text style={styles.h1}>{lang.t('no_messages')}</Text>
          {
            !user?.is_merchant ? <>
              <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('any_requests_you_make_for_materials_will_be_stored_here')}</Text>
              <TouchableOpacity onPress={() => navigation.navigate('HomeTabs', { screen: 'Home' })} style={[Theme.button, styles.button]}>
                <Text style={Theme.buttonText}>{lang.t('start_a_request')}</Text>
              </TouchableOpacity>
            </> : 
            <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('merchant_any_requests_you_make_for_materials_will_be_stored_here')}</Text>
          }
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'succeeded') {
    const renderItem = ({item}) => (
      <ConversationItem 
        key={item.id}
        id={item.id}
        name={user.is_merchant ? item?.owner?.name : item?.merchant?.name}
        unreadCount={item.unread_count}
        dateTime={item?.last_message[0]?.created_at}
        lastMessage={item?.last_message[0]?.message}
        logoUrl={{ uri: user.is_merchant ? item?.owner?.avatar_url : item?.merchant?.logo_url }}
        imgUrl={{ uri: user.is_merchant ? null : item?.merchant?.cover_photo }}
        merchantId={item?.merchant?.id}
        addressString={item?.merchant_address}
        merchantLat={ item?.merchant?.lat }
        merchantLng={ item?.merchant?.lng }
        />
    );

    return (
      <SafeAreaView style={styles.container}>
          <FocusAwareStatusBar barStyle="dark-content" />
          <View style={styles.header}>
            <Text style={styles.headerText}>{lang.t('request_messages')}</Text>
          </View>
          <FlatList 
            style={{backgroundColor: ThemeColours.white, paddingTop: 15}}
            data={requests}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            onRefresh={onRefresh}
            refreshing={requestsStatus === 'loading'}
            />
        </SafeAreaView>
    )
  }
}

export default RequestsScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    height: 73,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    fontSize: 18,
    lineHeight: 32,
    textAlign: 'center',
    fontWeight: 'bold',
    color: ThemeColours.midnightBlue,
    fontFamily: 'ITCAvantGardeProBold',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  h1: {
    marginBottom: 25,
    fontSize: 25,
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: 32,
    maxWidth: 275,
    color: ThemeColours.midnightBlue,
    fontFamily: 'ITCAvantGardeProBold',
  },
  subheading: {
    marginBottom: 35,
    textAlign: 'center',
    maxWidth: 275,
  },
  button: {
    maxWidth: 335,
    width: '100%',
  },
  messageIcon: {
    width: 59,
    height: 44,
    marginBottom: 25,
  },
  loadingIcon: {
    marginBottom: 25,
  }
})
