import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import BackendService from '../Services/BackendService'

export const fetchResults = createAsyncThunk('requests/fetchResults', async (queryParams) => {
    const response = await BackendService.getResults(queryParams)
    return response.data
})

const initialState = {
    results: [],
    selected: [],
    status: 'idle',
    error: null,
    count: 0,
}

export const resultsSlice = createSlice({
    name: 'results',
    initialState,
    reducers: {
        toggleResult: (state, action) => {
            const index = state.selected.findIndex((id) => id === action.payload)
            if (index !== -1) {
                state.selected = state.selected.filter((id) => id !== action.payload)
                return
            }
            state.selected.push(action.payload)
        },
        toggleAllResults: (state, action) => {
            state.selected = (state.selected.length === 0) ? state.results.map(result => result.id) : []
        },
        clearSelectedResults: (state, action) => {
            state.selected = []
        },
        resetResultsStatus: (state, action) => {
            state.status = initialState.status
        },
        setSingleMerchantAsSelected: (state, action) => {
            state.selected = [action.payload]
        },
    },
    extraReducers(builder) {
        builder
          .addCase(fetchResults.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchResults.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Set search results
            state.results = action.payload
            state.count = state.results.length
          })
          .addCase(fetchResults.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
      }
})

// Action creators are generated for each case reducer function
export const { toggleResult, toggleAllResults, setSingleMerchantAsSelected, resetResultsStatus, clearSelectedResults } = resultsSlice.actions

export const getResults = (state) => state.results.results
export const getSelected = (state) => state.results.selected

export const isSelected = (state, resultId) =>
    !! state.results.selected.find((item) => item === resultId)

export const isAllSelected = (state) =>
    state.results.results.length > 0 && state.results.results.length === state.results.selected.length

// export const selectRequestById = (state, postId) =>
//   state.requests.requests.find((request) => request.id === postId)

export default resultsSlice.reducer