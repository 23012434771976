import { ActivityIndicator, Alert, SafeAreaView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import React, { useRef, useState } from 'react'
import BackendService from '../Services/BackendService'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import { useLocalisationContext } from '../Providers/LocalisationProvider'


const ResetPasswordCard = () => {

    const [isLoading, setLoading] = useState(false)
    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const [password, setPassword] = useState('')
    const confirmInput = useRef()
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState({})


    const canSend = () => {
        let validation = {}
        if (!password || password.length < 8) {
            validation.password = lang.t('password_must_be_at_least_8_characters')
        }
        if (password !== confirmPassword) {
            validation.password_confirmation = lang.t('passwords_must_match')
        }
        setErrors(validation)
        
        return Object.keys(validation).length === 0
    }


    const updatePassword = async () => {
        
        if (canSend() === false) {
            return
        }

        setLoading(true)
        const formData = new FormData()
        formData.append('password', password)
        formData.append('password_confirmation', confirmPassword)
    
        try {
            const response = await BackendService.resetPassword(formData);
          
            // TODO: show a nicer success message
            setLoading(false)
            Alert.alert( lang.t('success'), lang.t('your_password_has_been_reset'), [
                { text: "OK", onPress: () => navigation.navigate('HomeTabs') }
            ])
    
        } catch (error) {
            let validation = {}
            if (error?.response?.data) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    validation[key] = error.response.data.errors[key]
                })
                setErrors(validation)
            } else {
                console.error(error.message)
                Alert.alert(lang.t('generic_error_message'))
            }

            setLoading(false)
        }
    }

    if (isLoading) {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
            </View>
        )
    }

    return (
        <SafeAreaView style={styles.cardContainer}>
            <View style={styles.formContainer}>
                <Text style={styles.heading}>{lang.t('reset_password')}</Text>
                <Text style={styles.bodyCopy}>{lang.t('enter_a_new_password_at_least_8_characters_long')}</Text>
                <View style={styles.formGroup}>
                    <Text style={styles.formControlLabel}>{lang.t('new_password')}</Text>
                    <TextInput 
                        onChangeText={setPassword}
                        onSubmitEditing={() => confirmInput.current.focus()}
                        secureTextEntry={true}
                        blurOnSubmit={false}
                        returnKeyType="next"
                        style={styles.formControl}
                        placeholder={lang.t('new_password')}
                    />
                    { errors?.password ? <Text style={styles.formValidationLabel}>{errors.password}</Text> : null }
                </View>
                <View style={styles.formGroup}>
                    <Text style={styles.formControlLabel}>{lang.t('re_type_password')}</Text>
                    <TextInput 
                        onChangeText={setConfirmPassword}
                        ref={confirmInput}
                        secureTextEntry={true}
                        style={styles.formControl}
                        placeholder={lang.t('re_type_password')}
                    />
                    { errors?.password_confirmation ? <Text style={styles.formValidationLabel}>{errors.password_confirmation}</Text> : null }
                </View>
            </View>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={updatePassword} style={styles.button}>
                    <Text style={styles.buttonText}>{lang.t('set_password')}</Text>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    )
}

export default ResetPasswordCard

const styles = StyleSheet.create({
    cardContainer: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        flexDirection: 'row',
        width: '100%',
        height: 40,
        justifyContent: 'flex-end',
        marginBottom: 34,
    },
    formContainer: {
        flex: 1,
        padding: 15,
    },
    formGroup: {
        marginBottom: 11,
    },
    formControl: {
        ...Theme.formControl,
    },
    formControlLabel: {
        ...Theme.formLabel,
        marginLeft: 10,
        marginBottom: 4,
    },
    formValidationLabel: {
        marginTop: 6,
        marginLeft: 12,
        fontSize: 12,
        color: ThemeColours.denialRed,
    },
    heading: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        marginBottom: 12,
    },
    bodyCopy: {
        fontSize: 16,
        color: ThemeColours.blueGrey,
        marginBottom: 34,
        maxWidth: 315,
    },
    buttonContainer: {
        padding: 15,
    },
    button: {
        ...Theme.button,
        shadowColor: 'transparent',
    },
    buttonText: {
        ...Theme.buttonText,
    },
})