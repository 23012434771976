import { StyleSheet, Text, View, Image, TouchableOpacity, Animated, ScrollView, Dimensions, Platform, ImageBackground, Alert, Modal } from 'react-native'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Theme, ThemeColours } from '../Styles/Theme'
import CloseButton from '../Components/CloseButton'
import { useAuthContext } from '../auth'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps'
import { useDispatch } from 'react-redux'
import { setSingleMerchantAsSelected } from '../Store/resultsSlice'
import BackendService from '../Services/BackendService'
import ReviewsModal from '../Components/ReviewsModal'
import CreateReviewModal from '../Components/CreateReviewModal'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import Purchases from 'react-native-purchases'
import { ENTITLEMENT_ID } from '../config'
import { showLocation } from 'react-native-map-link'
import * as Application from 'expo-application'
import * as Clipboard from 'expo-clipboard';
import MerchantSpecialsFile from '../Components/MerchantSpecialsFile'

const { width } = Dimensions.get('window')

const MerchantScreen = () => {
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const fadeEmailCopyAnim = useRef(new Animated.Value(0)).current;

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const dispatch = useDispatch()
    const { user, setAuthenticated } = useAuthContext()
    const isLoggedIn = !!user?.id //convert to boolean
    const lang = useLocalisationContext()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [merchant, setMerchant] = useState()
    const [merchantAddressString, setMerchantAddressString] = useState('')
    const [isReviewsModalOpen, setReviewsModalOpen] = useState(false)
    const [isCreateReviewModalOpen, setCreateReviewModalOpen] = useState(false)
    const { params: {
        id,
        imgUrl,
        logoUrl,
        name,
        distance,
        rating,
        reviewCount
    } } = useRoute()
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isMenuRendered, setIsMenuRendered] = useState(false);
    const menuAnimation = useRef(new Animated.Value(0)).current;

    const showMenu = () => {
        setIsMenuRendered(true);
        Animated.timing(menuAnimation, {
            toValue: 1,
            duration: 300,
            useNativeDriver: true,
        }).start();
    };

    const hideMenu = () => {
        Animated.timing(menuAnimation, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
        }).start(() => setIsMenuRendered(false));
    };
    useEffect(() => {
        if (isMenuVisible) {
            showMenu();
        } else {
            hideMenu();
        }
    }, [isMenuVisible]);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])


    useEffect(() => {
        // Load all data from the server when the id changes...
        if (id && !dataLoaded) {
            BackendService.getMerchant(id).then((response) => {
                setMerchant(response.data)
                const parts = [
                    response.data?.address_line_1,
                    response.data?.address_line_2,
                    response.data?.address_town_city,
                    response.data?.address_county,
                    response.data?.address_postcode
                ]
                setMerchantAddressString(parts.filter(n => n).join(', '))
                setDataLoaded(true)
                // console.log(response.data)
            }).catch((error) => console.error(error))
        }

        // Return clean up function.
        return () => {
            setDataLoaded(false)
        }
    }, [id, user])

    const navigateToRequest = async () => {
        // Check if the current user is subscribed and if not, display the paywall.
        try {
            // access latest customerInfo
            dispatch(setSingleMerchantAsSelected(merchant.id))
            navigation.navigate('CreateRequestScreen', { searchString: 'materials' })
            // const customerInfo = await Purchases.getCustomerInfo()
            // if (typeof customerInfo.entitlements.active[ENTITLEMENT_ID] !== 'undefined') {
            //     dispatch(setSingleMerchantAsSelected(merchant.id))
            //     navigation.navigate('CreateRequestScreen', { searchString: 'materials' })
            // } else {
            //     navigation.replace('PaywallScreen')
            // }
        } catch (e) {
            Alert.alert('Error fetching customer info', e.message)
        }
    }

    const openDirections = () => {
        const parts = [
            merchant?.address_line_1,
            merchant?.address_line_2,
            merchant?.address_town_city,
            merchant?.address_county,
            merchant?.address_postcode
        ]
        const destination = parts.filter(n => n).join(' ')
        showLocation({
            latitude: merchant.lat,
            longitude: merchant.lng,
            title: destination,
            googleForceLatLon: false,
            alwaysIncludeGoogle: true,
            appsWhiteList: ['apple-maps', 'google-maps', 'waze'],
            naverCallerName: Application.applicationId,
            directionsMode: 'car',
        })
    }

    const openCreateReviewModal = () => {
        setCreateReviewModalOpen(true)
    }

    const closeCreateReviewModal = () => {
        setCreateReviewModalOpen(false)
    }

    const openReviewsModal = () => {
        setReviewsModalOpen(true)
    }

    const closeReviewsModal = () => {
        setReviewsModalOpen(false)
    }

    const getWeekDayName = (day_of_week) => {
        const days = {
            '1': lang.t('weekday_short_1'),
            '2': lang.t('weekday_short_2'),
            '3': lang.t('weekday_short_3'),
            '4': lang.t('weekday_short_4'),
            '5': lang.t('weekday_short_5'),
            '6': lang.t('weekday_short_6'),
            '7': lang.t('weekday_short_7'),
        }

        return days[day_of_week] ? days[day_of_week] : ''
    }

    return (
        <View style={styles.container}>

            <ReviewsModal visible={isReviewsModalOpen} onClose={closeReviewsModal} merchantId={id} />
            <CreateReviewModal visible={isCreateReviewModalOpen} onClose={closeCreateReviewModal} merchantId={id} merchantName={name} />
            <FocusAwareStatusBar barStyle="light-content" />

            <ScrollView>
                <View style={styles.header}>
                    <ImageBackground
                        source={imgUrl}
                        style={{ width: '100%', height: (148 + insets.top), paddingTop: insets.top, resizeMode: 'cover', backgroundColor: ThemeColours.steelBlue }}
                        resizeMode='cover'
                    >
                        {merchant?.owner_id === user?.id ? null : <CloseButton onPress={() => navigation.goBack()} style={{ top: insets.top }}></CloseButton>}
                        {merchant?.owner_id === user?.id ?
                            <View style={styles.headerProfile}>
                                <View style={styles.headerRow}>
                                    <Text style={styles.headerTextLight}>{lang.t('merchant_profile')}</Text>

                                </View>
                            </View>
                            : null}
                    </ImageBackground>
                </View>
                <View style={styles.infoContainer}>
                    <View>
                        <Image source={logoUrl} style={styles.logo} />
                        {merchant?.owner_id === user?.id ?
                            <View style={styles.editButtonsRow}>
                                <TouchableOpacity onPress={() => navigation.navigate('MerchantEditScreen', { id })} style={styles.editBadge}>
                                    <Text style={styles.editBadgeText}>{lang.t('edit_profile')}</Text>
                                </TouchableOpacity>

                                {
                                    Platform.OS !== 'web' ?
                                        <TouchableOpacity
                                            onPress={() => setIsMenuVisible(!isMenuVisible)}
                                            style={styles.editBadge}>
                                            <Text style={styles.editBadgeText}>{lang.t('add_special')}</Text>
                                        </TouchableOpacity> : <View style={{
                                            justifyContent: 'space-between',
                                            flexDirection: 'row'
                                        }}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    navigation.navigate('MerchantEditScreen', { id, openAddSpecialsPdf: true })
                                                }}
                                                style={styles.editBadge}>
                                                <Text style={styles.editBadgeText}>{lang.t('add_specials_document')}</Text>
                                            </TouchableOpacity>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    navigation.navigate('MerchantEditScreen', { id, openAddSpecialsPhoto: true })
                                                }}
                                                style={styles.editBadge}>
                                                <Text style={styles.editBadgeText}>{lang.t('add_specials_photo')}</Text>
                                            </TouchableOpacity>
                                        </View>
                                }

                                {isMenuRendered && (
                                    <Animated.View style={[floatingMenu.menu, { opacity: menuAnimation }]}>
                                        <TouchableOpacity activeOpacity={0.75} onPress={() => {
                                            setIsMenuVisible(false);
                                            navigation.navigate('MerchantEditScreen', { id, openAddSpecialsPdf: true })
                                        }} style={floatingMenu.menuItem}>
                                            <Text style={floatingMenu.menuText}>{lang.t('add_document')}</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity activeOpacity={0.75} onPress={() => {
                                            setIsMenuVisible(false);
                                            navigation.navigate('MerchantEditScreen', { id, openAddSpecialsPhoto: true })
                                        }} style={floatingMenu.menuItem}>
                                            <Text style={floatingMenu.menuText}>{lang.t('add_photo')}</Text>
                                        </TouchableOpacity>
                                    </Animated.View>
                                )}
                            </View>
                            : null}
                    </View>
                    {merchant?.verified_at &&
                        <View style={styles.verifiedContainer}>
                            <Text style={styles.verifiedText}>{lang.t('verified_merchant')}</Text>
                            <Image source={require('../assets/images/verifiedIconMini-2x.png')} style={{ width: 25, height: 25 }} />
                        </View>
                    }
                    <Text style={styles.merchantName}>{name}</Text>
                    <View style={[styles.row, { marginBottom: 20 }]}>
                        <Image source={require('../assets/images/reviewStarIconFull-2x.png')} style={{ marginRight: 5, width: 18, height: 18 }} />
                        <View style={{ flexDirection: 'row', marginRight: 10 }}>
                            {reviewCount < 3 ?
                                <Text style={Theme.textBlueGrey}>New</Text>
                                :
                                <>
                                    <Text style={[Theme.textMidnightBlue, { fontWeight: 'bold' }]}>{rating}</Text>
                                    <Text style={Theme.textBlueGrey}>/{reviewCount}</Text>
                                </>
                            }
                        </View>
                        <TouchableOpacity onPress={openReviewsModal}>
                            <Text style={{ textDecorationLine: 'underline' }}>{reviewCount} {lang.t('reviews')}</Text>
                        </TouchableOpacity>
                    </View>
                    {isLoggedIn &&
                        <View style={{ marginBottom: 25 }}>
                            {merchant?.email && <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={styles.phoneEmail} onLongPress={async () => {
                                    await Clipboard.setStringAsync(merchant?.email || '');
                                    Animated.timing(fadeEmailCopyAnim, {
                                        toValue: 1,
                                        duration: 100,
                                        useNativeDriver: true,
                                    }).start();
                                    setTimeout(() => {
                                        Animated.timing(fadeEmailCopyAnim, {
                                            toValue: 0,
                                            duration: 1000,
                                            useNativeDriver: true,
                                        }).start();
                                    }, 1000)
                                }}>{merchant?.email}</Text>
                                <Animated.Text style={{ marginLeft: 12, opacity: fadeEmailCopyAnim, }}>{lang.t('copied_to_clipboard')}</Animated.Text>
                            </View>}
                            {merchant?.phone && <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={styles.phoneEmail} onLongPress={async () => {
                                    await Clipboard.setStringAsync(merchant?.phone || '');
                                    Animated.timing(fadeAnim, {
                                        toValue: 1,
                                        duration: 100,
                                        useNativeDriver: true,
                                    }).start();
                                    setTimeout(() => {
                                        Animated.timing(fadeAnim, {
                                            toValue: 0,
                                            duration: 1000,
                                            useNativeDriver: true,
                                        }).start();
                                    }, 1000)
                                }}>{merchant?.phone}</Text>
                                <Animated.Text style={{ marginLeft: 12, opacity: fadeAnim, }}>{lang.t('copied_to_clipboard')}</Animated.Text>
                            </View>}
                        </View>
                    }
                    {merchant?.material_categories &&
                        <View style={styles.badgeContainer}>
                            {merchant.material_categories.map((item) => (
                                <Text key={item.id} style={[Theme.textMidnightBlue, styles.badge]}>{item.name}</Text>
                            ))}
                        </View>
                    }
                    {merchant?.opening_times &&
                        <Text style={styles.subHeading}>{lang.t('opening_times')}</Text>
                    }

                    {merchant?.opening_times.map((item) => {
                        return (
                            <View key={item.id} style={[styles.row, { marginBottom: 4, width: 180 }]}>
                                <View style={styles.col}>
                                    <Text style={[Theme.textBlueGrey, { lineHeight: 20 }]}>{getWeekDayName(item.day_of_week)}</Text>
                                </View>
                                {!item.is_open &&
                                    <View style={styles.col}>
                                        <Text style={[Theme.textBlueGrey, { lineHeight: 20, textAlign: 'left' }]}>{lang.t('closed')}</Text>
                                    </View>
                                }
                                {item.is_open &&
                                    <View style={styles.col}>
                                        <Text style={[Theme.textBlueGrey, { lineHeight: 20 }]}>{item.open_at.slice(0, -3)}</Text>
                                    </View>
                                }
                                {item.is_open &&
                                    <View style={[styles.col, { justifyContent: 'center', alignItems: 'center' }]}>
                                        <Text style={[Theme.textBlueGrey, { lineHeight: 20 }]}>-</Text>
                                    </View>
                                }
                                {item.is_open &&
                                    <View style={styles.col}>
                                        <Text style={[Theme.textBlueGrey, { lineHeight: 20 }]}>{item.close_at.slice(0, -3)}</Text>
                                    </View>
                                }
                            </View>
                        )
                    })}
                    <View style={{ marginBottom: 20 }}></View>
                    {/* </View> */}
                    <Text style={styles.subHeading}>{lang.t('onsite_facilities')}</Text>
                    <View>
                        {merchant?.has_parking &&
                            <View style={[styles.row, { marginBottom: 16 }]}>
                                <Image source={require('../assets/images/utilitiesParkingIcon-2x.png')} style={{ marginRight: 12, width: 15, height: 15 }} />
                                <Text style={Theme.textBlueGrey}>{lang.t('onsite_parking')}</Text>
                            </View>
                        }
                        {merchant?.has_toilets &&
                            <View style={[styles.row, { marginBottom: 16 }]}>
                                <Image source={require('../assets/images/utilitiesToiletIcon-2x.png')} style={{ marginRight: 15, width: 12, height: 15 }} />
                                <Text style={Theme.textBlueGrey}>{lang.t('toilets')}</Text>
                            </View>
                        }
                        {merchant?.has_refreshments &&
                            <View style={[styles.row, { marginBottom: 16 }]}>
                                <Image source={require('../assets/images/utilitiesFoodIcon-2x.png')} style={{ marginRight: 15, width: 12, height: 15 }} />
                                <Text style={Theme.textBlueGrey}>{lang.t('refreshments')}</Text>
                            </View>
                        }
                    </View>
                    {isLoggedIn &&
                        <Text style={styles.subHeading}>{lang.t('where_you_can_find_us')}</Text>
                    }
                    {(isLoggedIn && merchantAddressString) ?
                        <Text style={[Theme.textBlueGrey, { marginBottom: 20 }]}>{merchantAddressString}</Text>
                        :
                        null
                    }
                    {(isLoggedIn && merchant?.lat && merchant?.lng) ?
                        <View style={styles.mapContainer}>
                            <MapView
                                provider={PROVIDER_GOOGLE}
                                style={styles.map}
                                initialRegion={{
                                    latitude: merchant.lat,
                                    longitude: merchant.lng,
                                    latitudeDelta: 0.0922,
                                    longitudeDelta: 0.0421,
                                }}
                            >
                                <Marker
                                    coordinate={{ latitude: merchant.lat, longitude: merchant.lng }}
                                    title={merchant.name} />
                            </MapView>
                        </View>
                        :
                        null
                    }

                    {(isLoggedIn && merchant?.owner_id !== user?.id && !user?.is_merchant) ?
                        <>
                            <View style={{ marginBottom: 20 }}>
                                <TouchableOpacity onPress={openDirections} style={styles.button}>
                                    <View style={styles.buttonInner}>
                                        <Image source={require('../assets/images/viewOnMapIconDark-2x.png')} style={{ marginRight: 15, width: 22, height: 20 }} />
                                        <Text style={styles.buttonText}>{lang.t('get_directions')}</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <TouchableOpacity onPress={navigateToRequest} style={styles.button}>
                                    <View style={styles.buttonInner}>
                                        <Image source={require('../assets/images/sendMessageIconDark-2x.png')} style={{ marginRight: 15, width: 20, height: 15 }} />
                                        <Text style={styles.buttonText}>{lang.t('send_request')}</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <TouchableOpacity onPress={openCreateReviewModal} style={styles.button}>
                                    <Text style={styles.buttonText}>{lang.t('leave_a_review')}</Text>
                                </TouchableOpacity>
                            </View>
                        </>
                        : null}
                    {merchant ?
                        <MerchantSpecialsFile merchant={merchant} /> : null
                    }
                </View>
            </ScrollView>
            {!isLoggedIn &&
                <View style={[styles.buttonContainer, { marginBottom: insets.bottom }]}>
                    <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')} style={styles.button}>
                        <Text style={styles.buttonText}>{lang.t('log_in_to_see_full_details')}</Text>
                    </TouchableOpacity>
                </View>
            }
        </View>
    )
}

export default MerchantScreen

const floatingMenu = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    menu: {
        position: 'absolute',
        borderRadius: 5,
        bottom: 40,
        right: 10,
        elevation: 5,  // for Android
        shadowColor: 'black',  // for iOS
        shadowOpacity: 0.3,    // for iOS
        shadowOffset: { width: 0, height: 2 },  // for iOS
    },
    menuItem: {
        padding: 10,
        paddingHorizontal: 20,
        backgroundColor: '#fff',
        borderRadius: 30,
        marginTop: 16,
    },
    menuText: {
        textAlign: 'right'
    }
});
const styles = StyleSheet.create({
    col: {
        flex: 1,
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    container: {
        flex: 1,
        backgroundColor: '#ffffff',
    },
    header: {
        position: 'relative',
    },
    headerProfile: {
        height: 73,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerTextLight: {
        textAlign: 'center',
        color: ThemeColours.white,
        fontSize: 18,
        lineHeight: 20,
        fontFamily: 'ITCAvantGardeProBold',
    },
    merchantName: {
        marginBottom: 16,
        marginTop: 20,
        fontWeight: 'bold',
        fontSize: 22,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    infoContainer: {
        paddingLeft: 25,
        paddingRight: 25,
        position: 'relative',
        paddingBottom: 30,
    },
    logo: {
        marginTop: -58,
        height: 125,
        width: 125,
        // backgroundColor: '#ffffff',
        backgroundColor: ThemeColours.blueGrey,
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowRadius: 19,
        shadowOpacity: 1,
        borderStyle: 'solid',
        borderWidth: 4,
        borderColor: '#ffffff',
        borderRadius: 7,
    },
    verifiedContainer: {
        position: 'absolute',
        flexDirection: 'row',
        top: 15,
        right: 25,
    },
    verifiedText: {
        marginRight: 5,
        display: 'inline-block',
        fontSize: 12,
        letterSpacing: 0,
        textAlign: 'right',
        color: 'rgb(153,167,188)',
        lineHeight: 20,
    },
    phoneEmail: {
        fontSize: 14,
        letterSpacing: 0,
        color: ThemeColours.midnightBlue,
        lineHeight: 20,
    },
    badgeContainer: {
        flex: 1,
        width: '100%',
        height: 400,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 19,
    },
    badge: {
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: 20,
        paddingHorizontal: 10,
        minHeight: 21,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccd3dd',
        borderRadius: 11,
        marginRight: 8,
        marginBottom: 10,
    },
    editButtonsRow: {
        position: 'absolute',
        left: 125,
        top: 20,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: (width - 150)
    },
    editBadge: {
        letterSpacing: 0,
        width: Platform.OS === 'web' ? 200 : 105,
        marginHorizontal: Platform.OS === 'web' ? 6 : 0,
        alignSelf: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.sunshineGold,
        borderRadius: 11,
        paddingVertical: 2,
    },
    editBadgeText: {
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
        fontSize: 16,
    },
    subHeading: {
        fontSize: 16,
        lineHeight: 28,
        marginBottom: 16,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProMd',
    },
    buttonText: {
        fontSize: 18,
        letterSpacing: 0,
        textAlign: 'center',
        color: 'rgb(0,35,87)',
    },
    buttonContainer: {
        padding: 15,
        backgroundColor: '#ffffff',
    },
    button: {
        backgroundColor: 'rgb(251,176,59)',
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        borderRadius: 3,
        padding: 13,
    },
    buttonInner: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonClose: {
        height: 40,
        width: 40,
        position: 'absolute',
        right: 15,
        top: 15,
    },
    mapContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },
    map: {
        height: 164,
        width: (width - 40),
    },
})