import { StyleSheet, Text, View, Alert, ActivityIndicator, TextInput, TouchableOpacity, Modal, Image, Pressable } from 'react-native'
import { useRef, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useAuthContext } from '../auth'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { SafeAreaView } from 'react-native-safe-area-context'
import BackendService from '../Services/BackendService'


const VerifyEmailCard = () => {

    const { user, setAuthenticated } = useAuthContext()
    const [isLoading, setLoading] = useState(false)
    const [showModal, setModal] = useState(false)
    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const [verificationCode, setVerificationCode] = useState('')
    const [errors, setErrors] = useState({})


    const canSend = () => {
        let validation = {}
        if ( !verificationCode || verificationCode.length < 4) {
            validation.code = lang.t('please_enter_your_validation_code')
        }
        
        setErrors(validation)
        
        return Object.keys(validation).length === 0
    }

    const closeModal = () => {
        navigation.navigate('HomeTabs', {screen: 'Profile'})
        setModal(false)
    }

    const verifyCode = async () => {
        
        if (canSend() === false) {
            return
        }

        setLoading(true)


        try {
            await BackendService.verifyEmail(user.id, {
                code: verificationCode
            })
            
            // Update the local authed user so they have email_verified_at set
            const userCopy = user;
            userCopy.email_verified_at = new Date();
            setAuthenticated(userCopy)

            setModal(true)
            setLoading(false)

        } catch(error) {
            let validation = {}
            if (error?.response?.data) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    validation[key] = error.response.data.errors[key]
                })
                setErrors(validation)
            } else {
                console.error(error.message)
                Alert.alert(lang.t('generic_error_message'))
            }

            setLoading(false)
        }
    }

    if (isLoading) {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
            </View>
        )
    }

    return (
        <SafeAreaView style={styles.container}>
            <Modal
                animationType="slide"
                visible={showModal}
                transparent={false}
                presentationStyle='overFullScreen'
                onRequestClose={closeModal}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Image source={require('../assets/images/checkCircleLargeIcon-2x.png')} 
                            style={{width: 151, height: 151, marginBottom: 25, marginTop: 90}}
                            />
                        <Text style={[styles.h1, {textAlign: 'center'}]}>{lang.t('your_account_has_been_verified')}</Text>
                        <Pressable style={[styles.button, {minWidth: 250}]} onPress={closeModal}>
                            <Text style={styles.buttonText}>{lang.t('close')}</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
            <View style={{flex: 1}}>
                <Text style={styles.h1}>{lang.t('verify_account')}</Text>
                <Text style={styles.copy}>{lang.t('enter_the_code_we_sent_to')} <Text style={styles.email}>{user.email}</Text></Text>
                <View style={{flexDirection: 'row'}}>
                    <TextInput 
                        onChangeText={setVerificationCode}
                        returnKeyType="done"
                        autoComplete='off'
                        autoCorrect={false}
                        spellCheck={false}
                        style={[styles.formControl, {}]}
                        />
                </View>
                {errors?.code ? <Text style={styles.formValidationLabel}>{errors.code}</Text> : null }
            </View>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={verifyCode} style={styles.button}>
                    <Text style={styles.buttonText}>{lang.t('verify')}</Text>
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    )
}

export default VerifyEmailCard

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
        paddingLeft: 20,
        paddingRight: 20,
    },
    h1: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        marginBottom: 38,
    },
    copy: {
        fontSize: 18,
        color: ThemeColours.blueGrey,
        marginBottom: 26,
    },
    email: {
        color: ThemeColours.midnightBlue,
    },
    formControl: {
        ...Theme.formControl,
    },
    button: {
        ...Theme.button,
        shadowColor: 'transparent',
    },
    buttonText: {
        ...Theme.buttonText,
    },
    spinnerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ThemeColours.cloudWhite,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
        backgroundColor: ThemeColours.cloudWhite,
    },
    modalView: {
        margin: 20,
        backgroundColor: ThemeColours.white,
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    }
})