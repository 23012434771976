import { StyleSheet, Text, View, TextInput, TouchableOpacity, Image, ScrollView, Modal, Keyboard, Alert, SafeAreaView, Dimensions, Pressable, ActivityIndicator, KeyboardAvoidingView } from 'react-native'
import React, { useEffect, useState } from 'react'
import CloseButton from '../Components/CloseButton'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Theme, ThemeColours } from '../Styles/Theme'
import * as ImagePicker from 'expo-image-picker'
import * as DocumentPicker from 'expo-document-picker';
import BackendService from '../Services/BackendService'
import { useSelector, useDispatch } from 'react-redux'
import { getSelected, clearSelectedResults } from '../Store/resultsSlice'
import { resetRequestStatus } from '../Store/requestsSlice'
import { useAuthContext } from '../auth'
import { lang } from '../Providers/LocalisationProvider'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import dismissKeyboard from '../utils/dismissKeyboard'
import { Platform } from 'react-native';


const CreateRequestScreen = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const dispatch = useDispatch()
    const [message, setMessage] = useState('')
    const [photo, setPhoto] = useState()
    const selectedMerchants = useSelector(getSelected)
    const [modalVisible, setModalVisible] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [sendVisable, setSendVisable] = useState(true)
    const { user } = useAuthContext()
    const isLoggedIn = !!user?.id //convert to boolean
    const [webUploadedPhoto, setWebUploadedPhoto] = useState(null);


    useEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })

        const onShowKeyboard = Keyboard.addListener('keyboardWillShow', () => {
            setSendVisable(false)
        })
        const onHideKeyboard = Keyboard.addListener('keyboardWillHide', () => {
            setSendVisable(true)
        })

        return () => {
            onShowKeyboard.remove()
            onHideKeyboard.remove()
        };

    }, [])

    const choosePdfAsync = async () => {
        if (Platform.OS !== 'web') {
            const result = await DocumentPicker.getDocumentAsync({
                type: ['application/pdf']
            });
            if (result.type !== 'success') {
                return;
            }
            setPhoto({
                uri: result.uri,
                name: result.name,
                type: result.mimeType
            });
        } else {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'application/pdf');

            input.onchange = () => {
                setWebUploadedPhoto(input.files[0]);
            };
            input.click();
        }
    }
    const choosePhotoAsync = async () => {
        if (Platform.OS !== 'web') {
            // Display the media libary to the user and wait for them to take a photo or to cancel
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All,
                quality: 0.2,
            })

            console.log(result)

            if (result.canceled) {
                setPhoto(undefined)
                return;
            }

            // ImagePicker saves the taken photo to disk and returns a local URI to it
            let localUri = result.assets[0].uri
            let filename = localUri.split('/').pop()

            // Infer the type of the image
            let match = /\.(\w+)$/.exec(filename)
            let type = match ? `image/${match[1]}` : `image`

            setPhoto({
                uri: localUri,
                name: filename,
                type
            })
        } else {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = () => {
                setWebUploadedPhoto(input.files[0]);
            };
            input.click();
        }
    }

    async function createRequestAsync() {

        // Upload the image using the fetch and FormData APIs
        const formData = new FormData()
        if (Platform.OS === 'web') {
            if (webUploadedPhoto) {
                formData.append('photo', webUploadedPhoto, webUploadedPhoto.name)
            }
        } else {
            if (photo) {
                formData.append('photo', photo)
            }
        }
        formData.append('message', message)
        selectedMerchants.forEach((merchant) => {
            formData.append('merchants[]', merchant)
        })

        try {
            setLoading(true)
            await BackendService.createRequest(formData)
            dispatch(resetRequestStatus())
            dispatch(clearSelectedResults())
            setLoading(false)
            setModalVisible(true)
        } catch (error) {
            //todo: handle failuer
            //413: image too large
            //422: validation errors
            //500: sorry we were unable to process your request, please try again later
            setLoading(false)
            Alert.alert(error.message)
        }
    }

    const handleCloseModal = () => {
        setModalVisible(false);
        navigation.navigate('HomeTabs', { screen: 'Requests' })
    };

    if (!isLoggedIn) {
        return (
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Text style={styles.heading}>{lang.t('material_request')}</Text>
                    <CloseButton onPress={() => navigation.goBack()}></CloseButton>
                </View>
                <View style={styles.content}>
                    <Text style={styles.headingLogin}>{lang.t('log_in_to_create_a_request')}</Text>
                    <Text style={styles.subheading}>{lang.t('deals_on_materials_right_at_your_fingertips')}</Text>
                    <View style={{ width: '100%', marginTop: 40 }}>
                        <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')} style={styles.sendButton}>
                            <Text style={styles.sendButtonText}>{lang.t('log_in')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </SafeAreaView>
        )
    }

    if (isLoading) {
        return (
            <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                    <Text style={styles.heading}>{lang.t('material_request')}</Text>
                </View>
                <View style={styles.content}>
                    <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
                    <Text style={styles.loadingText}>{lang.t('sending_request')}</Text>
                </View>
            </SafeAreaView>
        )
    }

    return (
        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1, paddingTop: insets.top }}>
            <Modal
                animationType="slide"
                visible={modalVisible}
                presentationStyle='overFullScreen'
                onRequestClose={handleCloseModal}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Image source={require('../assets/images/checkCircleLargeIcon-2x.png')}
                            style={{ width: 151, height: 151, marginBottom: 25, marginTop: 90 }}
                        />
                        <Text style={styles.modalHeading}>{lang.t('your_request_has_been_sent')}</Text>
                        <Text style={styles.modalText}>{lang.t('we_will_notify_you_when_merchants_respond')}</Text>
                        <Pressable
                            style={[styles.button, styles.buttonClose]}
                            onPress={handleCloseModal}
                        >
                            <Text style={styles.buttonText}>{lang.t('close')}</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
            <Pressable style={{ flex: 1 }} onPress={dismissKeyboard}>
                <View style={{ flex: 1 }}>
                    <View style={styles.header}>
                        <Text style={styles.heading}>{lang.t('material_request')}</Text>
                        <CloseButton onPress={() => navigation.goBack()}></CloseButton>
                    </View>
                    <View style={styles.editingSection}>
                        <View>
                            <TextInput
                                placeholder={lang.t('tell_merchants_what_you_need')}
                                multiline={true}
                                autoGrow={true}
                                numberOfLines={8}
                                style={styles.messageBox}
                                value={message}
                                onChangeText={setMessage}
                            />
                        </View>
                        <View style={{
                            // height: 200,
                            justifyContent: 'center'
                        }}>
                            <TouchableOpacity style={styles.row} onPress={choosePdfAsync}>
                                <View style={styles.attachmentButton}>
                                    <Image source={require('../assets/images/attachmentIcon-2x.png')} style={{ width: 17, height: 20 }} />
                                </View>
                                <Text style={styles.attachmentLabel}>{lang.t('add_document')}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.row} onPress={choosePhotoAsync}>
                                <View style={styles.attachmentButton}>
                                    <Image source={require('../assets/images/sendImageIcon-white-2x.png')} style={{ width: 20, height: 18 }} />
                                </View>
                                <Text style={styles.attachmentLabel}>{lang.t('add_photo')}</Text>
                            </TouchableOpacity>
                            {
                                Platform.OS !== 'web' ?
                                    (photo ? <TouchableOpacity onPress={() => { setPhoto() }}>
                                        <Text style={styles.attachedFileLabel}>{lang.t('attached_file')} {photo.name} {lang.t('tap_to_cancel')}</Text>
                                    </TouchableOpacity> : null)
                                    :
                                    (webUploadedPhoto ? <TouchableOpacity onPress={() => { setWebUploadedPhoto() }}>
                                        <Text style={styles.attachedFileLabel}>{lang.t('attached_file')} {webUploadedPhoto.name} {lang.t('tap_to_cancel')}</Text>
                                    </TouchableOpacity> : null
                                    )
                            }
                        </View>
                    </View>
                </View>
                {sendVisable ?
                    <View style={[styles.buttonContainer, { paddingBottom: (20 + insets.bottom) }]}>
                        <TouchableOpacity
                            style={styles.sendButton}
                            disabled={!message}
                            onPress={createRequestAsync}
                        >
                            <Text style={styles.sendButtonText}>{lang.t('send_request')}</Text>
                        </TouchableOpacity>
                    </View>
                    : null}
            </Pressable>
        </KeyboardAvoidingView>
    )
}

export default CreateRequestScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    row: {
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center'
    },
    header: {
        height: 84,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
    },
    heading: {
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 18,
        lineHeight: 32,
    },
    subheading: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.blueGrey,
        lineHeight: 22,
        maxWidth: 280,
    },
    headingLogin: {
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 25,
        lineHeight: 32,
        textAlign: 'center',
        marginBottom: 15,
        maxWidth: 280,
    },
    editingSection: {
        paddingHorizontal: 15,
        paddingTop: 10,
        flex: 1,
    },
    messageBox: {
        paddingHorizontal: 25,
        paddingTop: 15,
        paddingBottom: 15,
        minHeight: 220,
        maxHeight: 420,
        fontSize: 15,
        backgroundColor: ThemeColours.white,
        shadowColor: '#ebefff',
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowRadius: 15,
        shadowOpacity: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.midnightBlue,
        borderRadius: 10
    },
    buttonContainer: {
        padding: 15,
        // backgroundColor: ThemeColours.white,
    },
    sendButton: {
        ...Theme.button,
        paddingVertical: 5,
    },
    sendButtonText: {
        ...Theme.buttonText,
        fontSize: 22,
        lineHeight: 40,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
    },
    attachmentButton: {
        height: 40,
        width: 40,
        marginRight: 10,
        backgroundColor: ThemeColours.steelBlue,
        borderRadius: 7,
        alignItems: 'center',
        justifyContent: 'center',
    },
    attachmentLabel: {
        fontSize: 16,
        marginRight: 12,
        color: ThemeColours.blueGrey,
    },
    attachedFileLabel: {
        width: '100%',
        marginTop: 16,
        textAlign: 'center',
        color: ThemeColours.blueGrey,
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    loadingIcon: {
        marginBottom: 25,
    },
    loadingText: {
        ...Theme.h1,
        color: ThemeColours.midnightBlue,
    },
    // Modal
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    modalView: {
        // flex: 1,
        // width: '100%',
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: ThemeColours.sunshineGold,
        paddingHorizontal: 25,
        borderRadius: 6,
    },
    buttonText: {
        color: ThemeColours.midnightBlue,
        // fontWeight: "bold",
        textAlign: "center",
        lineHeight: 30,
        fontSize: 16,
    },
    modalHeading: {
        fontSize: 23,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
        lineHeight: 29,
        marginBottom: 15,
        fontWeight: "bold",
        maxWidth: 221,
    },
    modalText: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.steelBlue,
        lineHeight: 23,
        marginBottom: 25,
        maxWidth: 265,
    }
})