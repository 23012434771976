import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    location: {},
    address_string: '',
}

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload
        },
        setAddressString: (state, action) => {
            state.formatted_address = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLocation, setAddressString } = locationSlice.actions

export const selectLocation= (state) => state.location.location
export const selectAddressString = (state) => state.location.formatted_address

export default locationSlice.reducer