import { StyleSheet, Text, View, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useLayoutEffect } from 'react'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import Header from '../Components/Header'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import { BASE_URL,PURCHASES_API_KEY_ANDROID } from '../config'
import * as Application from 'expo-application'
import { ThemeColours } from '../Styles/Theme'


const AboutScreen = () => {

    const navigation = useNavigation()
    const lang = useLocalisationContext()

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    return (
        <View style={{flex: 1}}>
            <FocusAwareStatusBar barStyle="dark-content" />
            <Header title={lang.t('about')} />

            <View style={styles.container}>

                <View style={styles.pannel}>
                    <View style={styles.row}>
                        <View style={styles.col}><Text style={styles.textKey}>{Application.applicationName} {lang.t('version')}</Text></View>
                        <View style={styles.col}><Text style={styles.textValue}>{Application.nativeApplicationVersion}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}><Text style={styles.textKey}>{lang.t('platform')}</Text></View>
                        <View style={styles.col}><Text style={styles.textValue}>{Platform.OS}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}><Text style={styles.textKey}>{lang.t('build_number')}</Text></View>
                        <View style={styles.col}><Text style={styles.textValue}>{Application.nativeBuildVersion}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}><Text style={styles.textKey}>{lang.t('application_id')}</Text></View>
                        <View style={styles.col}><Text style={styles.textValue}>{Application.applicationId}</Text></View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={[styles.textKey, {marginBottom: 6}]}>{lang.t('api_version')}</Text>
                            <Text style={styles.textValue}>{BASE_URL}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.col}>
                            <Text style={[styles.textKey, {marginBottom: 6}]}>Android API</Text>
                            <Text style={styles.textValue}>{PURCHASES_API_KEY_ANDROID}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default AboutScreen

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 15,
        paddingTop: 60,
        flex: 1,
    },
    pannel: {
        borderRadius: 9,
        backgroundColor: ThemeColours.white,
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: ThemeColours.cloudWhite,
        borderBottomWidth: 1,
        paddingHorizontal: 9,
        paddingVertical: 12,
    },
    col: {
        flex: 1,
    },
    textHeader: {
        color: ThemeColours.midnightBlue,
        textAlign: 'center',
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 16,
        marginBottom: 18,
    },
    textKey: {
        color: ThemeColours.midnightBlue,
    },
    textValue: {
        color: ThemeColours.steelBlue,
    },
})